import gql from 'graphql-tag';

export const GET_CV = gql`
  query {
    me {
      id
      referee_1
      referee_2
      bio
      linkedin
      salary_currency
      minimum_salary
      maximum_salary
      salary_unit
      isProfileComplete {
        complete
        missing
      }
      education {
        id
        school_name
        qualification
        description
        from
        to
      }
      experiences {
        id
        title
        company
        description
        from
        to
        years
      }
      skills {
        id
        name
        pivot {
          is_top
        }
      }
      jobTypes {
        key
        name
      }
      genres {
        id
        name
      }
      resumes {
        id
        file
        fileUrl
        active
        created_at
      }
      location
    }
  }
`;
