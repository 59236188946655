import React, { useState, useEffect } from 'react';
import OpportunityCard from '../../Components/OpportunityCard';
import { Link } from 'react-router-dom';

const OpportunitiesForYou = ({ loading, opportunitiesForYou, user }) => {
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [formattedOpportunities, setFormattedOpportunities] = useState([]);

  useEffect(() => {
    if (initialLoadComplete) return;

    if (!user) {
      setInitialLoadComplete(true);
    }
  }, [user]);

  useEffect(() => {
    if (opportunitiesForYou !== undefined) {
      const filtered = opportunitiesForYou.filter((o) => o.is_active === true);
      setFormattedOpportunities(filtered);
    }
  }, [opportunitiesForYou]);

  return (
    <>
      <div className="col-12 mb-30">
        <h1>Job Opportunities Just For You</h1>

        {(user.genres.length === 0 || user.skills.length === 0) && (
          <p>
            <Link
              to="/freelancer/manage-cv"
              className="link-freelancer txt__fw-600"
            >
              Update your profile to find your perfect opportunity matches
            </Link>
          </p>
        )}
      </div>
      {loading &&
        initialLoadComplete === false &&
        'Loading job opportunities for you...'}
      {formattedOpportunities
        ?.slice(0, 6)
        .map(
          ({
            agency,
            title,
            created_at,
            id,
            genres,
            skills,
            type,
            updated_at,
          }) => (
            <OpportunityCard
              {...{
                agency,
                title,
                id,
                genres,
                skills,
                user,
                type,
              }}
              key={created_at}
              createdAt={created_at}
              updatedAt={updated_at}
            />
          )
        )}

      <div className="col-12 text-right mb-100">
        <Link
          to="/freelancer/opportunities-for-you"
          className="link-freelancer txt__fw-600"
        >
          View More
        </Link>
      </div>
    </>
  );
};

export default OpportunitiesForYou;
