import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import Header from '../../components/Freelancer/Header';
import Footer from '../../components/Footer';
import jquery from '../../styles/js/app';
import AccountSubmenuDesktop from '../../components/Freelancer/AccountSubmenuDesktop';
import AccountSubmenuMobile from '../../components/Freelancer/AccountSubmenuMobile';
import { userVar } from '../../graphql/config';
import { UPDATE_PROFILE } from '../../graphql';
import {
  getUser,
  isValidImage,
  saveUserLocalstorage,
} from '../../utils/helpers';
import Error from '../../components/Error';

const PersonalInformation = () => {
  useEffect(() => {
    jquery();
  }, []);

  const avatarRef = useRef(null);

  const {
    bio,
    company,
    address_1,
    address_2,
    city,
    state,
    country,
    zip,
    phone,
    phone_secondary,
    email,
    avatar,
    position,
    looking_for,
    payroll_status,
    hear_us,
    hear_us_additional,
    location: jobLocation,
  } = userVar();

  const [updateProfile, { loading }] = useMutation(UPDATE_PROFILE, {
    onCompleted: () => toast.dismiss('avUpLoading'),
  });

  const [img, setImg] = useState({
    url: avatar || '/img/profile-picture-placeholder@2x.jpg',
    error: null,
  });

  const payrollStatusMap = {
    0: 'Is a Limited Company',
    1: 'Requires going on the Payroll',
    2: 'Is a Limited Company',
    3: 'Either Payroll or Limited Company',
  };

  loading && toast.info('Updating avatar...', { toastId: 'avUpLoading' });

  const onChangeFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    toast.dismiss('invalidImage');

    const file = event.target.files[0];
    isValidImage(file, (valid) => {
      if (!valid) {
        setImg({ url: '/img/profile-picture-placeholder@2x.jpg', error: true });
        return toast.error('Please select a valid image file.', {
          toastId: 'invalidImage',
        });
      }
      setImg({
        url: valid.url,
        error: false,
        file: valid.file,
      });
      updateProfile({
        variables: {
          input: {
            avatar: valid.file,
          },
        },
      })
        .then(({ data: { updateUser } }) => {
          const updatedUserObj = { ...getUser(), ...updateUser };
          userVar(updatedUserObj);
          saveUserLocalstorage(updatedUserObj);
          toast.success('Avatar updated successfully!', {
            toastId: 'profUpdate',
            autoClose: 4000,
          });
        })
        .catch((e) =>
          toast.error('Could not update avatar!', { toastId: 'resErr' })
        );
    });
  };

  return (
    <>
      <div className="lh-wrapper">
        <Header isLoggedIn />
        <AccountSubmenuMobile />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Your Personal Information</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4 col-lg-3">
            <div className="edit-profile-picture">
              <div
                className="edit-profile-picture__overlay invisible"
                onClick={() => avatarRef.current.click()}
              >
                <p className="edit-profile-picture__text">
                  <i className="far fa-edit" /> Edit
                </p>
              </div>
              <img src={img.url} className="img img__profile" alt="" />
            </div>
            <input
              type="file"
              id="file"
              ref={avatarRef}
              style={{ display: 'none' }}
              onChange={(e) => onChangeFile(e)}
            />
            <div style={{ marginTop: '25px' }}>
              {img.error && <Error text="Please select a valid image file" />}
            </div>
            <p className="edit-profile-picture__smallprint">
              Click on image to edit
            </p>
            <Link
              to="/freelancer/edit-personal-information"
              className="form__btn form__edit-profile bg-freelancer d-block mb-50"
            >
              Edit personal information
            </Link>
          </div>
          <div className="col-12 col-md-8 col-lg-6">
            <div className="col-12 px-0">
              <div className="profile-bio-row row">
                <div className="col-12 add-opportunity-row__title font-weight-bold">
                  About Me{' '}
                  <span className="small">
                    (this is not a CV, just a simple overview)
                  </span>
                </div>
                <div className="col-12 col-lg-10 profile-bio-row__content">
                  <p>{bio}</p>
                </div>
              </div>
              <div className="profile-bio-row row">
                <div className="col-12 add-opportunity-row__title font-weight-bold">
                  Company Name
                </div>
                <div className="col-12 col-lg-10 profile-bio-row__content">
                  {company || 'No company information'}
                </div>
              </div>
              {position && (
                <div className="profile-bio-row row">
                  <div className="col-12 add-opportunity-row__title font-weight-bold">
                    Position
                  </div>
                  <div className="col-12 col-lg-10 profile-bio-row__content">
                    {position}
                  </div>
                </div>
              )}
              <div className="profile-bio-row row">
                <div className="col-12 add-opportunity-row__title font-weight-bold">
                  Address
                </div>
                <div className="col-12 col-lg-10 profile-bio-row__content">
                  {address_1}
                  <br />
                  {address_2 && address_2}
                </div>
              </div>
              <div className="profile-bio-row row">
                <div className="col-12 add-opportunity-row__title font-weight-bold">
                  City
                </div>
                <div className="col-12 col-lg-10 profile-bio-row__content">
                  {city}
                </div>
              </div>
              <div className="profile-bio-row row">
                <div className="col-12 add-opportunity-row__title font-weight-bold">
                  County
                </div>
                <div className="col-12 col-lg-10 profile-bio-row__content">
                  {state}
                </div>
              </div>
              <div className="profile-bio-row row">
                <div className="col-12 add-opportunity-row__title font-weight-bold">
                  Country
                </div>
                <div className="col-12 col-lg-10 profile-bio-row__content">
                  {country}
                </div>
              </div>
              <div className="profile-bio-row row">
                <div className="col-12 add-opportunity-row__title font-weight-bold">
                  Post code
                </div>
                <div className="col-12 col-lg-10 profile-bio-row__content">
                  {zip}
                </div>
              </div>
              <div className="profile-bio-row row">
                <div className="col-12 add-opportunity-row__title font-weight-bold">
                  Phone Number
                </div>
                <div className="col-12 col-lg-10 profile-bio-row__content">
                  {phone}
                </div>
              </div>
              {phone_secondary !== null &&
                phone_secondary !== undefined &&
                phone_secondary.length > 0 && (
                  <div className="profile-bio-row row">
                    <div className="col-12 add-opportunity-row__title font-weight-bold">
                      Secondary Phone Number
                    </div>
                    <div className="col-12 col-lg-10 profile-bio-row__content">
                      {phone_secondary}
                    </div>
                  </div>
                )}
              <div className="profile-bio-row row">
                <div className="col-12 add-opportunity-row__title font-weight-bold">
                  Email Address
                </div>
                <div className="col-12 col-lg-10 profile-bio-row__content">
                  {email}
                </div>
              </div>
              <div className="profile-bio-row row">
                <div className="col-12 add-opportunity-row__title font-weight-bold">
                  Additional Questions
                </div>
                <div className="col-12 col-lg-10 profile-bio-row__content">
                  {typeof looking_for === 'string' &&
                    looking_for.trim() !== '' &&
                    looking_for.search('Select') === -1 && (
                      <>
                        <span>Looking for a {looking_for} position</span>
                        <br />
                      </>
                    )}
                  {typeof jobLocation === 'string' &&
                    jobLocation.trim() !== '' &&
                    jobLocation.search('Select') === -1 && (
                      <>
                        <span style={{ textTransform: 'capitalize' }}>
                          Job Location: {jobLocation.toLowerCase()}
                        </span>
                        <br />
                      </>
                    )}
                  {typeof payroll_status === 'string' && payroll_status.trim() !== '' && (
                    <>
                      <span>{payrollStatusMap[payroll_status] || ''}</span>
                      <br />
                    </>
                  )}
                  {typeof hear_us === 'string' &&
                    hear_us.trim() !== '' &&
                    hear_us.search('Select') === -1 && (
                      <>
                        <span>
                          Heard about us{' '}
                          {['recommendation', 'other'].includes(hear_us)
                            ? `from ${
                                hear_us === 'recommendation'
                                  ? `recommendation (${hear_us_additional})`
                                  : hear_us_additional
                              }`
                            : `on ${hear_us.replace(/(^\w|\s\w)/g, (m) =>
                                m.toUpperCase()
                              )}`}
                        </span>
                        <br />
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
          <AccountSubmenuDesktop />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PersonalInformation;
