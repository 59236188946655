import React, { useContext, useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { userVar } from '../../graphql/config';
import Header from '../../components/Agency/Header';
import Footer from '../../components/Footer';
import jquery from '../../styles/js/app';
import Error from '../../components/Error';
import AccountSubmenuDesktop from '../../components/Agency/AccountSubmenuDesktop';
import AccountSubmenuMobile from '../../components/Agency/AccountSubmenuMobile';
import {
  getUser,
  isValidImage,
  saveUserLocalstorage,
} from '../../utils/helpers';
import { UPDATE_PROFILE } from '../../graphql';
import { AppContext } from '../../Store';

const ProfileEdit = ({ history }) => {
  useEffect(() => {
    jquery();
  }, []);
  const [state, setState] = useContext(AppContext);

  const avatarRef = useRef(null);
  const { register, watch, handleSubmit, errors } = useForm();
  const watchFields = watch(['bio', 'description']);
  const [updateProfile, { loading }] = useMutation(UPDATE_PROFILE);

  const [img, setImg] = useState({
    url:
      state?.activeCompany?.user?.avatar ||
      '/img/profile-picture-placeholder@2x.jpg',
    error: null,
  });

  const onChangeFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    toast.dismiss('invalidImage');

    const file = event.target.files[0];
    isValidImage(file, (valid) => {
      if (!valid) {
        setImg({ url: '/img/agency-picture-placeholder@2x.jpg', error: true });
        return toast.error('Please select a valid image file.', {
          toastId: 'invalidImage',
        });
      }
      setImg({
        url: valid.url,
        error: false,
        file: valid.file,
      });
    });
  };

  const editProfile = async (data) => {
    toast.dismiss();
    if (img.error) {
      return toast.error('Please select a valid image file.');
    }

    return updateProfile({
      variables: {
        input: {
          ...data,
          avatar: img.file,
        },
      },
    })
      .then(({ data: { updateUser } }) => {
        const updatedUserObj = { ...getUser(), ...updateUser };
        userVar(updatedUserObj);
        saveUserLocalstorage(updatedUserObj);
        return history.push('/agency/profile');
      })
      .catch((e) =>
        toast.error('Something went wrong!', { toastId: 'resErr' })
      );
  };

  return (
    <div className="lh-wrapper">
      <Header isLoggedIn />
      <AccountSubmenuMobile />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Update Your Company Profile</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4 col-lg-3">
            <div className="edit-profile-picture">
              <div
                className="edit-profile-picture__overlay invisible"
                onClick={() => avatarRef.current.click()}
              >
                <p className="edit-profile-picture__text">
                  <i className="far fa-edit" /> Edit
                </p>
              </div>
              <img src={img.url} className="img img__profile" alt="" />
            </div>
            <input
              type="file"
              id="file"
              ref={avatarRef}
              style={{ display: 'none' }}
              onChange={(e) => onChangeFile(e)}
            />
            <div style={{ marginTop: '25px' }}>
              {img.error && <Error text="Please select a valid image file" />}
            </div>
            <p className="edit-profile-picture__smallprint">
              Click on image to edit
            </p>
          </div>
          <div className="col-12 col-md-8 col-lg-6">
            <div className="row">
              <div className="col-12">
                <div className="profile-bio-row row">
                  <div className="col-12 add-opportunity-row__title">
                    <span className="txt__fw-600">Profile Bio</span>
                  </div>
                  <div className="col-12 profile-bio-row__content">
                    <textarea
                      rows="5"
                      maxLength="1000"
                      className="form__textarea__border-light height__unset form__edit-profile-textarea"
                      name="bio"
                      ref={register({ required: true })}
                      defaultValue={state?.activeCompany?.user?.bio}
                    />
                    <div className="form__textarea__character-limit">
                      {watchFields && (
                        <>
                          <span id="chars">
                            {'bio' in watchFields
                              ? watchFields.bio
                                ? watchFields.bio.length
                                : 0
                              : 0}
                          </span>{' '}
                          / 1000
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {errors.bio && <Error text="This field is required" noMargin />}
                <div className="profile-bio-row row">
                  <div className="col-12 add-opportunity-row__title">
                    <span className="txt__fw-600">What sets you apart</span>
                  </div>
                  <div className="col-12 profile-bio-row__content">
                    <textarea
                      rows="5"
                      maxLength="1000"
                      className="form__textarea__border-light height__unset form__edit-profile-textarea"
                      name="description"
                      ref={register({ required: true })}
                      defaultValue={state?.activeCompany?.user?.description}
                    />
                    <div className="form__textarea__character-limit">
                      {watchFields && (
                        <>
                          <span id="chars">
                            {'description' in watchFields
                              ? watchFields.description
                                ? watchFields.description.length
                                : 0
                              : 0}
                          </span>{' '}
                          / 250
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {errors.description && (
                  <Error text="This field is required" noMargin />
                )}
                <>
                  {getUser()?.type === 'GUEST' ||
                  getUser()?.type === 'FREELANCER' ? null : (
                    <>
                      <div className="profile-bio-row row">
                        <div className="col-12 add-opportunity-row__title">
                          <span className="txt__fw-600">Company Name</span>
                        </div>
                        <div className="col-12 profile-bio-row__content">
                          <div className="form__input-wrapper">
                            <input
                              className="form__input__border-light"
                              type="text"
                              name="company"
                              ref={register({ required: true })}
                              defaultValue={state?.activeCompany?.user?.company}
                            />
                          </div>
                        </div>
                      </div>
                      {errors.company && (
                        <Error text="This field is required" noMargin />
                      )}
                    </>
                  )}
                </>
                <div className="profile-bio-row row">
                  <div className="col-12 add-opportunity-row__title">
                    <span className="txt__fw-600">Address 1</span>
                  </div>
                  <div className="col-12 profile-bio-row__content">
                    <input
                      className="form__input__border-light"
                      type="text"
                      name="address_1"
                      ref={register({ required: true })}
                      defaultValue={state?.activeCompany?.user?.address_1}
                    />
                  </div>
                </div>
                {errors.address_1 && (
                  <Error text="This field is required" noMargin />
                )}
                <div className="profile-bio-row row">
                  <div className="col-12 add-opportunity-row__title">
                    <span className="txt__fw-600">Address 2</span>
                    <span className="small">(optional)</span>
                  </div>
                  <div className="col-12 profile-bio-row__content">
                    <input
                      className="form__input__border-light"
                      type="text"
                      name="address_2"
                      ref={register({ required: false })}
                      defaultValue={state?.activeCompany?.user?.address_2}
                    />
                  </div>
                </div>
                {errors.address_2 && (
                  <Error text="This field is required" noMargin />
                )}
                <div className="profile-bio-row row">
                  <div className="col-12 add-opportunity-row__title">
                    <span className="txt__fw-600">City</span>
                  </div>
                  <div className="col-12 profile-bio-row__content">
                    <input
                      className="form__input__border-light"
                      type="text"
                      name="city"
                      ref={register({ required: true })}
                      defaultValue={state?.activeCompany?.user?.city}
                    />
                  </div>
                </div>
                {errors.city && (
                  <Error text="This field is required" noMargin />
                )}
                <div className="profile-bio-row row">
                  <div className="col-12 add-opportunity-row__title">
                    <span className="txt__fw-600">Country</span>
                  </div>
                  <div className="col-12 profile-bio-row__content">
                    <input
                      className="form__input__border-light"
                      type="text"
                      name="country"
                      ref={register({ required: true })}
                      defaultValue={state?.activeCompany?.user?.country}
                    />
                  </div>
                </div>
                {errors.country && (
                  <Error text="This field is required" noMargin />
                )}
                <div className="profile-bio-row row">
                  <div className="col-12 add-opportunity-row__title">
                    <span className="txt__fw-600">Post code</span>
                  </div>
                  <div className="col-12 profile-bio-row__content">
                    <input
                      className="form__input__border-light"
                      type="text"
                      name="zip"
                      ref={register({ required: true })}
                      defaultValue={state?.activeCompany?.user?.zip}
                    />
                  </div>
                </div>
                {errors.zip && <Error text="This field is required" noMargin />}
                <div className="profile-bio-row row">
                  <div className="col-12 add-opportunity-row__title">
                    <span className="txt__fw-600">Phone Number</span>
                  </div>
                  <div className="col-12 profile-bio-row__content">
                    <input
                      className="form__input__border-light"
                      type="text"
                      name="phone"
                      ref={register({
                        required: true,
                        pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
                      })}
                      defaultValue={state?.activeCompany?.user?.phone}
                    />
                  </div>
                </div>
                {errors.phone && (
                  <Error text="Please enter a valid phone number" noMargin />
                )}
                <div className="profile-bio-row row">
                  <div className="col-12 add-opportunity-row__title">
                    <span className="txt__fw-600">Email Address</span>
                  </div>
                  <div className="col-12 profile-bio-row__content">
                    <input
                      className="form__input__border-light"
                      type="email"
                      name="email"
                      ref={register({
                        required: true,
                        pattern:
                          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      })}
                      defaultValue={state?.activeCompany?.user?.email}
                    />
                  </div>
                </div>
                {errors.email && (
                  <Error text="Please enter a valid email" noMargin />
                )}

                <a
                  href="/#"
                  className="form__btn d-block mb-50"
                  onClick={handleSubmit(editProfile)}
                >
                  {loading ? 'Loading...' : 'Save'}
                </a>
              </div>
            </div>
          </div>
          <AccountSubmenuDesktop />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProfileEdit;
