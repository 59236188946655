import React, { useState, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Route, Redirect, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { GET_AUTH_USER } from '../graphql';
import { AppContext } from '../Store';
import { userVar } from '../graphql/config';
import {
  getUser,
  getLoginType,
  logUserOut,
  getLoginUrl,
  saveLoginTypeLocalstorage,
} from '../utils/helpers';
import * as Sentry from "@sentry/browser";

const AuthWrapper = ({ component: Component, user, ...rest }) => {
  const location = useLocation();
  const isTeamRole = user.find((o) => o === 'AGENCY' || o === 'GUEST');
  const isLoggedIn = getUser().access_token || false;
  const [loginUrl, setLoginUrl] = useState(
    getLoginUrl(isTeamRole, location.pathname)
  );
  const [authenticatedUser, setAuthenticatedUser] = useState(undefined);
  const [state, setState] = useContext(AppContext);
  const { pathname } = location;
  const route = pathname.split('/');
  const cookies = new Cookies();

  useQuery(GET_AUTH_USER, {
    fetchPolicy: 'network-only',
    onCompleted: ({ me }) => {
      let activeCompany;
      const teams = me?.teamUser;
      if (teams?.length > 0) {
        const newlyInvitedCompanyId = cookies.get('lh_team');

        if (newlyInvitedCompanyId) {
          activeCompany = teams.find(
              (tu) => tu.team_id === newlyInvitedCompanyId
          );
          cookies.remove('lh_team', {
            path: '/',
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
          });
          cookies.remove('active_company', {
            path: '/',
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
          });
        }

        if (!activeCompany && cookies.get('active_company')) {
          activeCompany = teams[cookies.get('active_company')];
        }

        if (!activeCompany) {
          activeCompany = teams[0];
        }

        cookies.set(
            'active_company',
            teams.findIndex((tu) => tu.team_id === activeCompany.team_id),
            {
              path: '/',
              domain: process.env.REACT_APP_COOKIE_DOMAIN,
            }
        );
      }

      setAuthenticatedUser(me);
      setLoginUrl(
        teams && teams?.length > 0 && isTeamRole
          ? `/agency/login?redirectTo=${location.pathname}`
          : `/freelancer/login?redirectTo=${location.pathname}`
      );
      setState({
        ...state,
        userTeams: teams,
        activeCompany: activeCompany,
      });
      if (!me) {
        Sentry.setUser(null);
        userVar({});
        setState({});
        logUserOut();
        window.location.href = loginUrl;
      } else {

        Sentry.setUser({
          id: me.id,
          email: me.email,
          username: me.name
        });

        setLoginUrl(`${me.type}/login?redirectTo=${location.pathname}`);
      }
    },
  });

  /** Security Check On Role Access */
  if (authenticatedUser && user) {
    if (!user.find((o) => o === getUser()?.type)) {
      if (route[route.length - 1] !== 'login' && isLoggedIn) {
        return (
          <Redirect
            to={
              authenticatedUser?.teamUser &&
              authenticatedUser?.teamUser?.length > 0
                ? '/agency/dashboard'
                : '/freelancer/dashboard'
            }
          />
        );
      } else {
        userVar({});
        setState({});
        logUserOut();
        return <Redirect to={loginUrl} />;
      }
    }
  }

  /** Security Check On Restricted Team Member Access | Restricted Team Admin Only Pages */
  if (getUser() && user && getUser()?.type === 'GUEST') {
    if (state?.activeCompany?.role === 'member') {
      switch (pathname) {
        case '/agency/manage-payment-info':
          return <Redirect to={'/agency/dashboard'} />;
          break;
        case '/agency/manage-credits':
          return <Redirect to={'/agency/dashboard'} />;
          break;
        case '/agency/manage-team':
          return <Redirect to={'/agency/dashboard'} />;
          break;
        case '/agency/invitations':
          return <Redirect to={'/agency/dashboard'} />;
          break;
        case '/agency/billing-history':
          return <Redirect to={'/agency/dashboard'} />;
          break;
        default:
          break;
      }
    }
  }

  /** Security Check On Route Address */
  saveLoginTypeLocalstorage(getUser()?.type);
  if (isLoggedIn && route && route[1] && getLoginType()) {
    if (
      getLoginType()?.toLowerCase() !== 'guest' &&
      String(route[1])?.toLowerCase() !== getLoginType()?.toLowerCase()
    ) {
      return (
        <Redirect
          to={{
            pathname: `/${getLoginType()?.toLowerCase()}/dashboard`,
            state: { from: location },
          }}
        />
      );
    }
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? <Component {...props} /> : <Redirect to={loginUrl} />
      }
    />
  );
};

export default AuthWrapper;
