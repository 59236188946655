import gql from 'graphql-tag';

export const GET_JOB_TYPE = gql`
  query JobType($key: String!) {
    jobtype(key: $key) {
      key
      name
      description
    }
  }
`;

export const GET_ALL_JOB_TYPES = gql`
  query AllJobTypes {
    allJobtypes {
      key
      name
      description
    }
  }
`;
