import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useQuery } from '@apollo/react-hooks';
import jquery from '../../styles/js/app';
import Header from '../../components/Agency/Header';
import Footer from '../../components/Footer';
import { GET_OPPORTUNITY } from '../../graphql';

const ApplicantList = ({
  history,
  match: {
    params: { id },
  },
}) => {
  useEffect(() => {
    jquery();
  }, []);

  const {
    loading,
    data: { opportunity } = {},
    refetch,
  } = useQuery(GET_OPPORTUNITY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
    },
    onCompleted: () => toast.dismiss(),
    onError: () => refetch(),
  });

  return (
    <>
      <div className="lh-wrapper">
        <Header />
        <div className="container">
          <div className="row application-responses">
            <div className="col-12">
              <div style={{ flex: 1, textAlign: 'left' }} className="mb-30">
                <Link to="/agency/dashboard" className="btn back">
                  <i className="fas fa-arrow-left" />
                  Back
                </Link>
              </div>

              <h1 id="application-responses">
                {opportunity
                  ? `${opportunity.title} Applicants`
                  : 'Application Responses'}
              </h1>
              <span className="h1-subheading">
                (
                {opportunity &&
                'applicants' in opportunity &&
                opportunity.applicants.length
                  ? opportunity.applicants.filter((a) => a.user).length
                  : 0}{' '}
                new)
              </span>
            </div>
          </div>
          <div className="row">
            {loading && <div>Loading applicant list...</div>}
            {opportunity && 'applicants' in opportunity && (
              <div className="grid grid__applicants col-12">
                <span className="grid-heading name">Name</span>
                <span className="grid-heading date tablet">Date</span>
                <span className="grid-heading email desktop">Email</span>
                <span className="grid-heading phone desktop">Phone</span>
                <span className="grid-heading profile-link">&nbsp;</span>
                {opportunity.applicants
                  .filter((a) => a.user)
                  .map(({ user, created_at }) => (
                    <>
                      <span className="grid-content name">{user.name}</span>
                      <span className="grid-content date tablet">
                        {moment(created_at).format('DD/MM/YYYY')}
                      </span>
                      <span className="grid-content email desktop">
                        {user.email}
                      </span>
                      <span className="grid-content phone desktop">
                        {user.phone}
                      </span>
                      <span className="grid-content profile-link last-span">
                        <ul className="grid__ul">
                          <li className="grid__ul__li">
                            <Link
                              to={`/agency/freelancer-profile/${user.id}`}
                              className="link-agency"
                            >
                              View
                            </Link>
                          </li>
                        </ul>
                      </span>
                    </>
                  ))}
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ApplicantList;
