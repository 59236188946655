import axios from 'axios';
import formData from 'form-data';
import { getUser } from '../utils/helpers';

const apiUrl = process.env.REACT_APP_API_URL;

export const completeProfile = async (userData) => {
  try {
    const form = new formData();
    for (const key in userData) {
      form.append(key, userData[key]);
    }
    const { data } = await axios.post(`${apiUrl}/api/profile`, form, {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
        Authorization: `Bearer ${getUser().access_token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const googleLogin = async (code) => await axios.get(`${apiUrl}/login/google${code}`, {
  headers: {
    'Content-Type': 'application/json',
  },
});
