import React, { useState, useEffect, useContext } from 'react';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import AccountSubmenuMobile from '../../../components/Agency/AccountSubmenuMobile';
import AccountSubmenuDesktop from '../../../components/Agency/AccountSubmenuDesktop';
import DataTable from 'react-data-table-component';
import Header from '../../../components/Agency/Header';
import TeamUserAssociation from '../../../components/Agency/TeamUserAssociation';
import DeleteTeamMemberModal from '../../../components/Agency/DeleteTeamMemberModal';
import Footer from '../../../components/Footer';
import { getUser } from '../../../utils/helpers';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { AppContext } from '../../../Store';
import { GET_TEAM_USERS, UPDATE_TEAM_USER_SETTING } from '../../../graphql';
import './styles.css';

const CustomWrapper = styled.div`
  .account-submenu__desktop.bg-light-agency {
    min-width: 194.25px;
  }
  width: 9%;
  position: absolute;
  right: 0px;
`;

const TableWrapper = styled.div`
  div.rdt_TableCol div div {
    font-weight: 600 !important;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1rem;
    line-height: 1.5;
  }
  div.rdt_TableHeadRow {
    border-bottom-width: 1px;
    border-bottom-color: rgba(0, 0, 0, 1);
    border-bottom-style: solid;
  }
  div.rdt_TableCell {
    font-weight: 500 !important;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1rem;
    line-height: 1.5;
  }
`;

const ManageTeams = () => {
  const [state] = useContext(AppContext);
  const [memberList, setMemberList] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [activeSelectedRow, setActiveSelectedRow] = useState(undefined);
  const [hover, setHover] = useState(undefined);
  useEffect(() => {
    if (state?.activeCompany !== undefined || getUser()?.type === 'AGENCY') {
      getTeamUsers();
    }
  }, [state?.activeCompany]);
  const [getTeamUsers, { data: teamList, loading }] = useLazyQuery(
    /** Retrieve Team Members */
    GET_TEAM_USERS,
    {
      variables: {
        team_id: Number(state?.activeCompany?.team_id),
      },
      fetchPolicy: 'network-only',
    }
  );
  useEffect(() => {
    if (
      teamList?.viewUserTeam !== undefined &&
      teamList?.viewUserTeam?.length > 0
    ) {
      const holder = [];
      teamList.viewUserTeam.forEach((item, index) => {
        let host = item?.team;

        if (host) {
          holder.push({ ...item, ...host });
        }
      });

      setMemberList(
        holder.filter((a) => a?.id !== state?.activeCompany?.user_id)
      );
    }
  }, [teamList?.viewUserTeam]);

  /** Update Team User Setting */
  const [updateTeamUser] = useMutation(UPDATE_TEAM_USER_SETTING);

  const updateTeamUserRole = (e, item) => {
    toast.dismiss();
    updateTeamUser({
      variables: {
        input: {
          user_id: item.user_id,
          team_id: item.team_id,
          role: e.target.value,
        },
      },
    })
      .then(() => {
        toast.success('User role updated succesfully!', { autoClose: 4000 });
        getTeamUsers();
      })
      .catch(() =>
        toast.error('Could not update user role!', { toastId: 'appErr' })
      );
  };

  return (
    <>
      <div className="lh-wrapper">
        <Header isLoggedIn />
        <AccountSubmenuMobile />
        {showModal && (
          <DeleteTeamMemberModal
            onCloseCallback={() => setShowModal(false)}
            onSubmitCallback={() => getTeamUsers()}
            user={activeSelectedRow}
          />
        )}
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Manage Team</h1>
          </div>
        </div>

        <div
          className="row"
          style={{
            minHeight: '50vh',
            height: '100%',
          }}
        >
          <div className="col-12 col-md-12 col-lg-9">
            <TableWrapper>
              <DataTable
                columns={[
                  {
                    name: 'First Name',
                    selector: 'first_name',
                    sortable: false,
                  },
                  {
                    name: 'Last Name',
                    selector: 'last_name',
                    sortable: false,
                  },
                  {
                    name: 'Access Level',
                    selector: (item, index) => (
                      <select
                        name="freelancer"
                        className="form__input form__country"
                        style={{
                          width: 'fit-content',
                          height: 'auto',
                          background: '#237b9f',
                          borderRadius: '4px',
                          borderBottom: '1px solid transparent',
                          textAlign: 'center',
                          color: 'white',
                          padding: '3px',
                          cursor: 'pointer',
                        }}
                        defaultValue={item?.role}
                        onChange={(e) => updateTeamUserRole(e, item)}
                      >
                        <option value="member">Member</option>
                        <option value="admin">Admin</option>
                      </select>
                    ),
                    sortable: false,
                    center: true,
                  },
                  {
                    name: 'Actions',
                    selector: (item, index) => (
                      <>
                        {item?.role !== 'admin' && (
                          <button
                            type="button"
                            className="form__input form__country"
                            aria-disabled="false"
                            data-testid={'expander-button-' + (index + 1)}
                            aria-label="Expand Row"
                            role="button"
                            style={{
                              width: 'fit-content',
                              height: 'auto',
                              background: '#237b9f',
                              borderRadius: '4px',
                              borderBottom: '1px solid transparent',
                              color: 'white',
                              padding: '3px',
                            }}
                            disabled={item?.role === 'admin'}
                            onClick={() => {
                              if (expandedRows.find((a) => a === item?.id)) {
                                setExpandedRows(
                                  expandedRows.filter((a) => a !== item?.id)
                                );
                              } else {
                                setExpandedRows([...expandedRows, item?.id]);
                              }
                            }}
                          >
                            Account Access
                          </button>
                        )}
                      </>
                    ),
                    sortable: false,
                    width: '160px',
                  },
                  {
                    name: '',
                    selector: (item, index) => (
                      <>
                        {item?.role !== 'admin' && (
                          <button
                            type="button"
                            className="form__input form__country"
                            aria-disabled="false"
                            data-testid={'expander-button-' + (index + 1)}
                            aria-label="Expand Row"
                            role="button"
                            style={{
                              width: 'fit-content',
                              height: 'auto',
                              background: 'transparent',
                              borderBottom: '1px solid transparent',
                              borderBottomColor:
                                hover === index ? 'red' : 'transparent',
                              color: 'red',
                              padding: '3px',
                            }}
                            disabled={item?.role === 'admin'}
                            onClick={() => {
                              setActiveSelectedRow(item);
                              setShowModal(true);
                            }}
                            onMouseEnter={() => {
                              setHover(index);
                            }}
                            onMouseLeave={() => {
                              setHover(undefined);
                            }}
                          >
                            Remove User
                          </button>
                        )}
                      </>
                    ),
                    sortable: false,
                    width: '160px',
                  },
                ]}
                data={memberList}
                expandableRows
                expandableRowsComponent={
                  <TeamUserAssociation members={memberList} />
                }
                expandableRowDisabled={(row) => row?.role === 'admin'}
                expandableRowsHideExpander={true}
                expandOnRowClicked={false}
                expandableRowExpanded={(row) =>
                  expandedRows.find((a) => a === row.id)
                }
                keyField={'id'}
              />
            </TableWrapper>
          </div>
          <CustomWrapper>
            <AccountSubmenuDesktop />
          </CustomWrapper>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ManageTeams;
