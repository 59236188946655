import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Agency/Header';
import Footer from '../../components/Footer';
import jquery from '../../styles/js/app';
import AccountSubmenuMobile from '../../components/Agency/AccountSubmenuMobile';
import AccountSubmenuDesktop from '../../components/Agency/AccountSubmenuDesktop';
import { AppContext } from '../../Store';

const Profile = () => {
  useEffect(() => {
    jquery();
  }, []);
  const [state, setState] = useContext(AppContext);

  return (
    <>
      <div className="lh-wrapper">
        <Header isLoggedIn />
        <AccountSubmenuMobile />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Update Your Company Information</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4 col-lg-3">
            <div className="edit-profile-picture">
              {state?.activeCompany?.role !== 'member' && (
                <div className="edit-profile-picture__overlay invisible">
                  <p className="edit-profile-picture__text">
                    <i className="far fa-edit" /> Edit
                  </p>
                </div>
              )}
              <img
                src={
                  state?.activeCompany?.user?.avatar ||
                  '/img/profile-picture-placeholder@2x.jpg'
                }
                className="img img__profile"
                alt=""
              />
            </div>
            {state?.activeCompany?.role !== 'member' && (
              <Link
                to="/agency/edit-profile"
                className="form__btn form__edit-profile d-block mb-50"
              >
                Edit profile
              </Link>
            )}
          </div>
          <div className="col-12 col-md-8 col-lg-6">
            <div className="col-12 px-0">
              <div className="profile-bio-row row">
                <div className="col-12 add-opportunity-row__title font-weight-bold">
                  Profile Bio
                </div>
                <div className="col-12 col-lg-12 profile-bio-row__content">
                  <p>{state?.activeCompany?.user?.bio}</p>
                </div>
              </div>
              <div className="profile-bio-row row">
                <div className="col-12 add-opportunity-row__title font-weight-bold">
                  What sets you apart
                </div>
                <div className="col-12 col-lg-12 profile-bio-row__content">
                  <p>{state?.activeCompany?.user?.description}</p>
                </div>
              </div>
              <div className="profile-bio-row row">
                <div className="col-12 add-opportunity-row__title font-weight-bold">
                  Company Name
                </div>
                <div className="col-12 col-lg-12 profile-bio-row__content">
                  {state?.activeCompany?.user?.company}
                </div>
              </div>
              <div className="profile-bio-row row">
                <div className="col-12 add-opportunity-row__title font-weight-bold">
                  Address
                </div>
                <div className="col-12 col-lg-12 profile-bio-row__content">
                  {state?.activeCompany?.user?.address_1}
                  <br />
                  {state?.activeCompany?.user?.address_2}
                  <br />
                  {state?.activeCompany?.user?.city}
                  <br />
                  {state?.activeCompany?.user?.state}
                  <br />
                  {state?.activeCompany?.user?.country}
                  <br />
                  {state?.activeCompany?.user?.zip}
                </div>
              </div>
              <div className="profile-bio-row row">
                <div className="col-12 add-opportunity-row__title font-weight-bold">
                  Phone Number
                </div>
                <div className="col-12 col-lg-12 profile-bio-row__content">
                  {state?.activeCompany?.user?.phone}
                </div>
              </div>
              <div className="profile-bio-row row">
                <div className="col-12 add-opportunity-row__title font-weight-bold">
                  Email Address
                </div>
                <div className="col-12 col-lg-12 profile-bio-row__content">
                  {state?.activeCompany?.user?.email}
                </div>
              </div>
            </div>
          </div>
          <AccountSubmenuDesktop />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Profile;
