import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_AGENCY_OPPORTUNITIES } from '../../../../graphql';
import { Link } from 'react-router-dom';
import OpportunityCard from '../../Components/OpportunityCard';

const LatestOpportunities = ({ user }) => {
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [jobOpportunities, setJobOpportunities] = useState([]);

  const { loading: latestOpportunitiesLoading, refetch: refetchOpportunities } =
    useQuery(GET_AGENCY_OPPORTUNITIES, {
      fetchPolicy: 'cache-and-network',
      variables: {
        trashed: 'WITHOUT',
        first: 6,
        orderBy: [
          { field: 'FEATURED', order: 'DESC' },
          { field: 'CREATED_AT', order: 'DESC' },
          { field: 'TITLE', order: 'ASC' },
        ],
      },
      onCompleted: ({ opportunities }) => {
        const response = opportunities?.data;
        if (response?.length > 0) {
          setInitialLoadComplete(true);
          setJobOpportunities(response);
        }
      },
      onError: () => refetchOpportunities(),
    });

  return (
    <>
      <div className="col-12">
        <h1>Latest Job Opportunities</h1>
      </div>
      {latestOpportunitiesLoading &&
        initialLoadComplete === false &&
        'Loading latest job opportunities...'}
      {jobOpportunities?.map(
        ({
          agency,
          title,
          created_at,
          genres,
          skills,
          type,
          id,
          updated_at,
        }) => (
          <OpportunityCard
            key={created_at}
            createdAt={created_at}
            updatedAt={updated_at}
            {...{
              genres,
              skills,
              user,
              type,
              id,
              title,
              agency,
            }}
          />
        )
      )}
      <div className="col-12 text-right mb-100">
        <Link
          to="/freelancer/latest-opportunities"
          className="link-freelancer txt__fw-600"
        >
          View More
        </Link>
      </div>
    </>
  );
};

export default LatestOpportunities;
