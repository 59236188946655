import React, { useEffect } from 'react';
import Header from '../../components/Marketing/Header';
import Footer from '../../components/Footer';
import jquery from '../../styles/js/app';
import BlogpostSidebar from '../../components/Marketing/BlogpostSidebar';

const BlogIndividual = () => {
  useEffect(() => {
    jquery();
  }, []);

  const OtherBlogposts = () => {
    const blogPosts = [
      'Crafting the perfect description for your opportunity to get the right canditates',
      'How to update your profile picture on Liberty Hive',
      '21 Things to consider when making your new hire',
    ];
    return (
      <div className="col-lg-4 offset-lg-1">
        <h2 className="txt__fw-600 mb-20">Other Blog Posts</h2>
        <div className="row">
          {blogPosts.map((post) => (
            <BlogpostSidebar title={post} />
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="lh-wrapper">
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <a href="/#" className="link-marketing txt__fw-600 mb-20 d-block">
              <i className="fa fa-chevron-left" />
              {' '}
              Back
            </a>
          </div>
          <div className="col-lg-7">
            <h1>
              Crafting the perfect description for your opportunity to get the
              right canditates
            </h1>
            <img src="img/cta-placeholder@2x.jpg" alt="" className="w-100 mb-30" />
            <p className="txt__fw-600 mb-70">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non
              leo vitae ligula dignissim placerat. Vestibulum nibh quam, dictum
              non eleifend vitae, ullamcorper quis nibh. Etiam id enim ac magna
              tincidunt auctor id tincidunt lorem. Quisque ac ex blandit,
              pellentesque est eu, varius ante. Praesent bibendum iaculis eros,
              in porta orci laoreet ac. Nullam elementum interdum cursus. Fusce
              vel tortor sapien. Duis hendrerit erat velit. Sed sodales neque
              nec neque tempor mattis. Morbi hendrerit ligula id molestie
              ullamcorper. Mauris hendrerit leo sem, eu pulvinar enim facilisis
              a. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed in
              consequat felis. Sed nulla orci, facilisis id arcu a, eleifend
              cursus quam. Cras id placerat leo, commodo egestas quam.
              <br />
              <br />
              Nullam facilisis ipsum sit amet volutpat tempor. Nunc sed viverra
              diam. Maecenas hendrerit diam vel arcu aliquet convallis.
              Phasellus condimentum maximus velit. Praesent eget tristique eros.
              Nunc in purus justo. Integer suscipit elit sed pellentesque
              volutpat. Maecenas molestie interdum augue sollicitudin pulvinar.
              Donec in efficitur quam, vel elementum ante. Praesent faucibus ex
              nisl, quis viverra magna maximus id. Nullam nec augue a enim
              efficitur porttitor at eleifend mauris. Fusce quis facilisis erat.
              Nam quis tellus urna. Quisque vel porta massa.
              <br />
              <br />
              Ut sagittis tempor iaculis. Pellentesque ut turpis vitae massa
              rhoncus laoreet et vel lectus. Donec eu facilisis est. Phasellus
              ut diam sit amet ipsum dictum pharetra sollicitudin sed nibh.
              Morbi ullamcorper, dui quis placerat mattis, quam odio interdum
              elit, sed tempor tellus ligula eu diam. Vestibulum tincidunt lorem
              quis neque gravida, vitae dignissim ligula lobortis. Cras
              porttitor pretium enim, vitae lacinia metus suscipit at. Nulla
              ornare, velit volutpat posuere blandit, mi urna pulvinar augue,
              sed efficitur arcu metus ac tellus. Donec imperdiet vitae odio et
              maximus. Cras eget felis libero. Suspendisse sollicitudin ac enim
              non convallis. Vestibulum fermentum dapibus leo. Nulla commodo eu
              eros non ultricies.
              <br />
              <br />
              Morbi in purus aliquet, sagittis nulla eget, semper lorem.
              Curabitur nec euismod purus. Cras sollicitudin dui nec dui
              tincidunt, at mattis elit sodales. Nullam venenatis tincidunt
              lacus id semper. Duis mollis vitae nibh at fermentum. Integer id
              ligula a metus aliquet pulvinar bibendum id lacus. Vestibulum
              efficitur ultricies neque, vel lacinia purus imperdiet non. Sed
              elementum commodo erat eget ornare. Pellentesque et ultricies
              ipsum. Ut facilisis mattis diam, vel ultrices augue tristique
              quis.
              <br />
              <br />
              Vivamus porttitor nisl ut enim scelerisque ultricies. Nunc ut
              dignissim neque. Aenean et commodo velit. Vestibulum dolor dui,
              volutpat nec sagittis at, viverra sit amet ligula. Praesent
              dignissim mauris magna, sit amet mattis leo condimentum sit amet.
              Morbi vulputate diam ut placerat lobortis. Duis semper diam et
              euismod facilisis. Duis tincidunt consectetur lobortis. Quisque
              rutrum justo eu dui lacinia, vel varius ipsum fringilla. Vivamus
              sagittis purus eu purus malesuada accumsan. Quisque pellentesque
              tempus diam, at condimentum ante fermentum a. Vestibulum convallis
              dolor non libero tincidunt, eget rhoncus arcu venenatis. Ut dolor
              elit, scelerisque a semper egestas, rutrum a diam. In quis
              bibendum ante. Curabitur tempus nunc est, vel lobortis nisi
              dignissim nec. Cras non urna ornare, volutpat augue ut, malesuada
              tortor.
            </p>
          </div>
          <OtherBlogposts />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogIndividual;
