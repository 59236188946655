import React, { useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { VERIFY_NUMBER } from '../../../../graphql';
import Error from '../../../Error';

const PhoneVerificationModal = ({
  onSubmitCallback,
  onCloseCallback,
  onBackCallback,
}) => {
  const { register, handleSubmit } = useForm();
  const [codeError, setCodeError] = useState(false);
  const [code, setCode] = useState(null);
  const [verifyNumber] = useMutation(VERIFY_NUMBER);

  const customStyles = {
    content: {
      top: '35%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      paddingLeft: 51,
      paddingRight: 51,
      borderRadius: 0,
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
  };

  const VerifyCode = (data) =>
    verifyNumber({
      variables: {
        code: data.code,
      },
    })
      .then(() => onSubmitCallback())
      .catch(() => {
        setCodeError(true);
        toast.error('Something went wrong.', { autoClose: 5000 });
      });

  const handleEnterKey = (event) => {
    if (event.key === 'Enter') {
      return handleSubmit(VerifyCode)();
    }
  };

  // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
  Modal.setAppElement('#root');

  return (
    <Modal isOpen={true} style={customStyles} contentLabel="Example Modal">
      <form
        className="form"
        onSubmit={handleSubmit(VerifyCode)}
        style={{
          width: '100%',
        }}
      >
        <div
          style={{
            minWidth: '395px',
            minHeight: '400px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              flex: 7,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src="/img/close.png"
              style={{
                width: 12,
                height: 12,
                position: 'absolute',
                top: 20,
                right: 20,
              }}
              alt="close"
              onClick={() => onCloseCallback()}
            />
            <span
              style={{
                fontWeight: '600',
                fontSize: '20px',
                lineHeight: '26px',
                textAlign: 'center',
              }}
            >
              SMS Notification
            </span>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flex: 0.5,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              paddingRight: '5%',
            }}
          >
            <span
              style={{
                fontWeight: '300',
                fontSize: '16px',
                lineHeight: '26px',
                textAlign: 'left',
              }}
            >
              We’ve sent you a 6 digit verification code, please enter it below.
            </span>
          </div>
          <div
            style={{
              width: '90%',
              left: '-10%',
              display: 'flex',
              flex: 0.7,
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              alignItems: 'flex-start',
              alignSelf: 'flex-start',
            }}
          >
            <input
              className="form__input"
              type="text"
              autoComplete="false"
              name="code"
              ref={register({
                required: true,
                pattern: /^[0-9]{1,6}$/,
                maxLength: 6,
              })}
              onKeyDown={handleEnterKey}
            />
            <div className="form__clear form__hidden">
              <i className="fas fa-times" />
            </div>
            {codeError && (
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  marginTop: '30px',
                }}
              >
                <Error text="Please enter a valid code" />
              </div>
            )}
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flex: 2,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <button
              type="submit"
              className="form__btn form__edit-profile bg-freelancer d-block mb-50"
              style={{
                width: 300,
                height: 48,
              }}
            >
              <span
                style={{
                  fontWeight: 700,
                  fontSize: '16px',
                  lineHeight: '24px',
                  letterSpacing: '5%',
                }}
              >
                VERIFY
              </span>
            </button>
          </div>
          <div
            style={{
              display: 'flex',
              flex: 9,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div>
              I didn't get the code.&nbsp;
              <span
                className="link-freelancer"
                onClick={() => onBackCallback()}
              >
                Try again
              </span>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default PhoneVerificationModal;
