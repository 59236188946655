import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Link, Redirect } from 'react-router-dom';
import Error from '../../components/Error';
import Footer from '../../components/Footer';
import jquery from '../../styles/js/app';
import HeaderBlog from '../../components/Agency/HeaderBlog';
import { forgotPassword } from '../../api/auth';
import { formatValidationErrors } from '../../utils/helpers';

const ForgotPassword = () => {
  useEffect(() => {
    jquery();
  }, []);

  const [buttonText, setButtonText] = useState('SEND PASSWORD RESET');
  const { register, handleSubmit, errors } = useForm();

  const sendPasswordReset = async (data) => {
    toast.dismiss();
    setButtonText('Loading...');
    const response = await forgotPassword(data);
    setButtonText('SEND PASSWORD RESET');
    if (response.data && response.data.errors) {
      response.status === 422
        ? toast.error(formatValidationErrors(response.data.errors))
        : toast.error(response.data.error);
    } else {
      toast.success(response.message);
      return (
        <Redirect
          to={{
            pathname: '/agency/login',
            state: { passwordReset: true },
          }}
        />
      );
    }
  };

  return (
    <div className="lh-wrapper">
      <HeaderBlog />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="onboarding">
              <h1 className="center mb-50">Forgot your password?</h1>
              <form className="form">
                <div className="form__heading mt-0">Email</div>
                <div className="form__input-block">
                  <div className="form__input-wrapper">
                    <input
                      className="form__input"
                      type="text"
                      name="email"
                      ref={register({
                        required: true,
                        pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      })}
                      autoComplete="false"
                    />
                    <div className="form__clear form__hidden">
                      <i className="fas fa-times" />
                    </div>
                  </div>
                </div>
                {errors.email && (
                  <Error text="Please enter a valid email address" />
                )}
                <a
                  href="agency-create-account-step-2.html"
                  className="form__btn d-block mb-50"
                  onClick={handleSubmit(sendPasswordReset)}
                >
                  {buttonText}
                </a>
                <div className="d-flex justify-content-center mb-60">
                  <Link
                    to="/agency/register"
                    className="link-agency txt__fw-600"
                  >
                    Need an account?
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default ForgotPassword;
