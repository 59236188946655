import React, { useEffect, useState, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import Header from '../../components/Agency/Header';
import Footer from '../../components/Footer';
import jquery from '../../styles/js/app';
import { getUser } from '../../utils/helpers';
import AccountSubmenuDesktop from '../../components/Agency/AccountSubmenuDesktop';
import AccountSubmenuMobile from '../../components/Agency/AccountSubmenuMobile';
import { GET_CREDIT_HISTORY, GET_SUBSCRIPTION_HISTORY } from '../../graphql';
import { AppContext } from '../../Store';

const BillingHistory = () => {
  useEffect(() => {
    jquery();
  }, []);
  const [state, setState] = useContext(AppContext);
  const [list, setList] = useState([]);

  const { refetch: refetchCredit } = useQuery(GET_CREDIT_HISTORY, {
    fetchPolicy: 'network-only',
    variables: {
      orderBy: {
        field: { field: 'CREATED_AT', order: 'DESC' },
      },
      agency: state?.activeCompany?.team_id,
      type: 'IN',
    },
    onCompleted: (data) => {
      const combined = list.concat(
        data.creditHistory.map(({ qty, total, invoice_id, created_at }) => ({
          qty,
          total,
          invoice_id,
          created_at,
        }))
      );
      setList(
        combined.sort((a, b) => {
          if (a.created_at > b.created_at) {
            return -1;
          }
          if (b.created_at > a.created_at) {
            return 1;
          }
          return 0;
        })
      );
    },
    onError: () => refetchCredit(),
  });

  const { refetch: refetchSubscription } = useQuery(GET_SUBSCRIPTION_HISTORY, {
    fetchPolicy: 'network-only',
    variables: {
      orderBy: {
        field: { field: 'CREATED_AT', order: 'DESC' },
      },
      agency: state?.activeCompany?.team_id,
    },
    onCompleted: (data) => {
      const combined = list.concat(
        data.subscriptionHistory.map(({ price, stripe_id, created_at }) => ({
          name: 'Monthly Subscription',
          total: price,
          invoice_id: stripe_id,
          created_at,
        }))
      );
      setList(
        combined.sort((a, b) => {
          if (a.created_at > b.created_at) {
            return -1;
          }
          if (b.created_at > a.created_at) {
            return 1;
          }
          return 0;
        })
      );
    },
    onError: () => refetchSubscription(),
  });

  useEffect(() => {
    if (state?.activeCompany !== undefined) {
      setList([]);
      refetchCredit();
      refetchSubscription();
    }
  }, [state?.activeCompany]);

  const downloadInvoice = (invoiceId) => {
    const url = `${process.env.REACT_APP_API_URL}/api/user/${
      getUser().id
    }/invoice/${invoiceId}`;
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div className="lh-wrapper">
      <Header isLoggedIn />
      <AccountSubmenuMobile />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Billing History</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-9">
            <table className="table table__simple">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Date</th>
                  <th>Amount</th>
                  <th className="d-none d-md-table-cell">Invoice</th>
                </tr>
              </thead>
              <tbody>
                {list.map((item) => (
                  <tr>
                    <td>
                      {item.qty} {item.total === 0 ? 'Free' : ''} Job Post
                      {item.qty > 1 ? 's' : ''}
                    </td>
                    <td>{moment(item.created_at).format('DD/MM/YYYY')}</td>
                    <td>
                      {item.total > 0 && (
                        <span>
                          &pound;
                          {item.total.toFixed(2)}
                        </span>
                      )}
                    </td>
                    <td className="d-none d-md-table-cell">
                      {item.total > 0 && (
                        <div
                          onClick={() => {
                            downloadInvoice(item.invoice_id);
                          }}
                          className="btn link-agency font-weight-bold"
                        >
                          Download PDF
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <AccountSubmenuDesktop />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BillingHistory;
