import gql from 'graphql-tag';

export const CREATE_TEAM_USER = gql`
  mutation CreateTeamUser($input: UserTeamCreate!) {
    createUserTeam(input: $input) {
        user_id
        team_id
        role
        active
    }
  }
`;

export const UPDATE_TEAM_USER_SETTING = gql`
  mutation UpdateUserTeam($input: UserTeamUpdate!) {
    updateUserTeam(input: $input) {
        user_id
        team_id
        role
        active
    }
  }
`;

export const DELETE_TEAM_USER_SETTING = gql`
  mutation DeleteUserTeam($input: UserTeamDelete!) {
    deleteUserTeam(input: $input) 
  }
`;
