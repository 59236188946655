import gql from 'graphql-tag';

export const CREATE_INVITATION = gql`
  mutation CreateInvitation($input: InvitationCreate!) {
    createInvitation(input: $input) {
        id
        email
        team_id
        invite_status
    }
  }
`;

export const UPDATE_INVITATION = gql`
  mutation UpdateInvitation($id: ID!) {
    updateInvitation(id: $id) {
        id
        email
        team_id
        invite_status
    }
  }
`;

export const PROCESS_INVITATION = gql`
  mutation ProcessInvitation($email: String!, $token: String!) {
    processInvitation(email: $email, token: $token) {
        id
        email
        team_id
        invite_status
    }
  }
`;

export const DELETE_INVITATION = gql`
  mutation DeleteInvitation($id: ID!) {
    deleteInvitation(id: $id) {
        id
        email
        team_id
        invite_status
    }
  }
`;