import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { CHECK_USER } from '../../graphql';
import Header from '../../components/Freelancer/Header';
import Footer from '../../components/Footer';
import jquery from '../../styles/js/app';
import Error from '../../components/Error';
import { registerVar } from '../../graphql/config';

const CreateAccount = ({ history }) => {
  useEffect(() => {
    jquery();
  }, []);

  const { register, handleSubmit, errors } = useForm();
  const [credentials, setCredentials] = useState({});
  const [check, { data, loading, refetch }] = useLazyQuery(CHECK_USER, {
    onCompleted: () => isUniqueEmail(),
    onError: () => refetch(),
  });

  const isUniqueEmail = () => {
    toast.dismiss();
    if (data.checkUser) {
      registerVar(credentials);
      return history.push('/freelancer/register/2');
    }
    toast.error('Email already exists!', { toastId: 'emXFrlcr' });
  };

  const CheckEmail = (input) => {
    setCredentials(input);
    check({
      variables: {
        email: input.email,
      },
    });
  };

  const handleEnterKey = (event) => {
    if (event.key === 'Enter') {
      return handleSubmit(CheckEmail)();
    }
  };

  const openInNewTab = (url) => {
    localStorage.setItem('userType', 'freelancer');
    window.location.replace(url);
  };

  return (
    <>
      <div className="lh-wrapper">
        <Header />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="onboarding">
                <h1 className="center">Create A Candidate Account</h1>
                <div className="pagination-bubble">
                  <div className="pagination-bubble__freelancer__item pagination-bubble__freelancer__active">
                    1
                  </div>
                  <div className="pagination-bubble__freelancer__item bg-light-freelancer">
                    2
                  </div>
                  <div className="pagination-bubble__freelancer__item bg-light-freelancer">
                    3
                  </div>
                </div>
                <div className="mb-60">Login information</div>
                <form
                  className="form form__freelancer"
                  onSubmit={handleSubmit(CheckEmail)}
                >
                  <div className="form__input-block">
                    <div className="form__heading mt-0">Email</div>
                    <div className="form__input-wrapper">
                      <input
                        className="form__input"
                        type="text"
                        name="email"
                        defaultValue={registerVar().email || ''}
                        ref={register({
                          required: true,
                          pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        })}
                        autoComplete="false"
                      />
                      <div className="form__clear form__hidden">
                        <i className="fas fa-times" />
                      </div>
                    </div>
                  </div>
                  {errors.email && (
                    <Error text="Please enter a valid email address" />
                  )}
                  <div className="form__input-block">
                    <div className="form__heading">Password</div>
                    <div className="form__input-wrapper">
                      <input
                        className="form__input"
                        type="password"
                        id="onboarding-password"
                        name="password"
                        defaultValue={registerVar().password || ''}
                        ref={register({ required: true, minLength: 8 })}
                        autoComplete="false"
                        onKeyDown={handleEnterKey}
                      />
                      <div className="form__clear form__hidden">
                        <i className="fas fa-times" />
                      </div>
                      <div
                        toggle="#onboarding-password"
                        className="fas fa-eye form__toggle-password form__hidden"
                      />
                    </div>
                  </div>
                  {errors.password && (
                    <Error text="Password must be at least 8 characters" />
                  )}
                  <button
                    type="submit"
                    className="form__btn btn bg-freelancer d-block"
                  >
                    {loading ? 'Loading...' : 'Create An Account'}
                  </button>
                  <p className="form__text">or</p>
                  <div
                    onClick={() => {
                      openInNewTab(
                        `${process.env.REACT_APP_API_URL}/redirect/google`,
                      );
                    }}
                    className="form__btn-outline-purple d-block"
                  >
                    Sign Up With Google
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CreateAccount;
