import React, { useEffect, useState } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Header from '../../components/Agency/Header';
import Footer from '../../components/Footer';
import jquery from '../../styles/js/app';
import { GET_USER, GET_APPLICANT } from '../../graphql';

const FreelancerProfile = ({
  match: {
    params: { id },
  },
}) => {
  useEffect(() => {
    jquery();
  }, []);

  const params = id.split('-');
  const [freelancerId, setFreelancerId] = useState(0);
  const [applicantId, setApplicantId] = useState(0);

  useEffect(() => {
    setFreelancerId(params[0]);

    if (params[1] !== undefined && !called) {
      setApplicantId(params[1]);
      getApplicantData();
    }
  }, [params]);

  const allJobtypes = [
    {
      key: 'PERMANENT',
      name: 'Permanent',
    },
    {
      key: 'FREELANCE',
      name: 'Freelance',
    },
    {
      key: 'BOTH',
      name: 'Both',
    },
  ];

  const allSubJobtypes = [
    {
      key: 'FULLTIME',
      name: 'Full-time',
    },
    {
      key: 'PARTTIME',
      name: 'Part-time',
    },
    {
      key: 'BOTH_TIME',
      name: 'Both',
    },
  ];

  const [types, setTypes] = useState([]);
  const [workHour, setWorkHour] = useState([]);

  const { data = {}, loading } = useQuery(GET_USER, {
    variables: {
      id: freelancerId,
    },
    onCompleted: () => {
      if (data?.user?.jobTypes.length > 0) {
        const firstJobType = allJobtypes.filter((j) =>
          data?.user?.jobTypes.map((ajt) => ajt.key).includes(j.key)
        );
        const secondJobType = allSubJobtypes.filter((j) =>
          data?.user?.jobTypes.map((asjt) => asjt.key).includes(j.key)
        );

        if (firstJobType.length > 0) {
          if (firstJobType[0]?.name === 'Both') {
            setTypes(['Permanent', 'Freelance']);
          } else {
            setTypes([firstJobType[0].name]);
          }
        }

        if (secondJobType.length > 0) {
          if (secondJobType[0]?.name === 'Both') {
            setWorkHour(['Full-time', 'Part-time']);
          } else {
            setWorkHour([secondJobType[0].name]);
          }
        }
      }
    },
  });

  const [
    getApplicantData,
    { called, data: { applicant = {} } = {}, loading: loadingApplicant },
  ] = useLazyQuery(GET_APPLICANT, {
    variables: {
      id: applicantId,
    },
  });

  const SideBarLeft = () => (
    <div className="col-12 col-md-4 col-lg-3">
      <div className="edit-profile-picture mx-auto">
        <img
          src={data?.user?.avatar || '/img/profile-picture-placeholder@2x.jpg'}
          className="img img__profile"
          alt={data?.user?.name}
        />
      </div>
      <a
        href={`mailto:${data?.user?.email}`}
        className="form__btn d-block bg-agency mb-3"
      >
        Contact
      </a>
      {/* <a href='/#' class='form__btn-outline-purple d-block mb-50'>
        Shortlist
      </a> */}
    </div>
  );

  const Main = () => (
    <div className="col-12 col-md-8 col-lg-6">
      <h2 className="mb-20 txt__fw-600">About Me</h2>
      <p className="profile-bio-row__content">{data?.user?.bio}</p>
      <div className="mb-30" />
      <h2 className="txt__fw-600 mb-20">Experience</h2>
      {data &&
        data?.user?.experiences
          .sort(
            (a, b) =>
              moment(b.from).format('YYYY') - moment(a.from).format('YYYY')
          )
          .map(({ title, company, description, from, to }) => (
            <>
              <h3 className="txt__fw-600 mb-1">{title}</h3>
              <p className="txt__fw-600 mb-1">{company}</p>
              <p className="txt__light mb-10">
                {' '}
                {moment(from).format('YYYY')} -{' '}
                {moment(to).format('YYYY') || 'Present'}
              </p>
              <p className="mb-20">{description}</p>
            </>
          ))}
      <div className="mb-30" />
      <h2 className="txt__fw-600 mb-20">Qualifications</h2>
      {data &&
        data?.user?.education
          .sort(
            (a, b) =>
              moment(b.from).format('YYYY') - moment(a.from).format('YYYY')
          )
          .map(({ qualification, from, to, description }) => (
            <>
              <h3 className="txt__fw-600 mb-1">{qualification}</h3>
              {from && (
                <p className="txt__light mb-10">
                  {moment(from).format('YYYY')}{' '}
                  {to && `-${moment(to).format('YYYY') || 'Present'}`}
                </p>
              )}
              <p className="mb-20">{description}</p>
            </>
          ))}
      {data && data?.user?.linkedin && (
        <>
          <div className="mb-30" />

          <h2 className="txt__fw-600 mb-20">LinkedIn</h2>
          <p className="txt__fw-600">
            <a
              className="link-freelancer"
              href={data?.user?.linkedin}
              target="_blank"
            >
              View their LinkedIn
            </a>
          </p>
        </>
      )}
      {data && data?.user?.resumes?.length > 0 && (
        <>
          <div className="mb-30" />

          <h2 className="txt__fw-600 mb-20">CV</h2>
          <p className="txt__fw-600">
            <a
              className="link-freelancer"
              href={applicant?.resumeUrl ?? data?.user?.resumes[0]?.fileUrl}
              target="_blank"
            >
              View their CV
            </a>
          </p>
        </>
      )}
      {applicant && applicant?.documentUrl && (
        <>
          <div className="mb-30" />

          <h2 className="txt__fw-600 mb-20">Additional Attachments</h2>
          <p className="txt__fw-600">
            <a
              className="link-freelancer"
              href={applicant?.documentUrl}
              target="_blank"
            >
              {applicant?.document}
            </a>
          </p>
        </>
      )}
      {applicant &&
        applicant?.reason !== undefined &&
        applicant?.reason !== null &&
        applicant?.reason.trim() !== '' && (
          <>
            <div className="mb-30" />

            <h2 className="txt__fw-600 mb-20">
              Why are they right for this role?
            </h2>
            <p>{applicant?.reason}</p>
          </>
        )}
    </div>
  );

  const SideBarRight = () => (
    <div className="col-12 col-lg-3">
      <div className="p-20 bg-light-agency">
        <div className="row">
          {data?.user?.skills && (
            <div className="col-12 col-md-4 col-lg-12">
              <p className="txt__fw-600 mb-2">Skills</p>
              <ul className="account-submenu__list mb-20">
                {data?.user?.skills.map(({ name }) => (
                  <li className="account-submenu__list__item">
                    <img src="/img/lh-logo-sm-2.png" className="mr-2" alt="" />{' '}
                    <a href="/#" className="link-agency">
                      {name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {data?.user?.genres.length > 0 && (
            <div className="col-12 col-md-4 col-lg-12">
              <p className="txt__fw-600 mb-2">Category Experience</p>
              <ul className="account-submenu__list mb-20">
                {data?.user?.genres.map(({ name }) => (
                  <li className="account-submenu__list__item">
                    <img src="/img/lh-logo-sm-2.png" className="mr-2" alt="" />{' '}
                    <a href="/#" className="link-agency">
                      {name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {types.length > 0 && (
            <div className="col-12 col-md-4 col-lg-12">
              <p className="txt__fw-600 mb-2">Considering</p>
              <ul className="account-submenu__list mb-20">
                {types.map((t) => (
                  <li className="account-submenu__list__item">
                    <img src="/img/lh-logo-sm-2.png" className="mr-2" alt="" />{' '}
                    <a href="/#" className="link-agency">
                      {t}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {workHour.length > 0 && (
            <div className="col-12 col-md-4 col-lg-12">
              <p className="txt__fw-600 mb-2">Work Hour</p>
              <ul className="account-submenu__list mb-20">
                {workHour.map((w) => (
                  <li className="account-submenu__list__item">
                    <img src="/img/lh-logo-sm-2.png" className="mr-2" alt="" />{' '}
                    <a href="/#" className="link-agency">
                      {w}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {data?.user?.location &&
            data?.user?.location !== null &&
            data?.user?.location.trim() !== '' &&
            data?.user?.location.search('Select') === -1 && (
              <div className="col-12 col-md-4 col-lg-12">
                <p className="txt__fw-600 mb-2">Job Location</p>
                <ul className="account-submenu__list mb-20">
                  <li className="account-submenu__list__item">
                    <img src="/img/lh-logo-sm-2.png" className="mr-2" alt="" />{' '}
                    <a href="/#" className="link-agency">
                      {data?.user?.location == 'onsite'
                        ? 'On site'
                        : data?.user?.location.charAt(0).toUpperCase() +
                          data?.user?.location.slice(1)}
                    </a>
                  </li>
                </ul>
              </div>
            )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="lh-wrapper">
      <Header isLoggedIn />
      <div className="container">
        {loading && <div>Loading freelancer profile...</div>}
        {data && (
          <>
            <div className="row">
              <div className="col-12 d-flex justify-content-between">
                <h1>{data?.user?.name}</h1>

                <div style={{ textAlign: 'right' }}>
                  <Link to="/agency/dashboard" className="btn back">
                    <i className="fas fa-arrow-left" />
                    Back
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <SideBarLeft />
              <Main />
              <SideBarRight />
            </div>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};
export default FreelancerProfile;
