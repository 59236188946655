import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import DropdownMultiSelect from 'react-multiselect-dropdown-bootstrap';
import Header from '../../components/Freelancer/Header';
import Footer from '../../components/Footer';
import jquery from '../../styles/js/app';
import {
  GET_ALL_OPPORTUNITIES,
  GET_GENRES,
  GET_SKILLS,
  GET_ALL_JOB_LEVELS,
  GET_ALL_JOB_TYPES,
} from '../../graphql';
import {
  arrToDropdownSelection,
  getIdsFromSelection,
} from '../../utils/helpers';

const LatestOpportunities = () => {
  useEffect(() => {
    jquery();
  }, []);

  const { register, handleSubmit } = useForm();
  const [searchData, setSearchData] = useState({
    skills: [],
    genres: [],
    type: [],
    level: [],
  });

  const { data, loading, fetchMore, refetch } = useQuery(
    GET_ALL_OPPORTUNITIES,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        trashed: 'WITHOUT',
        orderBy: [
          { field: 'FEATURED', order: 'DESC' },
          { field: 'CREATED_AT', order: 'DESC' },
          { field: 'TITLE', order: 'ASC' },
        ],
      },
      onCompleted: () => toast.dismiss('loadingOpp'),
      onError: () => refetch(),
    }
  );

  loading &&
    toast.info('Loading opportunities...', {
      toastId: 'loadingOpp',
      autoclose: 2000,
    });

  const { data: { skills = [] } = {}, refetch: refetchSkill } = useQuery(
    GET_SKILLS,
    {
      onError: () => refetchSkill(),
    }
  );
  const { data: { genres = [] } = {}, refetch: refetchGenre } = useQuery(
    GET_GENRES,
    {
      onError: () => refetchGenre(),
    }
  );

  const { data: { allJoblevels = [] } = {}, refetch: refetchJobLevel } =
    useQuery(GET_ALL_JOB_LEVELS, {
      onError: () => refetchJobLevel(),
    });

  const optionsArrSkills = arrToDropdownSelection(skills);
  const optionsArrGenres = arrToDropdownSelection(genres);

  const setIds = (arr, type, original) => {
    setSearchData({
      ...searchData,
      [type]: getIdsFromSelection(original, arr),
    });
  };

  const orObj = (column, field) => {
    const orArr = [];
    searchData[field].map((value) => orArr.push({ column, value }));
    return orArr;
  };

  const searchAndFilter = (data) => {
    const whereParams = !!(
      searchData.type.length > 0 ||
      searchData.level.length > 0 ||
      data.search
    );
    fetchMore({
      variables: {
        ...(whereParams && {
          where: {
            AND: [
              {
                ...(searchData.type.length > 0 && {
                  OR: orObj('TYPE', 'type'),
                }),
              },
              {
                ...(searchData.level.length > 0 && {
                  OR: orObj('LEVEL', 'level'),
                }),
              },
              {
                ...(data.search && {
                  OR: [
                    {
                      column: 'DESCRIPTION',
                      operator: 'LIKE',
                      value: `%${data.search}%`,
                    },
                    {
                      column: 'TITLE',
                      operator: 'LIKE',
                      value: `%${data.search}%`,
                    },
                  ],
                }),
              },
            ],
          },
        }),
        ...(searchData.genres.length > 0 && {
          hasGenres: { OR: orObj('GENRE_ID', 'genres') },
        }),
        ...(searchData.skills.length > 0 && {
          hasSkills: { OR: orObj('SKILL_ID', 'skills') },
        }),
      },
      updateQuery: (prev, newQuery) =>
        newQuery.fetchMoreResult ? newQuery.fetchMoreResult : prev,
    });
  };

  return (
    <div className="lh-wrapper">
      <Header isLoggedIn />
      <div className="container">
        <h1>
          Latest Job Opportunities {data && `(${data.allOpportunities.length})`}
        </h1>
        {data && (
          <div className="row mb-2">
            <div className="col-lg-3 pr-lg-0">
              <div className="form__input-wrapper">
                <input
                  className="form__input"
                  type="text"
                  autoComplete="false"
                  name="search"
                  ref={register({ required: false })}
                  placeholder="Title or Description"
                />
                <div className="form__search">
                  <i className="fas fa-search" />
                </div>
              </div>
            </div>
            <div className="col-lg-2 mb-1 pr-lg-0">
              {optionsArrGenres.length > 0 && (
                <DropdownMultiSelect
                  options={optionsArrGenres}
                  name="genres"
                  placeholder="-- Category Experience --"
                  buttonClass="form__input form__country multi-selection"
                  placeholderMultipleChecked="Category Experience (multiple)"
                  handleOnChange={(selected) =>
                    setIds(selected, 'genres', genres)
                  }
                />
              )}
            </div>
            <div className="col-lg-2 mb-1 pr-lg-0">
              {optionsArrSkills.length > 0 && (
                <DropdownMultiSelect
                  options={optionsArrSkills}
                  name="skills"
                  placeholder="-- Skills --"
                  buttonClass="form__input form__country multi-selection"
                  placeholderMultipleChecked="Skills (multiple)"
                  handleOnChange={(selected) =>
                    setIds(selected, 'skills', skills)
                  }
                />
              )}
            </div>
            <div className="col-lg-2 mb-1 pr-lg-0">
              <DropdownMultiSelect
                options={[
                  { id: 9, label: 'Freelance', key: 'FREELANCE' },
                  { id: 1, label: 'Permanent', key: 'PERMANENT' },
                  { id: 8, label: 'Both', key: 'BOTH' },
                ]}
                name="type"
                placeholder="-- Type --"
                buttonClass="form__input form__country multi-selection"
                placeholderMultipleChecked="Type (multiple)"
                handleOnChange={(selected) =>
                  setSearchData({ ...searchData, type: selected })
                }
              />
            </div>
            <div className="col-lg-2 mb-1">
              {allJoblevels.length > 0 && (
                <DropdownMultiSelect
                  options={allJoblevels.map((l) => ({ ...l, label: l.name }))}
                  name="level"
                  placeholder="-- Level --"
                  buttonClass="form__input form__country multi-selection"
                  placeholderMultipleChecked="Level (multiple)"
                  handleOnChange={(selected) =>
                    setSearchData({ ...searchData, level: selected })
                  }
                />
              )}
            </div>
            <div className="col ml-0 pl-lg-0">
              <button
                className="btn opp-search bg-freelancer"
                onClick={handleSubmit(searchAndFilter)}
              >
                Search
              </button>
            </div>
          </div>
        )}

        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Company</th>
                <th scope="col" />
                <th scope="col">Date posted</th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
              {data &&
                !loading &&
                data.allOpportunities.map(
                  ({ agency: { company, avatar }, title, created_at, id }) => (
                    <tr key={id}>
                      <th scope="row">
                        <img
                          src={
                            avatar || '/img/agency-picture-placeholder@2x.jpg'
                          }
                          alt={company}
                          className="card__small__img-responsive"
                        />
                      </th>
                      <td className="align-middle">
                        <Link
                          to={`/freelancer/opportunities/${id}`}
                          className="text-dark txt__fs-18"
                        >
                          {title}
                        </Link>
                        <br />
                        {company}
                      </td>
                      <td className="align-middle">
                        {moment(created_at).format('DD/MM/YYYY')}
                      </td>
                      <td className="align-middle">
                        <Link
                          to={`/freelancer/opportunities/${id}`}
                          className="link-freelancer"
                        >
                          View Opportunity
                        </Link>
                      </td>
                    </tr>
                  )
                )}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LatestOpportunities;
