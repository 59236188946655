import gql from 'graphql-tag';

export const UPDATE_APPLICANT = gql`
  mutation UpdateApplicant($id: ID!, $input: ApplicantUpdate) {
    updateApplicant(id: $id, input: $input) {
      id
      is_shown
    }
  }
`;
