import React from 'react';

const BlogpostSidebar = ({ title }) => (
  <div className="col-12 col-md-6 col-lg-12">
    <img src="img/cta-placeholder@2x.jpg" className="blog__img" alt="" />
    <a href="/#" className="link-marketing txt__fw-600 mb-40 d-block">
      {title}
    </a>
  </div>
);

export default BlogpostSidebar;
