import React, {useState} from 'react'

export const AppContext = React.createContext()

const Store = ({apolloClient, children}) => {
  const initialState = {
    /** Global App State */
    apollo: apolloClient,
    /** Team Members Logic */
    userTeams: undefined,
    activeCompany: undefined
  }

  const [state, setState] = useState(initialState)

  return (
    <AppContext.Provider value={[state, setState]}>
      {children}
    </AppContext.Provider>
  )
}

export default Store
