import gql from 'graphql-tag';

export const UPDATE_TEAM_PROFILE = gql`
  mutation ($teamId: Int!, $input: UserUpdate) {
    updateTeam(teamId: $teamId, input: $input) {
      id
      bio
      avatar
      company
      email
      address_1
      address_2
      city
      state
      country
      zip
      bio
      description
      phone
      position
      email
      billing_address_1
      billing_address_2
      billing_city
      billing_state
      billing_country
      billing_zip
      referee_1
      referee_2
      privacy
      send_email
      send_text_notification
      notification_frequency
      status
    }
  }
`;
