import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import Cookies from 'universal-cookie';
import { GET_AUTH_USER } from '../graphql';
import { saveUserLocalstorage, logUserOut } from '../utils/helpers';
import HeaderBlog from '../components/Agency/HeaderBlog';
import Header from '../components/Freelancer/Header';
import Footer from '../components/Footer';

const MarketingLogin = ({ history }) => {
  useEffect(() => {}, []);

  const message = 'Loading...';

  function GetQuery() {
    return new URLSearchParams(useLocation().search);
  }

  function ShowHeader(type) {
    switch (type) {
      case 'freelancer':
        return <Header />;

      case 'agency':
      default:
        return <HeaderBlog />;
    }
  }

  const query = GetQuery();
  const token = query.get('token');
  const type = query.get('type');

  const [getUser, { called, loading }] = useLazyQuery(GET_AUTH_USER, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ me }) => {
      if (me) {
        const cookies = new Cookies();
        cookies.set('lh_user', me.id, {
          path: '/',
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
        });
        saveUserLocalstorage({
          ...me,
          type: type.toUpperCase(),
          access_token: token,
        });
        return history.push(`/${type}/dashboard`);
      }
      return history.push(`${type}/login`);
    },
  });

  if (type && token && !called && !loading) {
    logUserOut();
    saveUserLocalstorage({
      type: type.toUpperCase(),
      access_token: token,
    });
    getUser();
  }

  return (
    <div className="lh-wrapper">
      {ShowHeader(type)}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="onboarding">
              <span>{message}</span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MarketingLogin;
