import React, { useEffect, useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify';
import { GET_SUBSCRIPTION_HISTORY, LOGIN } from '../../graphql';
import Error from '../../components/Error';
import Footer from '../../components/Footer';
import jquery from '../../styles/js/app';
import HeaderBlog from '../../components/Agency/HeaderBlog';
import { userVar } from '../../graphql/config';
import { AppContext } from '../../Store';
import {
  getUser,
  saveUserLocalstorage,
  saveLoginTypeLocalstorage,
} from '../../utils/helpers';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Login = ({ location }) => {
  useEffect(() => {
    jquery();
  }, []);
  const history = useHistory();
  const redirectTo = useQuery().get('redirectTo');
  const notify = (text) => toast.error(text);
  const [state, setState] = useContext(AppContext);
  const { register, handleSubmit, errors } = useForm();
  const [loginMutation, { loading }] = useMutation(LOGIN);
  let isEnabled = true;

  useEffect(() => {
    if (state?.apollo) {
      state.apollo.clearStore();
    }
  }, [state]);
  const [, { data }] = useLazyQuery(GET_SUBSCRIPTION_HISTORY, {
    onCompleted: () => history.push(redirectTo ?? '/agency/dashboard'),
    onError: () => history.push(redirectTo ?? '/agency/dashboard'),
  });

  if (!data) {
    if (
      (getUser().access_token && getUser().type === 'AGENCY') ||
      getUser().type === 'GUEST'
    ) {
      history.push(redirectTo ?? '/agency/dashboard');
    }
  }

  function LoginUser(data) {
    setState({});
    toast.dismiss();
    isEnabled = false;
    loginMutation({
      variables: { email: data.email, password: data.password },
    })
      .then((res) => {
        const { data } = res;
        if (!data.login) {
          return notify('Wrong email / password combination!');
        }
        userVar(data.login);
        saveUserLocalstorage(data.login);
        const cookies = new Cookies();
        cookies.set('lh_user', data.login.id, {
          path: '/',
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
        });
        saveLoginTypeLocalstorage('AGENCY');
        history.push(redirectTo ?? '/agency/dashboard');
        // subHistory({
        //   variables: {
        //     agency: data.login.id,
        //   },
        // });
      })
      .catch((e) =>
        toast.error('Something went wrong!', { toastId: 'logErr' })
      );
  }

  return (
    <div className="lh-wrapper">
      <HeaderBlog />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="onboarding">
              <h1 className="center mb-50">
                {location.state && location.state.passwordReset
                  ? 'Your password has been reset. Please log in.'
                  : 'Login'}
              </h1>
              <form className="form" onSubmit={handleSubmit(LoginUser)}>
                <div className="form__heading mt-0">Email</div>
                <div className="form__input-block">
                  <div className="form__input-wrapper">
                    <input
                      className="form__input"
                      type="text"
                      name="email"
                      ref={register({
                        required: true,
                        pattern:
                          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      })}
                      autoComplete="false"
                    />
                    <div className="form__clear form__hidden">
                      <i className="fas fa-times" />
                    </div>
                  </div>
                </div>
                {errors.email && (
                  <Error text="Please enter a valid email address" />
                )}
                <div className="form__input-block">
                  <div className="form__heading mt-0">Password</div>
                  <div className="form__input-wrapper">
                    <input
                      className="form__input"
                      type="password"
                      id="onboarding-password"
                      name="password"
                      ref={register({ required: true })}
                      autoComplete="false"
                    />
                    <div className="form__clear form__hidden">
                      <i className="fas fa-times" />
                    </div>
                    <div
                      toggle="#onboarding-password"
                      className="fas fa-eye form__toggle-password form__hidden"
                    />
                  </div>
                </div>
                {errors.password && <Error text="Please enter a password" />}
                <button
                  type="submit"
                  disabled={!isEnabled}
                  className="form__btn btn d-block mb-50"
                >
                  {loading ? 'Loading...' : 'Login'}
                </button>
                {(!location.state || location.state.referrer === '/') && (
                  <div className="d-flex justify-content-between mb-60">
                    <Link
                      to="/agency/register"
                      className="link-agency txt__fw-600"
                    >
                      Need an account?
                    </Link>
                    <Link
                      to="/agency/forgot-password"
                      className="link-agency txt__fw-600"
                    >
                      Forgotten Password
                    </Link>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
