import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import 'bootstrap/js/src/modal';
import Error from '../Error';
import hideModal from '../../styles/js/hideModal';
import { isValidDocument, getUser } from '../../utils/helpers';

const ConfirmApplyOpportunity = ({
  confirm,
  loading,
  applicationLink,
  history,
}) => {
  const cvRef = useRef(null);
  const docRef = useRef(null);
  const [cv, setCv] = useState({
    url: null,
    error: null,
  });
  const [doc, setDoc] = useState({
    url: null,
    error: null,
  });
  const { watch, register, handleSubmit } = useForm();
  const watchFields = watch(['reason']);

  const onChangeFile = (type, event) => {
    event.stopPropagation();
    event.preventDefault();
    toast.dismiss('invalidDocs');

    const file = event.target.files[0];
    isValidDocument(file, (valid) => {
      if (!valid) {
        return toast.error('Please select a valid document file.', {
          toastId: 'invalidDocs',
        });
      }

      if (type === 'cv') {
        setCv({
          url: valid.url,
          error: false,
          file: valid.file,
        });
      } else if (type === 'doc') {
        setDoc({
          url: valid.url,
          error: false,
          file: valid.file,
        });
      }

      toast.success('Upload success!', { autoClose: 4000 });
    });
  };

  const handleConfirm = (data) => {
    confirm(cv, doc, data.reason);
    hideModal();
  };

  const onChangeReason = (e) => {
    const limit = 400;
    const wordsCount = e.target.value.split(' ').length;

    if (wordsCount - 1 >= limit) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <div
      className="modal fade"
      id="confirm_apply_opportunity"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="applyModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div
            className="modal-header text-center"
            style={{ borderBottom: 'none', paddingLeft: '2rem' }}
          >
            <h5 className="modal-title w-100" id="applyModalLabel">
              Please confirm before you apply
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body text-center">
            <div className="mb-40">
              {getUser()?.isProfileComplete?.missing?.length > 0 && (
                <>
                  Complete your profile before applying to this job. Adding
                  skills helps improve the accuracy of job matches.
                  <br />
                  <br />
                </>
              )}
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-6 mb-3">
                <div style={{ marginBottom: '80px' }}>
                  <p>Do you want to use a different CV?</p>
                </div>
                <div>
                  <input
                    type="file"
                    id="file"
                    ref={cvRef}
                    style={{ display: 'none' }}
                    onChange={(e) => onChangeFile('cv', e)}
                  />
                  <div
                    className="btn form__btn-small mt-0 bg-freelancer"
                    onClick={() => cvRef.current.click()}
                    style={{
                      position: 'absolute',
                      bottom: '0',
                      left: '50%',
                      transform: 'translate(-50%, 0)',
                    }}
                  >
                    Upload your CV
                  </div>
                  <div className="mt-2">&nbsp;</div>
                  <div
                    style={{
                      position: 'absolute',
                      left: '50%',
                      transform: 'translate(-50%)',
                      top: '40%',
                      width: '100%',
                    }}
                  >
                    {cv.file && (
                      <strong style={{ color: '#4BB543', fontSize: '11px' }}>
                        CV Uploaded! - {cv.file.name}
                      </strong>
                    )}
                    {cv.error && (
                      <Error text="Please select a valid document file" />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 mb-3">
                <div style={{ marginBottom: '80px' }}>
                  <p>
                    Additional material that would support your application
                    (optional)
                  </p>
                </div>
                <div>
                  <input
                    type="file"
                    id="file"
                    ref={docRef}
                    style={{ display: 'none' }}
                    onChange={(e) => onChangeFile('doc', e)}
                  />
                  <div
                    className="btn form__btn-small mt-0 bg-freelancer"
                    onClick={() => docRef.current.click()}
                    style={{
                      position: 'absolute',
                      bottom: '0',
                      left: '50%',
                      transform: 'translate(-50%, 0)',
                    }}
                  >
                    Upload document
                  </div>
                  <div className="mt-2">&nbsp;</div>
                  <div
                    style={{
                      position: 'absolute',
                      left: '50%',
                      transform: 'translate(-50%)',
                      top: '40%',
                      width: '100%',
                    }}
                  >
                    {doc.file && (
                      <strong style={{ color: '#4BB543', fontSize: '11px' }}>
                        File Uploaded! - {doc.file.name}
                      </strong>
                    )}
                    {doc.error && (
                      <Error text="Please select a valid document file" />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col">
                <textarea
                  autoFocus
                  rows="4"
                  name="reason"
                  id="reason"
                  placeholder="What is your elevator pitch? Send a message straight to the recruiter to let them know what skills and experience you have that make you perfect for this role."
                  className="form__textarea__border-light height__unset reason-input"
                  onKeyPress={onChangeReason}
                  onPaste={onChangeReason}
                  ref={register({ required: false })}
                />
                <div className="form__textarea__character-limit">
                  {watchFields && (
                    <>
                      <span id="chars">
                        {'reason' in watchFields
                          ? watchFields.reason
                            ? watchFields.reason.split(' ').length - 1
                            : 0
                          : 0}
                      </span>{' '}
                      / 400 words
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-footer"
            style={{
              justifyContent: 'center',
              borderTop: 'none',
            }}
          >
            <a
              href="/freelancer/manage-cv"
              className="btn form__btn-small mt-0 bg-freelancer-outline"
            >
              Edit Profile
            </a>
            <button
              type="button"
              className="btn form__btn-small mt-0 bg-freelancer"
              onClick={handleSubmit(handleConfirm)}
            >
              {applicationLink ? 'Complete Application Form' : 'Connect Now'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmApplyOpportunity;
