import gql from 'graphql-tag';

export const CHECK_PROMO = gql`
  mutation CheckPromo($code: String!) {
    checkPromo(code: $code) {
      name
      code
      description
      type
      value
      created_at
    }
  }
`;
