import React from 'react';
import 'bootstrap/js/src/modal';
import { Link } from 'react-router-dom';
import hideModal from '../../styles/js/hideModal';

const ConfirmNewOpportunity = ({ confirm, balance, loading }) => {
  const handleConfirm = () => {
    confirm();
    hideModal();
  };

  return (
    <div
      className="modal fade"
      id="confirm_new_opportunity"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div
            className="modal-header"
            style={{
              borderBottom: 0,
            }}
          >
            {balance > 0 && (
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            )}
          </div>
          <div
            className="modal-body"
            style={{
              textAlign: 'center',
            }}
          >
            {loading ? (
              'Loading Job Posts balance...'
            ) : (
              <>
                <div style={{ fontSize: '20px' }} className="mb-10">
                  This new opportunity will cost 1 job post
                </div>
                <div style={{ fontSize: '16px' }}>
                  Your current balance is&nbsp;
                  <b>
                    {balance}
                    &nbsp;job post
                    {balance > 1 || balance === 0 ? 's' : ''}
                  </b>
                </div>
              </>
            )}
          </div>
          <div
            className="modal-footer"
            style={{
              justifyContent: 'center',
              borderTop: 0,
              paddingBottom: '35px',
            }}
          >
            <Link
              to="/agency/manage-credits"
              className="btn link-agency pr-5"
              onClick={hideModal}
            >
              Buy More Job Posts
            </Link>
            {balance > 0 && (
              <button
                type="button"
                className="btn form__btn-small mt-0"
                onClick={handleConfirm}
              >
                Continue
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmNewOpportunity;
