import React from 'react';
import { Link } from 'react-router-dom';

const CTA = ({ content }) => (
  <div className="row mb-100">
    {content
      && content.map(({ id, title, image }) => (
        <div className="col-12 col-lg-6" key={id}>
          <Link to="/#">
            <div className="resources__card">
              <div className="resources__card__title">{title}</div>
              <img
                src={image || '/img/resources-thumbnail.png'}
                className="img img__training__thumbnail cta-hover"
                alt={title}
              />
            </div>
          </Link>
        </div>
      ))}
  </div>
);

export default CTA;
