import React from 'react';

const Header = () => (
  <>
    <div className="mobile-menu">
      <nav className="mobile-menu__inner">
        <ul className="mobile-menu__list">
          <li className="mobile-menu__list__item-large">
            <a href="/#" className="">
              For Agencies
            </a>
          </li>
          <li className="mobile-menu__list__item-large">
            <a href="/#" className="">
              For Freelancers
            </a>
          </li>
          <li className="mobile-menu__list__item-large">
            <a href="/#" className="">
              Sign In
            </a>
          </li>
          <li className="mobile-menu__list__item-large">
            <a href="/#" className="">
              Create An Account
            </a>
          </li>
        </ul>
        <ul className="mobile-menu__list">
          <li className="mobile-menu__list__item-small">
            <a
              href={`${process.env.REACT_APP_API_URL}#how-it-works`}
              className=""
            >
              How It Works
            </a>
          </li>
          <li className="mobile-menu__list__item-small">
            <a href="/#" className="">
              Get Advice
            </a>
          </li>
          <li className="mobile-menu__list__item-small">
            <a href="/#" className="">
              Resources
            </a>
          </li>
        </ul>
        <div className="mobile-menu__inner__social">
          <a href="/#" target="_self" className="footer__links__img">
            <img
              src="/img/lh-header-logo.png"
              className="img img__social"
              alt=""
            />
          </a>
          <a
            href="https://www.instagram.com/libertyhive/"
            target="_blank"
            rel="noreferrer noopener"
            className="footer__links__img"
          >
            <img
              src="/img/social-icon-instagram@2x.png"
              className="img img__social"
              alt=""
            />
          </a>
          <a
            href="https://www.linkedin.com/company/libertyhive/"
            target="_blank"
            rel="noreferrer noopener"
            className="footer__links__img"
          >
            <img
              src="/img/social-icon-linkedin@2x.png"
              className="img img__social"
              alt=""
            />
          </a>
        </div>
      </nav>
    </div>
    <div className="header-marketing">
      <div className="container">
        <nav className="header-marketing__inner__menu">
          <ul className="header-marketing__list">
            <li className="header-marketing__list__item desktop">
              <a href="/#" className="header__link txt__fw-600">
                For Agencies
              </a>
            </li>
            <li className="header-marketing__list__item desktop">
              <a href="/#" className="header__link txt__fw-600">
                For Freelancers
              </a>
            </li>
            <li className="header-marketing__list__item">
              <div className="header-marketing__inner__logo">
                <a href="/#">
                  <img
                    src="/img/lh-header-logo.png"
                    className="img__logo__mobile"
                    alt=""
                  />
                </a>
                <a href="/#">
                  <img
                    src="/img/lh-header-logo.png"
                    className="img__logo__desktop"
                    alt=""
                  />
                </a>
              </div>
            </li>
            <li className="header-marketing__list__item desktop">
              <a href="/#" className="header__link txt__fw-600">
                Sign In
              </a>
            </li>
            <li className="header-marketing__list__item desktop mr-0">
              <a href="/#" className="header__link txt__fw-600">
                Create An Account
              </a>
            </li>
            <li className="header-marketing__list__item mobile-tablet">
              <div className="header-marketing__inner__menu">
                <div id="hamburger">
                  <span />
                  <span />
                  <span />
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </>
);

export default Header;
