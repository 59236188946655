import gql from 'graphql-tag';

export const GET_AGENCY_APPLICANTS = gql`
  query Applicants($agency: Int!, $opportunity: Int) {
    applicants(agency: $agency, opportunity: $opportunity) {
      id
      user {
        id
        name
        email
        phone
        position
      }
      opportunity(trashed: WITH) {
        id
        title
        deleted_at
      }
      created_at
    }
  }
`;

export const GET_FREELANCER_APPLICATIONS = gql`
  query Applicants {
    me {
      applications {
        id
        opportunity(trashed: WITH) {
          id
          title
          agency {
            id
            company
            avatar
          }
          deleted_at
        }
        created_at
        is_shown
      }
    }
  }
`;

export const GET_APPLICANT = gql`
  query Applicant($id: ID!) {
    applicant(id: $id) {
      id
      user {
        id
        name
        email
        phone
      }
      opportunity(trashed: WITH) {
        id
        title
      }
      resume
      resumeUrl
      document
      documentUrl
      reason
      created_at
    }
  }
`;
