import React from 'react';
import { Link } from 'react-router-dom';

const AccountSubmenuDesktop = () => (
  <div className="col-12 col-lg-3">
    <div className="account-submenu__desktop bg-light-freelancer">
      <div className="account-submenu__desktop__heading txt-black">
        Account Settings
      </div>
      <ul className="account-submenu__list">
        <li className="account-submenu__list__item">
          <Link
            to="/freelancer/personal-information"
            className="link-freelancer"
          >
            Personal Information
          </Link>
        </li>
        <li className="account-submenu__list__item">
          <Link
            to="/freelancer/edit-personal-information"
            className="link-freelancer"
          >
            Edit Personal Information
          </Link>
        </li>
        <li className="account-submenu__list__item">
          <Link to="/freelancer/profile" className="link-freelancer">
            View Profile
          </Link>
        </li>
        <li className="account-submenu__list__item">
          <Link to="/freelancer/manage-cv" className="link-freelancer">
            Edit profile
          </Link>
        </li>
        <li className="account-submenu__list__item">
          <Link
            to="/freelancer/notification-settings"
            className="link-freelancer"
          >
            Notification Settings
          </Link>
        </li>
        <li className="account-submenu__list__item">
          <Link to="/freelancer/profile-privacy" className="link-freelancer">
            Profile Privacy
          </Link>
        </li>
        <li className="account-submenu__list__item">
          <Link to="/freelancer/change-password" className="link-freelancer">
            Change Password
          </Link>
        </li>
        <li className="account-submenu__list__item">
          <Link to="/freelancer/logout" className="link-freelancer">
            Log Out
          </Link>
        </li>
      </ul>
    </div>
  </div>
);

export default AccountSubmenuDesktop;
