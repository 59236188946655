import axios from 'axios';
import formData from 'form-data';

const apiUrl = `${process.env.REACT_APP_API_URL}/api`;

export const registerAgency = async (userData) => {
  try {
    const form = new formData();
    for (const key in userData) {
      form.append(key, userData[key]);
    }
    const { data } = await axios.post(`${apiUrl}/register/agency`, form, {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
      },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const login = async (userData) => {
  try {
    const { data } = await axios.post(`${apiUrl}/login`, userData);
    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const forgotPassword = async (email) => {
  try {
    const { data } = await axios.post(`${apiUrl}/forgot-password`, email);
    return data;
  } catch (error) {
    return error.response;
  }
};

export const resetPassword = async (details) => {
  try {
    const { data } = await axios.post(`${apiUrl}/reset-password`, details);
    return data;
  } catch (error) {
    return error.response;
  }
};

export const logout = async () => {
  try {
    await axios.get('');
    return;
  } catch (error) {
    return error.response.data;
  }
};
