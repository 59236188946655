import React, { useEffect, useState, useContext } from 'react';
import Collapse from '@kunukn/react-collapse';
import currencyToSymbolMap from 'currency-symbol-map/map';
import { useForm } from 'react-hook-form';
import Switch from 'react-switch';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useLazyQuery } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { Multiselect } from 'multiselect-react-dropdown';
import RangeSlider from './../../../components/Agency/RangeSlider';
import Error from '../../../components/Error';
import Header from '../../../components/Agency/Header';
import Footer from '../../../components/Footer';
import jquery from '../../../styles/js/app';
import { AppContext } from '../../../Store';
import {
  ADD_OPPORTUNITY,
  GET_GENRES,
  GET_SKILLS,
  GET_CURRENT_CREDITS,
  GET_ALL_JOB_LEVELS,
  GET_TEAM_USERS,
  // GET_SUBSCRIPTION_HISTORY,
} from '../../../graphql';
import { arrToDropdownSelection } from '../../../utils/helpers';
import ConfirmNewOpportunity from '../../../components/Agency/ConfirmNewOpportunityModal';
import { openAddOpportunityModal } from '../../../styles/js/hideModal';

const availableCurrencies = ['GBP', 'EUR', 'USD'];
const currencies = Object.entries(
  Object.keys(currencyToSymbolMap)
    .filter((key) => availableCurrencies.includes(key))
    .reduce((obj, key) => {
      obj[key] = currencyToSymbolMap[key];
      return obj;
    }, {})
);

const NewOpportunity = ({ history }) => {
  const [state] = useContext(AppContext);
  const [followUp, setFollowUp] = useState('');
  useEffect(() => {
    jquery();
  }, []);

  useEffect(() => {
    if (state?.activeCompany) {
      getTeamUsers();
      setFollowUp(
        `Thank you for your interest in our company ${state.activeCompany.user.company}. Now we've got your application, we will review your details and contact you shortly if we'd like to meet you for an interview. If you have any questions, please email contact@libertyhive.com`
      );
    }
  }, [state?.activeCompany]);

  /** Retrieve Team Members */
  const [getTeamUsers, { data: teamList, loading: teamListLoading }] =
    useLazyQuery(GET_TEAM_USERS, {
      variables: {
        team_id:
          state?.activeCompany?.role === 'admin'
            ? Number(state?.activeCompany?.team_id)
            : Number(state?.activeCompany?.user_id),
      },
      fetchPolicy: 'network-only',
    });

  const [opportunity, setOpportunity] = useState({
    genres: { sync: [] },
    is_on_site: true,
    is_remote: false,
    skills: { sync: [] },
    is_public: true,
    is_active: true,
    is_compensation_displayed: true,
    type: '',
    level: '',
    compensation_unit: 'DAY',
  });
  const [jobLevelHelpText, setJobLevelHelpText] = useState('');
  const [jobLevelIsOpen, setJobLevelIsOpen] = useState(false);
  const [jobBudget, setJobBudget] = useState([]);
  const [jobRate, setJobRate] = useState('Day Rate');
  const [assignedUser, setAssignedUser] = useState(undefined);

  // const [subscriptionHistory, setSubscriptionHistory] = useState([]);
  // useQuery(GET_SUBSCRIPTION_HISTORY, {
  //   variables: {
  //     orderBy: {
  //       field: { field: 'CREATED_AT', order: 'DESC' },
  //     },
  //     agency: getUser().id,
  //   },
  //   onCompleted: ({ subscriptionHistory }) => {
  //     setSubscriptionHistory(subscriptionHistory);
  //   },
  // });

  const { data: { skills = [] } = {}, refetch: refetchSkill } = useQuery(
    GET_SKILLS,
    {
      variables: { name: '' },
      onError: () => refetchSkill(),
    }
  );

  const { data: { genres = [] } = {}, refetch: refetchGenre } = useQuery(
    GET_GENRES,
    {
      variables: { name: '' },
      onError: () => refetchGenre(),
    }
  );

  const allJobtypes = [
    {
      key: 'PERMANENT',
      name: 'Permanent',
      description: 'Full Time or Part Time',
    },
    {
      key: 'FREELANCE',
      name: 'Freelance',
      description: 'Less than six months',
    },
    {
      key: 'BOTH',
      name: 'Both',
    },
  ];

  const allSubJobtypes = [
    {
      key: 'FULLTIME',
      name: 'Full-time',
    },
    {
      key: 'PARTTIME',
      name: 'Part-time',
    },
    {
      key: 'BOTH_TIME',
      name: 'Both',
    },
  ];

  const { data: { allJoblevels = [] } = {}, refetch: refetchJobLevel } =
    useQuery(GET_ALL_JOB_LEVELS, {
      onError: () => refetchJobLevel(),
    });

  const optionsArrSkills = arrToDropdownSelection(skills);
  const optionsArrGenres = arrToDropdownSelection(genres);

  const [balance, setBalance] = useState(0);
  const { loading: loadingCredit, refetch: refetchCredit } = useQuery(
    GET_CURRENT_CREDITS,
    {
      variables: { team_id: state?.activeCompany?.team_id },
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        // if (subscriptionHistory.length === 0) {
        //   history.push('/agency/subscription');
        // } else {
        setBalance(data.currentCredit.balance);

        openAddOpportunityModal();
        // }
      },
      onError: () => refetchCredit(),
    }
  );

  const { is_on_site, is_remote, is_public, type, level, compensation_unit } =
    opportunity;

  const { register, watch, handleSubmit, errors, reset, getValues, setError } =
    useForm();
  const watchFields = watch(['description']);
  const followUpWatchFields = watch(['follow_up']);

  const [createOpportunity, { loading, error }] = useMutation(ADD_OPPORTUNITY, {
    onCompleted: () => toast.dismiss('loadingAddOpp'),
  });

  if (error) {
    toast.dismiss('loadingAddOpp');
    toast.error('Something went wrong.', { toastId: 'OppCustom' });
    console.log(error);
  }

  loading && toast.info('Adding opportunity...', { toastId: 'loadingAddOpp' });

  const handleChecked = (checked) => {
    setOpportunity({ ...opportunity, is_active: checked });
  };

  const handleBudgetNotDisplayed = (e) => {
    setOpportunity({
      ...opportunity,
      is_compensation_displayed: e.target.checked,
    });
  };

  const handleIR35 = (val) => {
    setOpportunity({
      ...opportunity,
      is_ir35: val,
    });
  };

  const updateLocation = (location) => {
    if (location === 'both') {
      return setOpportunity({
        ...opportunity,
        is_on_site: true,
        is_remote: true,
      });
    }
    if (location === 'is_on_site') {
      return setOpportunity({
        ...opportunity,
        is_on_site: true,
        is_remote: false,
      });
    }
    return setOpportunity({
      ...opportunity,
      is_on_site: false,
      is_remote: true,
    });
  };

  const updateField = (key, value) =>
    setOpportunity({ ...opportunity, [key]: value });

  const openModal = (data) => {
    if (data !== undefined) {
      if (!('type' in opportunity) || opportunity.type === '') {
        toast.error('Job Type must be filled!', { toastId: 'appErr' });
        return;
      }

      if (!('sub_type' in opportunity) || opportunity.sub_type === '') {
        toast.error('Working Hour must be filled!', { toastId: 'appErr' });
        return;
      }

      if (!('level' in opportunity) || opportunity.level === '') {
        toast.error('Experience level must be filled!', {
          toastId: 'appErr',
        });
        return;
      }

      if (jobBudget.length === 0) {
        toast.error('Compensation must be filled!', { toastId: 'appErr' });
        return;
      }

      openAddOpportunityModal();
    }
  };

  const submitOpportunity = (data) =>
    createOpportunity({
      variables: {
        input: {
          ...data,
          minimumBudget: jobBudget[0],
          maximumBudget: jobBudget[1],
          agency: {
            connect: state?.activeCompany?.team_id,
          },
          created_by:
            assignedUser && assignedUser !== -1
              ? assignedUser
              : state?.activeCompany?.user_id,
          updated_by:
            assignedUser && assignedUser !== -1
              ? assignedUser
              : state?.activeCompany?.user_id,
          ...opportunity,
        },
      },
    }).then((data) => {
      reset({
        title: '',
        description: '',
        compensation: '',
        follow_up: '',
      });
      setOpportunity({
        ...opportunity,
        skills: { sync: [] },
        genres: { sync: [] },
      });
      toast.success('Opportunity added successfully', { autoClose: 5000 });
      history.push('/agency/dashboard');
    });

  const validateCurrency = (value) => {
    if (getValues('compensation') !== '') {
      if (value === '') return false;
    }
    return true;
  };

  const style = {
    chips: {
      background: '#237B9F',
    },
    searchBox: {
      border: 'none',
      borderBottom: '1px solid #ccc',
      borderRadius: '0px',
    },
    multiselectContainer: {
      color: '#237B9F',
    },
  };

  return (
    <div className="lh-wrapper">
      <Header isLoggedIn />
      <ConfirmNewOpportunity
        confirm={handleSubmit(submitOpportunity)}
        balance={balance}
        loading={loadingCredit}
      />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Post New Job Opportunity</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-4">
            <p>
              Fill out the details of the role which will be shown to our
              network of candidates
            </p>
          </div>
          <div className="col-12 col-lg-5">
            <div className="add-opportunity-row row">
              <div className="col-12 add-opportunity-row__title font-weight-bold">
                Job Title
              </div>
              <div className="col-12 profile-bio-row__content">
                <div className="form__input-block">
                  <div className="form__input-wrapper">
                    <input
                      className="form__input"
                      type="text"
                      name="title"
                      ref={register({ required: true })}
                    />
                  </div>
                </div>
                {errors.title && <Error text="Please enter a title" />}
              </div>
            </div>
            <div className="add-opportunity-row row">
              <div className="col-12 add-opportunity-row__title font-weight-bold">
                Date posted
              </div>
              <div className="col-12 profile-bio-row__content">
                <div className="form__input-block">
                  <div className="form__input-wrapper">
                    <input
                      className="form__input"
                      type="text"
                      name="job_number"
                      defaultValue={moment().format('DD/MM/YYYY')}
                      ref={register({ required: false })}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="add-opportunity-row row">
              <div className="col-12 add-opportunity-row__title font-weight-bold">
                Category Experience
              </div>
              <div className="col-12 profile-bio-row__content">
                <div className="form__input-block">
                  <div className="form__input-wrapper">
                    {optionsArrGenres.length > 0 && (
                      <Multiselect
                        options={optionsArrGenres}
                        onSelect={(selectedList, selectedItem) => {
                          setOpportunity({
                            ...opportunity,
                            genres: { sync: selectedList.map((g) => g.key) },
                          });
                        }}
                        onRemove={(selectedList, removedItem) => {
                          setOpportunity({
                            ...opportunity,
                            genres: { sync: selectedList.map((g) => g.key) },
                          });
                        }}
                        displayValue="label"
                        closeOnSelect={false}
                        avoidHighlightFirstOption
                        placeholder="Select Category Experience"
                        style={style}
                      />
                    )}
                  </div>
                </div>
                {errors.genres && (
                  <Error text="You have entered an invalid category experience. Please select one from the list and press enter." />
                )}
              </div>
            </div>
            <div className="add-opportunity-row row">
              <div className="col-12 add-opportunity-row__title font-weight-bold">
                Job Location
              </div>
              <div className="col-12 col-lg-12 add-opportunity-row__content">
                <div className="opportunity-location">
                  <span
                    className={`opportunity-location__card ${
                      is_on_site && !is_remote && 'active'
                    }`}
                    onClick={() => updateLocation('is_on_site')}
                  >
                    On site
                  </span>
                  <span
                    className={`opportunity-location__card ${
                      !is_on_site && is_remote && 'active'
                    }`}
                    onClick={() => updateLocation('is_remote')}
                  >
                    Remote
                  </span>
                  <span
                    className={`opportunity-location__card ${
                      is_on_site && is_remote && 'active'
                    }`}
                    onClick={() => updateLocation('both')}
                  >
                    Hybrid
                  </span>
                </div>
              </div>
            </div>

            <div className="add-opportunity-row row">
              <div className="col-12 add-opportunity-row__title font-weight-bold">
                Job Description
              </div>
              <div className="col-12 col-lg-12 add-opportunity-row__content">
                <textarea
                  rows="5"
                  className="form__textarea__border-light height__unset"
                  name="description"
                  ref={register({ required: true })}
                />
                <div className="form__textarea__character-limit">
                  {watchFields && (
                    <>
                      <span id="chars">
                        {'description' in watchFields
                          ? watchFields.description
                            ? watchFields.description.length
                            : 0
                          : 0}
                      </span>{' '}
                      character
                      {'description' in watchFields
                        ? watchFields.description
                          ? watchFields.description.length > 1
                            ? 's'
                            : ''
                          : ''
                        : ''}
                    </>
                  )}
                </div>{' '}
                {errors.description && (
                  <Error noMargin text="Please enter a description" />
                )}
              </div>
            </div>

            <div className="add-opportunity-row row">
              <div className="col-12 add-opportunity-row__title font-weight-bold">
                Job Type
              </div>
              <div className="col-12 col-lg-12 add-opportunity-row__content">
                <div className="job-type">
                  {allJobtypes.length > 0 &&
                    allJobtypes.map((t) => (
                      <span
                        className={`job-type__card ${
                          type === t.key && 'active'
                        }`}
                        onClick={() => {
                          if (type !== t.key) {
                            updateField('type', t.key);
                          } else {
                            updateField('type', null);
                          }
                        }}
                      >
                        {t.name}
                      </span>
                    ))}
                </div>
                {errors.type && (
                  <span className="form__error" style={{ marginTop: '4px' }}>
                    Please pick a job type
                  </span>
                )}
              </div>
            </div>

            <div className="add-opportunity-row row">
              <div className="col-12 add-opportunity-row__title font-weight-bold">
                Working Hours
              </div>
              <div className="col-12 col-lg-12 add-opportunity-row__content">
                <div className="col-12 work-hour mt-3">
                  {allSubJobtypes.map((sjt) => (
                    <div>
                      <input
                        name="sub_type"
                        id={sjt.key}
                        type="radio"
                        value={sjt.key}
                        onClick={() => {
                          updateField('sub_type', sjt.key);
                        }}
                      />
                      <label htmlFor={sjt.key}>{sjt.name}</label>
                    </div>
                  ))}
                </div>
                {errors.sub_type && (
                  <span className="form__error" style={{ marginTop: '4px' }}>
                    Please pick a working hour
                  </span>
                )}
              </div>
              {opportunity.type === 'FREELANCE' && (
                <>
                  <div className="col-12 mt-4">
                    <label htmlFor="duration">What is the duration?</label>
                  </div>
                  <div className="col-12 add-opportunity-row__content">
                    <div className="form__input-block">
                      <div className="form__input-wrapper">
                        <select
                          name="duration"
                          className="form__input form__country"
                          ref={register({ required: false })}
                        >
                          <option disabled selected>
                            -- Select duration --
                          </option>
                          {[1, 2, 3, 4, 5, 6].map((val) => (
                            <option value={val} key={val}>
                              {val} month{val === 1 ? '' : 's'}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-2">
                    <label htmlFor="is_ir35">Is this role inside IR35?</label>
                  </div>
                  <div className="col-12">
                    <div className="form__input-wrapper">
                      <input
                        name="is_ir35"
                        id="toggle1"
                        type="radio"
                        value="1"
                        onClick={() => handleIR35(1)}
                      />
                      <label htmlFor="toggle1">Yes</label>
                      <br />

                      <input
                        name="is_ir35"
                        id="toggle2"
                        type="radio"
                        value="0"
                        onClick={() => handleIR35(0)}
                      />
                      <label htmlFor="toggle2">No</label>
                      <br />

                      <input
                        name="is_ir35"
                        id="toggle3"
                        type="radio"
                        value="2"
                        onClick={() => handleIR35(2)}
                      />
                      <label htmlFor="toggle3">Not sure</label>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="add-opportunity-row row">
              <div className="col-12 add-opportunity-row__title font-weight-bold">
                Experience Level
              </div>
              <div className="col-12 col-lg-12 add-opportunity-row__content">
                <div className="opportunity-type">
                  {allJoblevels.length > 0 &&
                    allJoblevels.map((l) => (
                      <span
                        className={`opportunity-type__card ${
                          level === l.key && 'active'
                        }`}
                        onClick={() => {
                          setJobLevelIsOpen(true);
                          setJobLevelHelpText(l.description);
                          updateField('level', l.key);
                        }}
                      >
                        {l.name}
                      </span>
                    ))}
                </div>
                <Collapse
                  isOpen={jobLevelIsOpen}
                  aria-hidden={jobLevelIsOpen ? 'false' : 'true'}
                  className="collapse-css-transition app__collapse"
                >
                  <div
                    className="mt-2"
                    dangerouslySetInnerHTML={{ __html: jobLevelHelpText }}
                  />
                </Collapse>
                {errors.level && (
                  <span className="form__error" style={{ marginTop: '4px' }}>
                    Please pick an experience level
                  </span>
                )}
              </div>
            </div>
            <div className="add-opportunity-row row">
              <div className="col-12 add-opportunity-row__title font-weight-bold">
                Skills Required
              </div>
              <div className="col-12 profile-bio-row__content">
                <div className="form__input-block">
                  <div className="form__input-wrapper">
                    {optionsArrSkills.length > 0 && (
                      <Multiselect
                        options={optionsArrSkills}
                        onSelect={(selectedList, selectedItem) => {
                          setOpportunity({
                            ...opportunity,
                            skills: { sync: selectedList.map((s) => s.key) },
                          });
                        }}
                        onRemove={(selectedList, removedItem) => {
                          setOpportunity({
                            ...opportunity,
                            skills: { sync: selectedList.map((s) => s.key) },
                          });
                        }}
                        displayValue="label"
                        closeOnSelect={false}
                        avoidHighlightFirstOption
                        placeholder="Select Skills"
                        style={style}
                      />
                    )}
                  </div>
                </div>
                {errors.skills && (
                  <Error text="You have entered an invalid skill. Please select one from the list and press enter." />
                )}
              </div>
            </div>
            <div className="add-opportunity-row row">
              <div
                className="col-12 add-opportunity-row__title font-weight-bold"
                style={{
                  marginBottom: 20,
                }}
              >
                Compensation/Budget
              </div>
              <div className="col-12" style={{ marginBottom: 20 }}>
                <input
                  id="do-not-display"
                  type="checkbox"
                  defaultChecked={true}
                  style={{ marginRight: '10px' }}
                  onChange={handleBudgetNotDisplayed}
                />
                <label htmlFor="do-not-display">
                  Show compensation on the job offer
                </label>
              </div>
              <div className="col-md-3 add-opportunity-row__content">
                <div className="form__input-block">
                  <div className="form__input-wrapper">
                    <select
                      name="compensation_currency"
                      defaultValue="GBP"
                      className="form__input form__country"
                      ref={register({
                        validate: (value) => validateCurrency(value),
                      })}
                    >
                      {currencies.map(([code, symbol]) => (
                        <option value={code} key={code}>
                          {symbol}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {errors.compensation_currency && (
                  <Error text="Please select a currency" />
                )}
              </div>
              <div
                className="col-md-9 add-opportunity-row__content"
                style={{
                  justifyContent: 'center',
                  alignSelf: 'flex-start',
                }}
              >
                <div className="form__input-block">
                  <div className="form__input-wrapper">
                    {/* <input
                      className="form__input"
                      type="text"
                      name="compensation"
                      placeholder="Amount"
                      ref={register({
                        required: false,
                        pattern: /^[+-]?((\d+(\.\d+)?)|(\.\d+))$/,
                      })}
                    /> */}

                    <RangeSlider
                      onUpdateCallback={(args) => setJobBudget(args)}
                      jobRate={jobRate}
                    />
                  </div>
                </div>
                {errors.compensation && (
                  <Error text="Please enter a valid number" />
                )}
              </div>
              <div className="col-lg-12 add-opportunity-row__content">
                <div className="compensation">
                  <span
                    className={`compensation__card ${
                      jobRate === 'Day Rate' && 'active'
                    }`}
                    onClick={() => {
                      updateField('compensation_unit', 'DAY');
                      setJobRate('Day Rate');
                    }}
                  >
                    Day Rate
                  </span>

                  <span
                    className={`compensation__card ${
                      jobRate === 'Year Rate' && 'active'
                    }`}
                    onClick={() => {
                      updateField('compensation_unit', 'YEAR');
                      setJobRate('Year Rate');
                    }}
                  >
                    Annual Salary
                  </span>
                </div>
              </div>
            </div>

            <div className="add-opportunity-row row">
              <div className="col-12 add-opportunity-row__title font-weight-bold">
                Follow Up Message
              </div>
              <div className="col-12">
                Add a message to your applicants to let them know you have
                received their application and/or add more information about the
                recruitment process.
              </div>
              <div className="col-12 col-lg-12 add-opportunity-row__content">
                <textarea
                  rows="5"
                  className="form__textarea__border-light height__unset"
                  name="follow_up"
                  defaultValue={followUp}
                  ref={register({ required: true })}
                />
                <div className="form__textarea__character-limit">
                  {followUpWatchFields && (
                    <>
                      <span id="chars">
                        {'follow_up' in followUpWatchFields
                          ? followUpWatchFields.follow_up
                            ? followUpWatchFields.follow_up.length
                            : 0
                          : 0}
                      </span>{' '}
                      character
                      {'follow_up' in followUpWatchFields
                        ? followUpWatchFields.follow_up
                          ? followUpWatchFields.follow_up.length > 1
                            ? 's'
                            : ''
                          : ''
                        : ''}
                    </>
                  )}
                </div>{' '}
                {errors.follow_up && (
                  <Error noMargin text="Please enter a follow up message" />
                )}
              </div>
            </div>

            <div className="add-opportunity-row row">
              <div className="col-12 add-opportunity-row__title font-weight-bold">
                If you would like to link this application to your own ATS,
                please supply a link below
              </div>
              <div className="col-12 profile-bio-row__content">
                <div className="form__input-block">
                  <div className="form__input-wrapper">
                    <input
                      className="form__input"
                      type="text"
                      name="application_link"
                      ref={register({ required: false })}
                    />
                  </div>
                </div>
              </div>
            </div>

            {state?.activeCompany?.role === 'admin' && (
              <div
                style={{
                  marginTop: '10px',
                  marginBottom: '50px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <span
                  style={{
                    fontWeight: '700',
                    fontSize: '16px',
                    color: 'black',
                    marginTop: '14px',
                  }}
                >
                  Post job on behalf of:
                </span>
                <span>
                  <select
                    name="posting_user"
                    className="form__input form__country"
                    onChange={(e) => {
                      setAssignedUser(e?.target?.value);
                    }}
                  >
                    <option value={-1}>-- Select User --</option>
                    {teamList &&
                      teamList?.viewUserTeam
                        .filter(
                          (a) => a.team_id !== a.user_id && a.team !== null
                        )
                        .map(
                          ({
                            team: { first_name, last_name },
                            id,
                            user_id,
                          }) => (
                            <option value={user_id} key={id}>
                              {first_name + ' ' + last_name}
                            </option>
                          )
                        )}
                  </select>
                </span>
              </div>
            )}

            <div className="text-center mt-4">
              <button
                className="btn publish-center mb-50 font-weight-bold"
                onClick={handleSubmit(openModal)}
              >
                {loading ? 'Loading...' : 'SAVE'}
              </button>
            </div>
          </div>
          <div className="col-12 col-lg-3">
            <div className="add-opportunity-row">
              <div className="add-opportunity-row__title font-weight-bold">
                Status
              </div>
            </div>
            <div className="published">
              <span className="mr-5">
                {opportunity.is_active ? 'Published' : 'Not Published'}
              </span>
              <Switch
                uncheckedIcon={false}
                checkedIcon={false}
                onColor="#237b9f"
                onChange={handleChecked}
                checked={opportunity.is_active}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NewOpportunity;
