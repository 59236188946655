import React, { useState, useEffect, useRef } from 'react';
import ReactSlider from 'react-slider';
import Constants from '../../../constants';
import './styles.css';

const RangeSlider = ({
  onUpdateCallback,
  disabled = false,
  editMode,
  jobBudget,
  jobRate,
  freelance,
  ...props
}) => {
  const slide = useRef();
  const [startRange, setStartRange] = useState([0, 1000]);
  const [selectedRange, setSelectedRange] = useState([0, 1000]);
  const [increment, setIncrement] = useState(100);

  useEffect(() => {
    switch (jobRate) {
      case 'Day Rate':
        setStartRange(undefined);
        setStartRange(Constants.DateRate);
        setSelectedRange(
          editMode
            ? jobBudget[0] !== undefined && jobBudget[0] !== null
              ? jobBudget
              : Constants.DateRate
            : [
                Number(Constants.DateRate[0]) + 100,
                Number(Constants.DateRate[1]) - 100,
              ]
        );
        setIncrement(25);
        break;
      case 'Project Rate':
        setStartRange(undefined);
        setStartRange(Constants.ProjectRate);
        setSelectedRange(
          editMode
            ? jobBudget[0] !== undefined && jobBudget[0] !== null
              ? jobBudget
              : Constants.ProjectRate
            : [
                Number(Constants.ProjectRate[0]) + 1000,
                Number(Constants.ProjectRate[1]) - 10000,
              ]
        );
        setIncrement(100);
        break;
      case 'Year Rate':
        setStartRange(undefined);
        setStartRange(Constants.YearRate);
        setSelectedRange(
          editMode
            ? jobBudget[0] !== undefined && jobBudget[0] !== null
              ? jobBudget
              : Constants.YearRate
            : [
                Number(Constants.YearRate[0]) + 1000,
                Number(Constants.YearRate[1]) - 1000,
              ]
        );
        setIncrement(1000);
        break;
      default:
        break;
    }
  }, [jobRate]);

  const handleChange = (value) => {
    setSelectedRange(value);
    onUpdateCallback(value);
  };

  return (
    <div className="rangeSlider-0" id="rangeSlider-x">
      {startRange !== undefined && (
        <ReactSlider
          ref={slide}
          className="horizontal-slider"
          thumbClassName="example-thumb"
          trackClassName="example-track"
          value={selectedRange}
          min={startRange[0]}
          max={startRange[1]}
          ariaLabel={['Lower thumb', 'Upper thumb']}
          ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
          disabled={disabled}
          renderThumb={(props, state) => {
            return (
              <>
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: '42px',
                    // width: "42px",
                    minWidth: '42px',
                    borderRadius: '4px',
                    backgroundColor: '#FFF',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: '0px 2px 6px #AAA',
                    color: '#000000',
                    paddingLeft: '4px',
                    paddingRight: '4px',
                  }}
                >
                  {state.valueNow
                    .toString()
                    .replace(/\B(\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                  <p
                    style={{
                      marginTop: '12.5px',
                      marginLeft: '2px',
                      fontWeight: '700',
                    }}
                  >
                    {props.key === 'example-thumb-1' ? ' +' : null}
                  </p>
                </div>
              </>
            );
          }}
          renderTrack={(props, state) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '18px',
                borderRadius: '4px',
                backgroundColor: freelance ? '#8c79b5' : '#297b9f',
              }}
            />
          )}
          step={increment}
          minDistance={10}
          onChange={handleChange}
        />
      )}
    </div>
  );
};

export default RangeSlider;
