import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import Cookies from 'universal-cookie';
import countryList from 'country-list';
import Error from '../../components/Error';
import { REGISTER } from '../../graphql';
import Header from '../../components/Freelancer/Header';
import Footer from '../../components/Footer';
import jquery from '../../styles/js/app';
import {
  saveLoginTypeLocalstorage,
  saveUserLocalstorage,
  inputProps,
  inputStyle,
} from '../../utils/helpers';
import { registerVar, userVar } from '../../graphql/config';

const CreateAccountStep2 = ({ history }) => {
  useEffect(() => {
    jquery();
  }, []);

  const [phoneError, setPhoneError] = useState(false);
  const [phone, setPhoneNo] = useState(null);
  const countries = countryList.getNames().sort();
  const { register, handleSubmit, errors } = useForm();
  const [registerMutation, { loading }] = useMutation(REGISTER);

  if (!Object.keys(registerVar()).length) {
    return <Redirect to="/freelancer/register" />;
  }

  const registerUser = (data) => {
    toast.dismiss();
    validatePhone(phone);
    if (!phoneError && phone !== '') {
      registerMutation({
        variables: {
          ...data,
          ...registerVar(),
          type: 'FREELANCER',
          phone: `+${phone}`,
          can_use_logo: false,
        },
      })
        .then(({ data: { register } }) => {
          registerVar({});
          const cookies = new Cookies();
          cookies.set('lh_user', register.id, {
            path: '/',
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
          });
          saveLoginTypeLocalstorage('FREELANCER');
          saveUserLocalstorage(register);
          userVar(register);
          return history.push('/freelancer/complete-profile');
        })
        .catch((e) => {
          let msg = 'Something went wrong!';

          if (
            'validation' in e.graphQLErrors[0].extensions &&
            'input.email' in e.graphQLErrors[0].extensions.validation
          ) {
            msg = 'The email has already been taken!';
          }

          toast.error(msg, { toastId: 'frRegErr' });
        });
    }
  };

  const handleEnterKey = (event) => {
    if (event.key === 'Enter') {
      return handleSubmit(registerUser)();
    }
  };

  const validatePhone = (phone) => {
    if (!/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(phone)) {
      return setPhoneError(true);
    }
    return setPhoneError(false);
  };

  return (
    <>
      <div className="lh-wrapper">
        <Header />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="onboarding">
                <h1 className="center">Create a Candidate Account</h1>
                <div className="pagination-bubble">
                  <div className="pagination-bubble__freelancer__item bg-light-freelancer">
                    1
                  </div>
                  <div className="pagination-bubble__freelancer__item pagination-bubble__freelancer__active">
                    2
                  </div>
                  <div className="pagination-bubble__freelancer__item bg-light-freelancer">
                    3
                  </div>
                </div>
                <div className="mb-60">Personal information</div>
                <form
                  className="form form__freelancer"
                  onSubmit={handleSubmit(registerUser)}
                >
                  <input
                    type="hidden"
                    name="googleId"
                    defaultValue={registerVar().google_id || null}
                    ref={register({ required: false })}
                  />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form__heading mt-0">First Name</div>
                      <div className="form__input-block">
                        <div className="form__input-wrapper">
                          <input
                            className="form__input"
                            type="text"
                            autoComplete="false"
                            name="firstName"
                            defaultValue={registerVar().first_name || ''}
                            ref={register({ required: true })}
                          />
                          <div className="form__clear form__hidden">
                            <i className="fas fa-times" />
                          </div>
                        </div>
                      </div>
                      {errors.firstName && (
                        <Error text="Please enter your first name" />
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="form__heading mt-0">Last Name</div>
                      <div className="form__input-block">
                        <div className="form__input-wrapper">
                          <input
                            className="form__input"
                            type="text"
                            autoComplete="false"
                            name="lastName"
                            defaultValue={registerVar().last_name || ''}
                            ref={register({ required: true })}
                          />
                          <div className="form__clear form__hidden">
                            <i className="fas fa-times" />
                          </div>
                        </div>
                      </div>
                      {errors.lastName && (
                        <Error text="Please enter your lastname" />
                      )}
                    </div>
                    <div className="col-12">
                      <div className="form__heading mt-0">
                        Company Name (optional)
                      </div>
                      <div className="form__input-block">
                        <div className="form__input-wrapper">
                          <input
                            className="form__input"
                            type="text"
                            autoComplete="false"
                            name="company"
                            ref={register({ required: false })}
                          />
                          <div className="form__clear form__hidden">
                            <i className="fas fa-times" />
                          </div>
                        </div>
                      </div>
                      {errors.company && (
                        <Error text="Please enter your company name" />
                      )}
                    </div>
                    <div className="col-12">
                      <div className="form__heading mt-0">
                        Position (optional)
                      </div>
                      <div className="form__input-block">
                        <div className="form__input-wrapper">
                          <input
                            className="form__input"
                            type="text"
                            autoComplete="false"
                            name="position"
                            ref={register({ required: false })}
                          />
                          <div className="form__clear form__hidden">
                            <i className="fas fa-times" />
                          </div>
                        </div>
                      </div>
                      {errors.position && (
                        <Error text="Please enter a position" />
                      )}
                    </div>
                    <div className="col-12">
                      <div className="form__heading mt-0">Address Line 1</div>
                      <div className="form__input-block">
                        <div className="form__input-wrapper">
                          <input
                            className="form__input"
                            type="text"
                            autoComplete="false"
                            name="address1"
                            ref={register({ required: true })}
                          />
                          <div className="form__clear form__hidden">
                            <i className="fas fa-times" />
                          </div>
                        </div>
                      </div>
                      {errors.address1 && (
                        <Error text="This field is required" />
                      )}
                    </div>
                    <div className="col-12">
                      <div className="form__heading mt-0">Address Line 2</div>
                      <div className="form__input-block">
                        <div className="form__input-wrapper">
                          <input
                            className="form__input"
                            type="text"
                            autoComplete="false"
                            name="address2"
                            ref={register({ required: false })}
                          />
                          <div className="form__clear form__hidden">
                            <i className="fas fa-times" />
                          </div>
                        </div>
                      </div>
                      {errors.address2 && (
                        <Error text="This field is required" />
                      )}
                    </div>
                    <div className="col-12">
                      <div className="form__heading mt-0">City</div>
                      <div className="form__input-block">
                        <div className="form__input-wrapper">
                          <input
                            className="form__input"
                            type="text"
                            autoComplete="false"
                            name="city"
                            ref={register({ required: true })}
                          />
                          <div className="form__clear form__hidden">
                            <i className="fas fa-times" />
                          </div>
                        </div>
                      </div>
                      {errors.city && <Error text="This field is required" />}
                    </div>
                    <div className="col-12">
                      <div className="form__heading mt-0">County</div>
                      <div className="form__input-block">
                        <div className="form__input-wrapper">
                          <input
                            className="form__input"
                            type="text"
                            autoComplete="false"
                            name="state"
                            ref={register({ required: true })}
                          />
                          <div className="form__clear form__hidden">
                            <i className="fas fa-times" />
                          </div>
                        </div>
                      </div>
                      {errors.state && <Error text="This field is required" />}
                    </div>
                    <div className="col-md-6">
                      <div className="form__heading mt-0">Post code</div>
                      <div className="form__input-block">
                        <div className="form__input-wrapper">
                          <input
                            className="form__input"
                            type="text"
                            autoComplete="false"
                            name="zip"
                            ref={register({ required: true })}
                          />
                          <div className="form__clear form__hidden">
                            <i className="fas fa-times" />
                          </div>
                        </div>
                      </div>
                      {errors.zip && <Error text="This field is required" />}
                    </div>
                    <div className="col-md-6">
                      <div
                        className="form__heading mt-0"
                        style={{ color: '#000' }}
                      >
                        Country
                      </div>
                      <div className="form__input-wrapper">
                        <select
                          className="form__input form__country"
                          name="country"
                          list="countries"
                          id="country"
                          defaultValue="United Kingdom"
                          ref={register({ required: true })}
                        >
                          {countries.map((country) => (
                            <option
                              value={
                                country ===
                                'United Kingdom of Great Britain and Northern Ireland'
                                  ? 'United Kingdom'
                                  : country
                              }
                              key={
                                country ===
                                'United Kingdom of Great Britain and Northern Ireland'
                                  ? 'United Kingdom'
                                  : country
                              }
                            >
                              {country ===
                              'United Kingdom of Great Britain and Northern Ireland'
                                ? 'United Kingdom'
                                : country}
                            </option>
                          ))}
                        </select>
                        <div className="form__clear form__hidden">
                          <i className="fas fa-times" />
                        </div>
                      </div>
                      {errors.country && (
                        <Error text="Please select a country" />
                      )}
                    </div>
                    <div className="col-12">
                      <div className="form__heading mt-0">Phone Number</div>
                      <div className="form__input-block">
                        <div className="form__input-wrapper">
                          <PhoneInput
                            country="gb"
                            autoFormat
                            inputProps={inputProps}
                            inputClass="form__input"
                            containerClass="form__input-wrapper"
                            inputStyle={inputStyle}
                            buttonStyle={{
                              border: 'none',
                            }}
                            // isValid={(value) => validatePhone(value)}
                            onChange={(phone) => {
                              setPhoneNo(phone);
                            }}
                            placeholder="44 116 456 5566"
                            onKeyDown={handleEnterKey}
                          />
                          <div className="form__clear form__hidden">
                            <i className="fas fa-times" />
                          </div>
                        </div>
                      </div>
                      {phoneError && (
                        <Error text="Please enter a valid phone number" />
                      )}
                    </div>
                    <div className="col-12">
                      <div
                        className="form__input-block"
                        style={{ textAlign: 'left' }}
                      >
                        <input
                          type="checkbox"
                          id="tnc"
                          name="tnc"
                          ref={register({ required: true })}
                        />{' '}
                        <label htmlFor="tnc">
                          I agree to the{' '}
                          <a
                            href={`${process.env.REACT_APP_API_URL}/pages/terms-conditions`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="freelancer"
                          >
                            Terms and Conditions
                          </a>
                        </label>
                      </div>
                      {errors.tnc && (
                        <Error text="You must accept the terms & conditions" />
                      )}
                    </div>
                  </div>
                  <button
                    type="submit"
                    disabled={!!loading}
                    className="form__btn btn bg-freelancer d-block"
                  >
                    {loading ? 'Loading...' : 'Next'}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CreateAccountStep2;
