import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getUser } from '../../utils/helpers';

const Header = ({ isLoggedIn }) => {
  if (getUser().access_token) isLoggedIn = true;

  const location = useLocation();

  return (
    <>
      <div className="mobile-menu freelancer">
        <nav className="mobile-menu__inner">
          {isLoggedIn ? (
            <>
              <ul className="mobile-menu__list">
                <li className="mobile-menu__list__item-large">
                  {location.pathname.includes('dashboard') ? (
                    <a href="/freelancer/dashboard" className="">
                      Dashboard
                    </a>
                  ) : (
                    <Link to="/freelancer/dashboard" className="">
                      Dashboard
                    </Link>
                  )}
                </li>
                <li className="mobile-menu__list__item-large">
                  <Link to="/freelancer/personal-information" className="">
                    My Account
                  </Link>
                </li>
              </ul>
              <ul className="mobile-menu__list">
                <li className="mobile-menu__list__item-small">
                  <Link to="/freelancer/logout" className="">
                    Sign Out
                  </Link>
                </li>
              </ul>
            </>
          ) : (
            <ul className="mobile-menu__list">
              <li className="mobile-menu__list__item-large">
                {location.pathname.includes('login') ? (
                  <Link to="/freelancer/register" className="">
                    Sign Up
                  </Link>
                ) : (
                  <Link to="/freelancer/login" className="">
                    Login
                  </Link>
                )}
              </li>
            </ul>
          )}

          <div className="mobile-menu__inner__social">
            {/* <a href='/#' target='_self' className='footer__links__img'>
              <img
                src='/img/liberty-hive-logo-icon-white-small@2x.png'
                className='img img__social'
                alt=''
              />
            </a> */}
            <a
              href="https://www.instagram.com/libertyhive/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer__links__img"
            >
              <img
                src="/img/social-icon-instagram@2x.png"
                className="img img__social"
                alt=""
              />
            </a>
            <a
              href="https://www.linkedin.com/company/libertyhive/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer__links__img"
            >
              <img
                src="/img/social-icon-linkedin@2x.png"
                className="img img__social"
                alt=""
              />
            </a>
          </div>
        </nav>
      </div>
      <div className="header-freelancer">
        <div className="header-freelancer__inner container">
          <div className="row">
            <div className="header-freelancer__inner__logo col-5">
              <Link to="/freelancer/login">
                <img
                  src="/img/lh-header-logo.png"
                  className="img__logo__mobile"
                  alt=""
                />
              </Link>
              <Link to="/freelancer/login">
                <img
                  src="/img/lh-header-logo.png"
                  className="img__logo__desktop"
                  alt=""
                />
              </Link>
            </div>
            <nav className="header-freelancer__inner__menu col-7 desktop">
              <ul className="header__list">
                {isLoggedIn ? (
                  <>
                    <li className="header__list__item">
                      {location.pathname.includes('dashboard') ? (
                        <a
                          href="/freelancer/dashboard"
                          className="header__link"
                        >
                          Dashboard
                        </a>
                      ) : (
                        <Link
                          to="/freelancer/dashboard"
                          className="header__link"
                        >
                          Dashboard
                        </Link>
                      )}
                    </li>
                    <li className="header__list__item">
                      {location.pathname.includes('account') ? (
                        <a
                          href="/freelancer/personal-information"
                          className="header__link"
                        >
                          My Account
                        </a>
                      ) : (
                        <Link
                          to="/freelancer/personal-information"
                          className="header__link"
                        >
                          My Account
                        </Link>
                      )}
                    </li>
                  </>
                ) : (
                  <li className="header__list__item">
                    {location.pathname.includes('login') ? (
                      <Link to="/freelancer/register" className="header__link">
                        Sign Up
                      </Link>
                    ) : (
                      <Link to="/freelancer/login" className="header__link">
                        Login
                      </Link>
                    )}
                  </li>
                )}
              </ul>
            </nav>
            <div className="header-freelancer__inner__menu col-7 mobile-tablet">
              <div id="hamburger">
                <span />
                <span />
                <span />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
