import React, { useRef } from 'react';
import dayjs from 'dayjs';
import { formatDate } from '../../../../utils/helpers';
import { Link } from 'react-router-dom';
import { useMediaPredicate } from 'react-media-hook';
import moment from 'moment';

const OpportunityCard = ({
  title,
  createdAt,
  updatedAt,
  id,
  genres,
  skills,
  user,
  type,
  agency,
}) => {
  // eslint-disable-next-line max-len
  const tabsRef = useRef();
  const inBoth = (l1 = [], l2 = []) =>
    l1.filter((a) => l2.some((b) => a.id === b.id && a.name === b.name));
  const overlappingGenres = inBoth(user.genres, genres) ?? [];
  const overlappingSkills = inBoth(user.skills, skills) ?? [];
  const overlappingJobType = user.jobTypes.filter((x) => x.key === type) ?? [];
  const formattedCreatedAt = dayjs(createdAt, 'YY-MM-DD');
  const seventhDay = moment().subtract(7, 'd').format('YYYY-MM-DD');
  const isSevenDaysOld = moment(createdAt).isSameOrBefore(seventhDay);
  const isUpdateSevenDayOlds = moment(updatedAt).isSameOrBefore(seventhDay);
  const tags = [
    ...overlappingJobType,
    ...overlappingGenres,
    ...overlappingSkills,
  ];
  /** Checks Current ScreenWidth */
  const smallerThan992 = useMediaPredicate('(max-width: 992px)');

  return (
    <div className="col-md-6 mb-20">
      <Link
        to={`/freelancer/${id ? `opportunities/${id}` : 'dashboard'}`}
        className="nochange"
      >
        <div className="card__small" style={styles.card}>
          <div style={styles.logo}>
            <img
              src={agency.avatar || '/img/agency-picture-placeholder@2x.jpg'}
              className="card__small__img-responsive"
              alt=""
            />
          </div>
          <div style={styles.detailsWrapper}>
            <div style={styles.detailsContainer}>
              <div style={styles.details}>
                <span className="txt__fs-18 text-dark">{title}</span>
                <p>
                  <span className="link-freelancer">{agency.company}</span>
                </p>
              </div>
              <div className="text-muted" style={styles.timestamp}>
                <p
                  style={{
                    height: '100%',
                    color: '#99cccc',
                    // fontStyle: 'italic',
                    fontWeight: '600',
                    margin: 0,
                    padding: 0,
                  }}
                >
                  {!isSevenDaysOld && 'New'}
                </p>
                {isSevenDaysOld && !isUpdateSevenDayOlds && (
                  <p
                    style={{
                      margin: '1rem 0 0 0',
                      color: '#99cccc',
                      // fontStyle: 'italic',
                      fontWeight: '600',
                    }}
                  >
                    {'Updated'}
                  </p>
                )}
                <p>{formatDate(formattedCreatedAt.fromNow(true))}</p>
              </div>
            </div>
            {tags.length > 0 && (
              <div style={styles.tagsContainer}>
                <div
                  ref={tabsRef}
                  className="chips offset-md-3"
                  style={styles.footer}
                >
                  {tags.slice(0, smallerThan992 ? 3 : 4).map((chip) => (
                    <div key={chip.name} className="chip">
                      {chip.name}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};

const styles = {
  card: {
    maxHeight: 200,
    // minWidth: 450,
    height: 200,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignSelf: 'center',
  },
  logo: {
    height: '100%',
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  detailsWrapper: {
    height: '100%',
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: 10,
    paddingRight: 10,
  },
  detailsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    // alignSelf: 'center'
  },
  details: {
    width: '65%',
  },
  timestamp: {
    width: '30%',
  },
  tagsContainer: {
    width: '100%',
  },
  age: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    alignSelf: 'flex-start',
    paddingTop: 25,
    // paddingRight: 25
  },
  footer: {
    width: '100%',
    // display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    margin: 0,
  },
};

export default OpportunityCard;
