import gql from 'graphql-tag';

export const ADD_CREDIT = gql`
  mutation AddCredit($input: CreditCreate!) {
    addCredit(input: $input) {
      balance
      created_at
    }
  }
`;

export const CALCULATE_PRICE = gql`
  mutation CalculatePrice($qty: Int!, $promo: String) {
    calculatePrice(qty: $qty, promo: $promo) {
      unitPriceExcludingDiscount
      discountPercentage
      discountAmount
      unitPriceIncludingDiscount
      subtotalExcludingDiscount
      discountPerUnitAmount
      subtotalIncludingDiscount
      vatAmount
      vatPercentage
      total
    }
  }
`;
