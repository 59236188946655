export const logUserOut = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('loginType');
};

export const getLoginUrl = (type, prev) => {
  let url;

  switch (type) {
    case 'FREELANCER':
      url = `/freelancer/login?redirectTo=${prev}`;
      break;
    case 'GUEST':
      url = `/agency/login?redirectTo=${prev}`;
      break;
    case 'AGENCY':
    default:
      url = `/agency/login?redirectTo=${prev}`;
      break;
  }

  return url;
};

export const getUser = () => {
  const user = localStorage.getItem('user');
  if (!user) return {};
  try {
    return JSON.parse(user);
  } catch {
    console.error(
      'Failed to JSON.Parse user from local storage, received: ',
      user
    );
    return {};
  }
};

export const saveUserLocalstorage = (user) =>
  localStorage.setItem('user', JSON.stringify(user));

export const getLoginType = () => {
  const loginType = localStorage.getItem('loginType');
  if (!loginType) return {};
  try {
    return loginType;
  } catch {
    console.error(
      'Failed to JSON.Parse loginType from local storage, received: ',
      loginType
    );
    return {};
  }
};

export const saveLoginTypeLocalstorage = (loginType) =>
  localStorage.setItem('loginType', loginType);

export const formatValidationErrors = (errObj) => {
  let errors = '';
  Object.values(errObj).forEach((error) => {
    errors = `${errors} ${error}`;
  });
  return errors;
};

export const getId = (arr, value) => {
  let id = null;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].name === value) {
      id = arr[i].id;
      break;
    }
  }
  return id || false;
};

export const getIds = (arr) => {
  const ids = [];
  if (arr.length > 0) {
    arr.map((item) => ids.push(Number(item.id)));
  }
  return ids;
};

export const isValidDocument = (file, callback) => {
  if (!file || !file.name.toLowerCase().match(/\.(doc|docx|pdf)$/)) {
    return callback(false);
  }

  const reader = new FileReader();
  reader.readAsDataURL(file);

  return callback({ url: URL.createObjectURL(file), file });
};

export const isValidImage = (file, callback) => {
  const reader = new FileReader();

  if (!file || !file.name.toLowerCase().match(/\.(jpg|jpeg|png|gif)$/)) {
    return callback(false);
  }

  reader.onload = (e) => {
    const imgFile = new Image();
    imgFile.onerror = () => {
      callback(false);
    };
    imgFile.src = e.target.result;
  };
  reader.readAsDataURL(file);

  return callback({ url: URL.createObjectURL(file), file });
};

export const formatDate = (date) => {
  let days = date.split(' ').shift();
  days = days === 'a' ? '1' : days;
  return `${days} ${date.split(' ').pop()}`;
};

export const getOpportunityGenreOrSkillNames = (arr) => {
  const nameArr = [];
  arr.map((item) => nameArr.push(item.name));
  return nameArr.join(', ');
};

export const getNameArr = (arr) => {
  const nameArr = [];
  arr.map(({ id }) => nameArr.push(id));
  return nameArr;
};

export const getOpportunityLocation = (isOnsite, isRemote) => {
  if (isOnsite && isRemote) return 'Hybrid';
  return isOnsite ? 'Onsite' : 'Remote';
};

export const capitalizeFirstLetter = (str) => {
  str = str.toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const inputProps = {
  name: 'phone',
  required: true,
  autoFocus: false,
};

export const inputStyle = {
  width: '100%',
  height: '39px',
  outline: 'none',
  border: 'none',
  borderBottom: '1px solid black',
  borderRadius: 0,
};

const KeyCodes = {
  comma: 188,
  enter: 13,
};
export const delimiters = [KeyCodes.comma, KeyCodes.enter];

export const reactTagsFreelancerStyles = {
  tagInputField: 'form__input__search',
  tag: 'freelancer-tag',
  activeSuggestion: 'freelancer-active-suggestion',
};

export const arrToDropdownSelection = (arr) => {
  const selectionArr = [];
  arr.map(({ id, name }) => selectionArr.push({ key: id, label: name }));
  return selectionArr;
};

export const getIdsFromSelection = (arr, selection) => {
  const idArr = [];
  selection.forEach((item) => {
    idArr.push(getId(arr, item));
  });
  return idArr;
};

export const userHasApplied = (applicants) => {
  const applicant = applicants.find(
    (applicant) => parseInt(applicant.user.id) === parseInt(getUser().id)
  );
  return typeof applicant !== 'undefined';
};

export const clone = (items) =>
  items.map((item) => (Array.isArray(item) ? clone(item) : item));

export const localPrevLink = {
  setLink: (item) => {
    if (typeof localStorage === 'undefined') {
      return null;
    }
    localStorage.setItem('prevLink', item);
  },
  getLink: () => {
    if (typeof localStorage === 'undefined') {
      return null;
    }
    return localStorage.get('prevLink') || null;
  },
  removeLink: () => {
    if (typeof localStorage === 'undefined') {
      return null;
    }
    return localStorage.removeItem('prevLink');
  },
};

export const jobLevelsMultiSelectStyle = {
  chips: {
    background: '#237B9F',
  },
  searchBox: {
    border: '1px solid #aaa',
    borderRadius: '0px',
  },
  multiselectContainer: {
    color: '#237B9F',
  },
};
