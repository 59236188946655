import React from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/react-hooks';
import { DELETE_TEAM_USER_SETTING } from '../../graphql'

const DeleteTeamMemberModal = ({ user, onSubmitCallback, onCloseCallback }) => {
  const [deleteTeamUser, { loading }] = useMutation(DELETE_TEAM_USER_SETTING);

  const customStyles = {
    content: {
      top: '35%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      paddingLeft: 51,
      paddingRight: 51,
      borderRadius: 0,
      background: 'transparent',
      borderColor: 'transparent'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
  };

  const showError = () => {
    toast.error("Failed to remove team member", { toastId: 'deleteTeamMember' });
  };

  const deactivateAccount = () => {
    deleteTeamUser({
      variables: {
        input: {
          user_id: user?.user_id,
          team_id: user?.team_id
        },
      },
    })
    .then((data) => {
      toast.dismiss();
      onSubmitCallback();
      onCloseCallback();
      toast.success('Team member removal successfull', { autoClose: 4000 });
    })
    .catch((e) => {
      toast.dismiss();
      showError();
    });
  }

  // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
  Modal.setAppElement('#root');

  return (
    <Modal isOpen={true} style={customStyles} contentLabel="Example Modal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onCloseCallback}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <h3 className="txt__fw-600 text-center">Delete Team User</h3>
            <p style={{ color: '#f05454', textAlign: 'center' }}>
              This action is not reversible!
            </p>
            <div className="row">
              <div className="col-12 text-center">
                <button
                  className="btn form__btn"
                  style={{background: '#f05454'}}
                  onClick={deactivateAccount}
                >
                  Delete team user
                </button>
                <button
                  className="btn form__btn bg-agency"
                  style={{marginTop: 20}}
                  onClick={onCloseCallback}
                >
                 Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteTeamMemberModal;
