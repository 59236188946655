import React, { useEffect, useRef, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { AppContext } from '../../Store';
import { toast } from 'react-toastify';
import { userVar } from '../../graphql/config';
import Header from '../../components/Agency/Header';
import Footer from '../../components/Footer';
import jquery from '../../styles/js/app';
import AccountSubmenuMobile from '../../components/Agency/AccountSubmenuMobile';
import AccountSubmenuDesktop from '../../components/Agency/AccountSubmenuDesktop';
import { GET_CURRENT_CREDITS, UPDATE_PROFILE } from '../../graphql';
import Error from '../../components/Error';
import {
  getUser,
  isValidImage,
  saveUserLocalstorage,
} from '../../utils/helpers';

const MyAccount = () => {
  const [state, setState] = useContext(AppContext);
  const avatarRef = useRef(null);

  const { name } = userVar();
  const [balance, setBalance] = useState(0);

  const { loading, refetch } = useQuery(GET_CURRENT_CREDITS, {
    variables: {
      team_id: state?.activeCompany?.team_id,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setBalance(data.currentCredit.balance);
    },
    onError: () => refetch(),
  });
  const [updateProfile, profileState] = useMutation(UPDATE_PROFILE, {
    onCompleted: () => toast.dismiss('avUpLoading'),
  });

  const [img, setImg] = useState({
    url:
      state?.activeCompany?.user?.avatar ||
      '/img/profile-picture-placeholder@2x.jpg',
    error: null,
  });

  profileState.loading &&
    toast.info('Updating avatar...', { toastId: 'avUpLoading' });

  const onChangeFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    toast.dismiss('invalidImage');

    const file = event.target.files[0];
    isValidImage(file, (valid) => {
      if (!valid) {
        setImg({ url: '/img/agency-picture-placeholder@2x.jpg', error: true });
        return toast.error('Please select a valid image file.', {
          toastId: 'invalidImage',
        });
      }
      setImg({
        url: valid.url,
        error: false,
        file: valid.file,
      });
      updateProfile({
        variables: {
          input: {
            avatar: valid.file,
          },
        },
      })
        .then(({ data: { updateUser } }) => {
          const updatedUserObj = { ...getUser(), ...updateUser };
          userVar(updatedUserObj);
          saveUserLocalstorage(updatedUserObj);
          toast.success('Avatar updated successfully!', {
            toastId: 'profUpdate',
            autoClose: 4000,
          });
        })
        .catch((e) =>
          toast.error('Could not update avatar!', { toastId: 'resErr' })
        );
    });
  };

  return (
    <>
      <div className="lh-wrapper">
        <Header isLoggedIn />
        <AccountSubmenuMobile />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>My Account</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4 col-lg-3">
            <div className="edit-profile-picture">
              <div
                className={
                  state?.activeCompany?.role !== 'member'
                    ? 'edit-profile-picture__overlay invisible'
                    : ''
                }
                onClick={() => avatarRef.current.click()}
              >
                {state?.activeCompany?.role !== 'member' && (
                  <p className="edit-profile-picture__text">
                    <i className="far fa-edit" /> Edit
                  </p>
                )}
              </div>
              <img src={img.url} className="img img__profile" alt="" />
            </div>
            {state?.activeCompany?.role !== 'member' && (
              <>
                <input
                  type="file"
                  id="file"
                  ref={avatarRef}
                  style={{ display: 'none' }}
                  onChange={(e) => onChangeFile(e)}
                />
                <div style={{ marginTop: '25px' }}>
                  {img.error && (
                    <Error text="Please select a valid image file" />
                  )}
                </div>
                <p className="edit-profile-picture__smallprint">
                  Click on image to edit
                </p>
                <Link
                  to="/agency/edit-profile"
                  className="form__btn form__edit-profile d-block mb-50"
                >
                  Edit profile
                </Link>
              </>
            )}
          </div>
          <div className="col-12 col-md-8 col-lg-6">
            <div className="col-12 px-0">
              <div className="profile-bio-row row">
                <div className="col-12 add-opportunity-row__title">
                  <h1>{name}</h1>
                </div>
                <div
                  className="col-12 col-lg-12 profile-bio-row__content"
                  style={{ marginTop: '-23px' }}
                >
                  <h3>{state?.activeCompany?.user?.company}</h3>
                </div>
              </div>
              <div className="col-12 px-0">
                <div className="available-credits">
                  {loading ? (
                    <>
                      <div className="available-credits__heading" />
                      <div className="available-credits__subheading">
                        Loading Job Posts balance...
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="available-credits__heading">
                        {balance} Job Post
                        {balance > 1 ? 's' : ''}
                      </div>
                      <div className="available-credits__subheading">
                        Available
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <AccountSubmenuDesktop />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MyAccount;
