import React from 'react';

const DealCard = ({
  deal: {
    title, subtitle, image, link,
  },
} = {}) => (
  <div className="col-lg-4 col-md-6 mb-40">
    <div className="card__small">
      <div className="card__small__body">
        <img src={image} className="card__small__img" alt="" />
        <div>
          <h3 className="mb-0 txt__fw-600">{title}</h3>
          <p className="txt__fs-18 mb-10">{subtitle}</p>
          <a href={link} className="link-freelancer txt__fw-600 txt__small">
            Find Out More
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default DealCard;
