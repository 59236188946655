import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Header from '../../components/Freelancer/Header';
import Footer from '../../components/Footer';
import jquery from '../../styles/js/app';
import { GET_AUTH_USER } from '../../graphql';
import AccountSubmenuMobile from '../../components/Freelancer/AccountSubmenuMobile';
import { logUserOut, getLoginUrl } from '../../utils/helpers';

const Profile = () => {
  useEffect(() => {
    jquery();
  }, []);

  const allJobtypes = [
    {
      key: 'PERMANENT',
      name: 'Permanent',
    },
    {
      key: 'FREELANCE',
      name: 'Freelance',
    },
    {
      key: 'BOTH',
      name: 'Both',
    },
  ];

  const allSubJobtypes = [
    {
      key: 'FULLTIME',
      name: 'Full-time',
    },
    {
      key: 'PARTTIME',
      name: 'Part-time',
    },
    {
      key: 'BOTH_TIME',
      name: 'Both',
    },
  ];

  const [types, setTypes] = useState([]);
  const [workHour, setWorkHour] = useState([]);

  const locations = [
    {
      key: 'ONSITE',
      name: 'On site',
    },
    {
      key: 'REMOTE',
      name: 'Remote',
    },
    {
      key: 'HYBRID',
      name: 'Hybrid',
    },
  ];

  const {
    data: { me } = {},
    loading,
    refetch,
  } = useQuery(GET_AUTH_USER, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      if (!me) {
        logUserOut();
        window.location.href = getLoginUrl('FREELANCER');
      }
      if (me.jobTypes.length > 0) {
        const firstJobType = allJobtypes.filter((j) =>
          me.jobTypes.map((ajt) => ajt.key).includes(j.key)
        );
        const secondJobType = allSubJobtypes.filter((j) =>
          me.jobTypes.map((asjt) => asjt.key).includes(j.key)
        );

        if (firstJobType.length > 0) {
          if (firstJobType[0]?.name === 'Both') {
            setTypes(['Permanent', 'Freelance']);
          } else {
            setTypes([firstJobType[0].name]);
          }
        }

        if (secondJobType.length > 0) {
          if (secondJobType[0]?.name === 'Both') {
            setWorkHour(['Full-time', 'Part-time']);
          } else {
            setWorkHour([secondJobType[0].name]);
          }
        }
      }
    },
    onError: () => refetch(),
  });

  const SideBarLeft = () => (
    <div className="col-12 col-md-4 col-lg-3">
      <div className="mx-auto">
        <img
          src={me?.avatar || '/img/profile-picture-placeholder@2x.jpg'}
          className="img img__profile"
          alt=""
        />
      </div>
      <Link
        to="/freelancer/manage-cv"
        className="form__btn form__edit-profile bg-freelancer d-block mb-50"
      >
        Edit profile
      </Link>
    </div>
  );

  const Main = () => (
    <>
      <div className="col-12 col-md-8 col-lg-6 mt-5 mt-md-0 mt-lg-0">
        {loading && 'Loading profile...'}
        {me && (
          <>
            <h2 className="txt__fw-600 mb-20">About Me</h2>
            <p className="mb-40 profile-bio-row__content">{me.bio}</p>

            {me?.skills?.length > 0 && (
              <>
                <div className="mb-40" />

                <h2 className="txt__fw-600 mb-20">Skills</h2>
                <ul className="account-submenu__list mb-40">
                  {me?.skills?.map(({ id, name }) => (
                    <li className="account-submenu__list__item" key={id}>
                      <img
                        src="/img/lh-logo-sm-2.png"
                        className="mr-2"
                        alt=""
                      />{' '}
                      <a href="/#" className="link-freelancer">
                        {name}
                      </a>
                    </li>
                  ))}
                </ul>
              </>
            )}

            {me?.genres?.length > 0 && (
              <>
                <div className="mb-40" />

                <h2 className="txt__fw-600 mb-20">Category Experience</h2>
                <ul className="account-submenu__list mb-40">
                  {me?.genres?.map(({ id, name }) => (
                    <li className="account-submenu__list__item" key={id}>
                      <img
                        src="/img/lh-logo-sm-2.png"
                        className="mr-2"
                        alt=""
                      />{' '}
                      <a href="/#" className="link-freelancer">
                        {name}
                      </a>
                    </li>
                  ))}
                </ul>
              </>
            )}

            {types.length > 0 && (
              <>
                <h2 className="txt__fw-600 mb-20">Considering</h2>
                <ul className="account-submenu__list mb-40">
                  {types.map((t) => (
                    <li className="account-submenu__list__item">
                      <img
                        src="/img/lh-logo-sm-2.png"
                        className="mr-2"
                        alt=""
                      />{' '}
                      <a href="/#" className="link-freelancer">
                        {t}
                      </a>
                    </li>
                  ))}
                </ul>
              </>
            )}

            {workHour.length > 0 && (
              <>
                <h2 className="txt__fw-600 mb-20">Work Hour</h2>
                <ul className="account-submenu__list mb-40">
                  {workHour.map((w) => (
                    <li className="account-submenu__list__item">
                      <img
                        src="/img/lh-logo-sm-2.png"
                        className="mr-2"
                        alt=""
                      />{' '}
                      <a href="/#" className="link-freelancer">
                        {w}
                      </a>
                    </li>
                  ))}
                </ul>
              </>
            )}

            {me?.location && (
              <>
                <div className="mb-40" />

                <h2 className="txt__fw-600 mb-20">Job Location</h2>
                <ul className="account-submenu__list mb-40">
                  <li className="account-submenu__list__item">
                    <img src="/img/lh-logo-sm-2.png" className="mr-2" alt="" />{' '}
                    <a href="/#" className="link-freelancer">
                      {locations.find((l) => l.key === me.location)?.name}
                    </a>
                  </li>
                </ul>
              </>
            )}

            {me?.experiences?.length > 0 && (
              <>
                <h2 className="txt__fw-600 mb-20">Experience</h2>
                {me?.experiences
                  .sort(
                    (a, b) =>
                      moment(b.from).format('YYYY') -
                      moment(a.from).format('YYYY')
                  )
                  .map(({ id, title, company, description, from, to }) => (
                    <div key={id}>
                      <h3 className="txt__fw-600 mb-1">{title}</h3>
                      <p className="txt__fw-600 mb-1">{company}</p>
                      {from && (
                        <p className="txt__light mb-10">
                          {moment(from).format('YYYY')} {' - '}
                          {to ? moment(to).format('YYYY') : 'Present'}
                        </p>
                      )}
                      <p className="mb-20">{description}</p>
                    </div>
                  ))}
              </>
            )}

            {me?.education?.length > 0 && (
              <>
                <div className="mb-40" />

                <h2 className="txt__fw-600 mb-20">Qualifications</h2>
                {me?.education
                  .sort(
                    (a, b) =>
                      moment(b.from).format('YYYY') -
                      moment(a.from).format('YYYY')
                  )
                  .map(
                    ({
                      id,
                      school_name,
                      qualification,
                      from,
                      to,
                      description,
                    }) => (
                      <div key={id}>
                        <h3 className="txt__fw-600 mb-1">{qualification}</h3>
                        {from && (
                          <p className="txt__light mb-10">
                            {moment(from).format('YYYY')} {' - '}
                            {to ? moment(to).format('YYYY') : 'Present'}
                          </p>
                        )}
                        <p className="mb-20">{description}</p>
                      </div>
                    )
                  )}
              </>
            )}

            {me?.linkedin && (
              <>
                <div className="mb-40" />

                <h2 className="txt__fw-600 mb-20">LinkedIn</h2>
                <p className="txt__fw-600">
                  <a
                    className="link-freelancer"
                    href={me?.linkedin}
                    target="_blank"
                  >
                    View their LinkedIn
                  </a>
                </p>
              </>
            )}

            {me?.resumes?.length > 0 && (
              <>
                <div className="mb-40" />

                <h2 className="txt__fw-600 mb-20">CV</h2>
                <p className="txt__fw-600">
                  <a
                    className="link-freelancer"
                    href={me?.resumes[0]?.fileUrl}
                    target="_blank"
                  >
                    View their CV
                  </a>
                </p>
              </>
            )}

            {me?.minimum_salary && me?.maximum_salary && (
              <>
                <div className="mb-40" />

                <h2 className="txt__fw-600 mb-20">Salary Bands</h2>
                <p className="mb-20">
                  {me?.salary_currency} {me?.minimum_salary.toLocaleString()} -{' '}
                  {me?.maximum_salary.toLocaleString()}
                </p>
              </>
            )}

            {(me?.referee_1 || me?.referee_2) && (
              <>
                <div className="mb-40" />

                <h2 className="txt__fw-600 mb-20">Referees</h2>
                {me.referee_1 && <p className="mb-10">{me?.referee_1}</p>}

                {me.referee_2 && <p>{me?.referee_2}</p>}
              </>
            )}
          </>
        )}
      </div>
    </>
  );

  const SideBarRight = () => (
    <>
      <div className="col-12 col-lg-3">
        <div className="row bg-light-freelancer">
          <div className="account-submenu__desktop p-20 ">
            <div className="account-submenu__desktop__heading txt-black">
              Account Settings
            </div>
            <ul className="account-submenu__list">
              <li className="account-submenu__list__item">
                <Link
                  to="/freelancer/personal-information"
                  className="link-freelancer"
                >
                  Personal Information
                </Link>
              </li>
              <li className="account-submenu__list__item">
                <Link
                  to="/freelancer/edit-personal-information"
                  className="link-freelancer"
                >
                  Edit Personal Information
                </Link>
              </li>
              <li className="account-submenu__list__item">
                <Link to="/freelancer/profile" className="link-freelancer">
                  View Profile
                </Link>
              </li>
              <li className="account-submenu__list__item">
                <Link to="/freelancer/manage-cv" className="link-freelancer">
                  Edit profile
                </Link>
              </li>
              <li className="account-submenu__list__item">
                <Link
                  to="/freelancer/notification-settings"
                  className="link-freelancer"
                >
                  Notification Settings
                </Link>
              </li>
              <li className="account-submenu__list__item">
                <Link
                  to="/freelancer/profile-privacy"
                  className="link-freelancer"
                >
                  Profile Privacy
                </Link>
              </li>
              <li className="account-submenu__list__item">
                <Link
                  to="/freelancer/change-password"
                  className="link-freelancer"
                >
                  Change Password
                </Link>
              </li>
              <li className="account-submenu__list__item">
                <Link to="/freelancer/logout" className="link-freelancer">
                  Log Out
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className="lh-wrapper">
      <Header isLoggedIn />
      <AccountSubmenuMobile />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>{me?.name}</h1>
          </div>
        </div>
        <div className="row">
          <SideBarLeft />
          <Main />
          <SideBarRight />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Profile;
