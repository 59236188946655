import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Header from '../../components/Freelancer/Header';
import Footer from '../../components/Footer';
import jquery from '../../styles/js/app';
import AccountSubmenuDesktop from '../../components/Freelancer/AccountSubmenuDesktop';
import AccountSubmenuMobile from '../../components/Freelancer/AccountSubmenuMobile';
import { GET_ALL_JOB_LEVELS, UPDATE_PROFILE } from '../../graphql';
import { userVar } from '../../graphql/config';
import {
  getUser,
  isValidImage,
  jobLevelsMultiSelectStyle,
  saveUserLocalstorage,
} from '../../utils/helpers';
import Error from '../../components/Error';

const PersonalInformationEdit = ({ history }) => {
  useEffect(() => {
    jquery();
  }, []);

  const { data: { allJoblevels } = {}, loading: loadingRoles } =
    useQuery(GET_ALL_JOB_LEVELS);

  const avatarRef = useRef(null);
  const { register, watch, handleSubmit, errors } = useForm();
  const watchFields = watch(['hear_us']);
  const [updateProfile, { loading }] = useMutation(UPDATE_PROFILE);
  const hearUs = [
    'recommendation',
    'google',
    'trade press',
    'social media',
    'other',
  ];
  const allLocations = [
    {
      key: 'ONSITE',
      name: 'On site',
    },
    {
      key: 'REMOTE',
      name: 'Remote',
    },
    {
      key: 'HYBRID',
      name: 'Hybrid',
    },
  ];

  const {
    bio,
    company,
    address_1,
    address_2,
    city,
    state,
    position,
    country,
    zip,
    phone,
    phone_secondary,
    avatar,
    looking_for,
    payroll_status,
    hear_us,
    hear_us_additional,
    location,
  } = userVar();

  const [selectedJobLevels, setSelectedJobLevels] = useState(looking_for);
  const preselectedJobLevels = useCallback(
    () => allJoblevels?.filter((level) => looking_for?.includes(level.key)),
    [allJoblevels, looking_for]
  );

  const [img, setImg] = useState({
    url: avatar || '/img/profile-picture-placeholder@2x.jpg',
    error: null,
  });

  const onChangeFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    toast.dismiss('invalidImage');

    const file = event.target.files[0];
    isValidImage(file, (valid) => {
      if (!valid) {
        setImg({ url: '/img/agency-picture-placeholder@2x.jpg', error: true });
        return toast.error('Please select a valid image file.', {
          toastId: 'invalidImage',
        });
      }
      setImg({
        url: valid.url,
        error: false,
        file: valid.file,
      });
    });
  };

  const editProfile = (data) => {
    toast.dismiss();
    if (img.error) {
      return toast.error('Please select a valid image file.');
    }

    return updateProfile({
      variables: {
        input: {
          ...data,
          avatar: img.file,
          looking_for: selectedJobLevels,
        },
      },
    })
      .then(({ data: { updateUser } }) => {
        const updatedUserObj = { ...getUser(), ...updateUser };
        userVar(updatedUserObj);
        saveUserLocalstorage(updatedUserObj);
        return history.push('/freelancer/personal-information');
      })
      .catch((e) => {
        toast.error('Something went wrong!', { toastId: 'resErr' });
      });
  };

  const handleJobChange = (selectedList, _) =>
    setSelectedJobLevels(selectedList.map((s) => s.key));

  return (
    <>
      <div className="lh-wrapper">
        <Header isLoggedIn />
        <AccountSubmenuMobile />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Update Your Personal Information</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4 col-lg-3">
            <div className="edit-profile-picture">
              <div
                className="edit-profile-picture__overlay invisible"
                onClick={() => avatarRef.current.click()}
              >
                <p className="edit-profile-picture__text">
                  <i className="far fa-edit" /> Edit
                </p>
              </div>
              <img src={img.url} className="img img__profile" alt="" />
            </div>
            <input
              type="file"
              id="file"
              ref={avatarRef}
              style={{ display: 'none' }}
              onChange={(e) => onChangeFile(e)}
            />
            <div style={{ marginTop: '25px' }}>
              {img.error && <Error text="Please select a valid image file" />}
            </div>
            <p className="edit-profile-picture__smallprint">
              Click on image to edit
            </p>
          </div>
          <div className="col-12 col-md-8 col-lg-6">
            <div className="row">
              <div className="col-12">
                <div className="profile-bio-row row">
                  <div className="col-12 add-opportunity-row__title">
                    <span className="txt__fw-600">About Me </span>
                    <span className="small">
                      (this is not a CV, just a simple overview)
                    </span>
                  </div>
                  <div className="col-10 profile-bio-row__content">
                    <textarea
                      rows="5"
                      maxLength="1000"
                      name="bio"
                      defaultValue={bio}
                      className="form__textarea__border-light height__unset"
                      ref={register({ required: true })}
                    />
                  </div>
                </div>
                {errors.bio && <Error text="This field is required" noMargin />}

                <div className="profile-bio-row row">
                  <div className="col-12 add-opportunity-row__title">
                    <span className="txt__fw-600">Company Name</span>{' '}
                    <span className="small">(optional)</span>
                  </div>
                  <div className="col-10 profile-bio-row__content">
                    <div className="form__input-wrapper">
                      <input
                        className="form__input__border-light"
                        type="text"
                        name="company"
                        defaultValue={company || ''}
                        ref={register({ required: false })}
                      />
                    </div>
                  </div>
                </div>
                {errors.company && (
                  <Error text="This field is required" noMargin />
                )}
                <div className="profile-bio-row row">
                  <div className="col-12 add-opportunity-row__title">
                    <span className="txt__fw-600">Position</span>{' '}
                    <span className="small">(optional)</span>
                  </div>
                  <div className="col-10 profile-bio-row__content">
                    <div className="form__input-wrapper">
                      <input
                        className="form__input__border-light"
                        type="text"
                        name="position"
                        defaultValue={position || ''}
                        ref={register({ required: false })}
                      />
                    </div>
                  </div>
                </div>

                {errors.position && (
                  <Error text="This field is required" noMargin />
                )}
                <div className="profile-bio-row row">
                  <div className="col-12 add-opportunity-row__title">
                    <span className="txt__fw-600">Address 1</span>
                  </div>
                  <div className="col-10 profile-bio-row__content">
                    <div className="form__input-wrapper">
                      <input
                        className="form__input__border-light"
                        type="text"
                        name="address_1"
                        defaultValue={address_1}
                        ref={register({ required: true })}
                      />
                    </div>
                  </div>
                </div>
                {errors.address_1 && (
                  <Error text="This field is required" noMargin />
                )}
                <div className="profile-bio-row row">
                  <div className="col-12 add-opportunity-row__title">
                    <span className="txt__fw-600">Address 2 </span>
                    <span className="small">(optional)</span>
                  </div>
                  <div className="col-10 profile-bio-row__content">
                    <div className="form__input-wrapper">
                      <input
                        className="form__input__border-light"
                        type="text"
                        name="address_2"
                        defaultValue={address_2}
                        ref={register({ required: false })}
                      />
                    </div>
                  </div>
                </div>
                {errors.address_2 && (
                  <Error text="This field is required" noMargin />
                )}
                <div className="profile-bio-row row">
                  <div className="col-12 add-opportunity-row__title">
                    <span className="txt__fw-600">City</span>
                  </div>
                  <div className="col-10 profile-bio-row__content">
                    <div className="form__input-wrapper">
                      <input
                        className="form__input__border-light"
                        type="text"
                        name="city"
                        defaultValue={city}
                        ref={register({ required: true })}
                      />
                    </div>
                  </div>
                </div>
                {errors.city && (
                  <Error text="This field is required" noMargin />
                )}
                <div className="profile-bio-row row">
                  <div className="col-12 add-opportunity-row__title">
                    <span className="txt__fw-600">County</span>
                  </div>
                  <div className="col-10 profile-bio-row__content">
                    <div className="form__input-wrapper">
                      <input
                        className="form__input__border-light"
                        type="text"
                        name="state"
                        defaultValue={state}
                        ref={register({ required: true })}
                      />
                    </div>
                  </div>
                </div>
                {errors.county && (
                  <Error text="This field is required" noMargin />
                )}
                <div className="profile-bio-row row">
                  <div className="col-12 add-opportunity-row__title">
                    <span className="txt__fw-600">Country</span>
                  </div>
                  <div className="col-10 profile-bio-row__content">
                    <div className="form__input-wrapper">
                      <input
                        className="form__input__border-light"
                        type="text"
                        name="country"
                        defaultValue={country}
                        ref={register({ required: true })}
                      />
                    </div>
                  </div>
                </div>
                {errors.country && (
                  <Error text="This field is required" noMargin />
                )}
                <div className="profile-bio-row row">
                  <div className="col-12 add-opportunity-row__title">
                    <span className="txt__fw-600">Post code</span>
                  </div>
                  <div className="col-10 profile-bio-row__content">
                    <div className="form__input-wrapper">
                      <input
                        className="form__input__border-light"
                        type="text"
                        name="zip"
                        defaultValue={zip}
                        ref={register({ required: true })}
                      />
                    </div>
                  </div>
                </div>
                {errors.zip && <Error text="This field is required" noMargin />}

                <div className="profile-bio-row row">
                  <div className="col-12 add-opportunity-row__title">
                    <span className="txt__fw-600">Phone Number</span>
                  </div>
                  <div className="col-10 profile-bio-row__content">
                    <input
                      className="form__input__border-light"
                      type="text"
                      name="phone"
                      defaultValue={phone}
                      ref={register({
                        required: true,
                        pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
                      })}
                    />
                  </div>
                </div>
                {errors.phone && (
                  <Error text="Please enter a valid phone number" noMargin />
                )}

                {phone_secondary !== null &&
                  phone_secondary !== undefined &&
                  phone_secondary.length > 0 && (
                    <div className="profile-bio-row row">
                      <div className="col-12 add-opportunity-row__title">
                        <span className="txt__fw-600">
                          Secondary Phone Number
                        </span>
                      </div>
                      <div className="col-10 profile-bio-row__content">
                        <input
                          className="form__input__border-light"
                          type="text"
                          name="phone_secondary"
                          defaultValue={phone_secondary}
                          ref={register({
                            required: true,
                            pattern:
                              /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
                          })}
                        />
                      </div>
                    </div>
                  )}
                {errors.phone_secondary && (
                  <Error text="Please enter a valid phone number" noMargin />
                )}

                <div className="profile-bio-row row">
                  <div className="col-12 add-opportunity-row__title">
                    <span className="txt__fw-600">
                      What level of roles would you like to see?{' '}
                      <span className="d-block font-italic font-weight-normal">
                        Select the most relevant for you (max 2)
                      </span>
                    </span>
                  </div>
                  <div className="col-10 profile-bio-row__content">
                    {loadingRoles && <span>Loading roles...</span>}
                    {allJoblevels && (
                      <Multiselect
                        options={allJoblevels}
                        onSelect={handleJobChange}
                        onRemove={handleJobChange}
                        displayValue="name"
                        closeOnSelect={false}
                        avoidHighlightFirstOption
                        placeholder="-- Select Roles --"
                        style={jobLevelsMultiSelectStyle}
                        selectionLimit={2}
                        selectedValues={preselectedJobLevels()}
                      />
                    )}
                  </div>
                </div>

                <div className="profile-bio-row row">
                  <div className="col-12 add-opportunity-row__title">
                    <span className="txt__fw-600">
                      Do you require going on the payroll or are you a limited
                      company?
                    </span>
                  </div>
                  <div className="col-10 profile-bio-row__content">
                    <select
                      className="form__input__border-light"
                      name="payroll_status"
                      defaultValue={payroll_status}
                      ref={register({ required: false })}
                    >
                      <option disabled value="0">
                        -- Select --
                      </option>
                      <option value="1" key="payroll">
                        Payroll
                      </option>
                      <option value="2" key="limited">
                        Limited Company
                      </option>
                      <option value="3" key="limited">
                        Either
                      </option>
                    </select>
                  </div>
                </div>

                <div className="profile-bio-row row">
                  <div className="col-12 add-opportunity-row__title">
                    <span className="txt__fw-600">
                      Where would you like to work?
                    </span>
                  </div>
                  <div className="col-10 profile-bio-row__content">
                    <select
                      className="form__input__border-light"
                      name="location"
                      defaultValue={location}
                      ref={register({ required: false })}
                    >
                      <option disabled selected>
                        -- Select --
                      </option>
                      {allLocations.map((location, index) => (
                        <option value={location.key} key={location.key}>
                          {location.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="profile-bio-row row">
                  <div className="col-12 add-opportunity-row__title">
                    <span className="txt__fw-600">
                      How did you hear about us?
                    </span>
                  </div>
                  <div className="col-10 profile-bio-row__content">
                    <select
                      className="form__input__border-light mb-30"
                      name="hear_us"
                      defaultValue={hear_us}
                      ref={register({ required: false })}
                    >
                      <option disabled selected>
                        -- Select --
                      </option>
                      {hearUs.map((h) => (
                        <option value={h} key={h}>
                          {h.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())}
                        </option>
                      ))}
                    </select>
                    {watchFields && (
                      <>
                        {(watchFields.hear_us === 'recommendation' ||
                          watchFields.hear_us === 'other') && (
                          <div className="row">
                            <div className="col-12">
                              <span className="txt__fw-600">
                                Please specify (optional)
                              </span>
                            </div>
                            <div className="col-12">
                              <input
                                className="form__input__border-light"
                                type="text"
                                name="hear_us_additional"
                                defaultValue={hear_us_additional}
                                ref={register({ required: false })}
                              />
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-10">
                    <a
                      href="/#"
                      className="form__btn d-block bg-freelancer mb-50"
                      onClick={handleSubmit(editProfile)}
                    >
                      {loading ? 'Loading...' : 'Save'}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AccountSubmenuDesktop />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PersonalInformationEdit;
