import React from 'react';
import dayjs from 'dayjs';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_APPLICANT } from '../../../../graphql';
import hideModal, { openHideApplicationModal } from '../../../../styles/js/hideModal';
import ConfirmHideApplication from '../../../../components/Freelancer/ConfirmHideApplicationModal';
import { toast } from 'react-toastify';
import { formatDate } from '../../../../utils/helpers';

const OpportunitiesAppliedFor = ({
    loading, applications = [], updateApplications, history,
  }) => {
    const [updateApplicant, { loading: loadingUpdateApplicant }] = useMutation(
      UPDATE_APPLICANT,
    );
  
    const hideApplication = (id) => {
      updateApplicant({
        variables: {
          id,
          input: {
            is_shown: false,
          },
        },
      })
        .then(() => {
          updateApplications({
            application: applications?.filter((a) => a.id !== id),
          });
          hideModal();
        })
        .catch(() => toast.error('Something went wrong!', { toastId: 'err' }));
    };
  
    return (
      <>
        <div className="col-12">
          <h1>Job Opportunities You&apos;ve Applied For</h1>
        </div>
        {loading && 'Loading applications...'}
        {applications
          .filter((a) => a.opportunity && a.is_shown)
          .map(
            ({
              id,
              created_at,
              opportunity: {
                id: opportunityId,
                title,
                agency: { company, avatar },
                is_active,
                deleted_at,
              },
            }) => (
              <div className="col-12 mb-20" id={`application${id}`} key={id}>
                <ConfirmHideApplication
                  id={id}
                  confirm={() => hideApplication(id)}
                  loading={loadingUpdateApplicant}
                />
                <div className="card__small">
                  <div
                    className="card__small__body"
                    onClick={() => history.push(`/freelancer/opportunities/${opportunityId}`)}
                  >
                    <div className="col-2">
                      <img
                        src={avatar || '/img/agency-picture-placeholder@2x.jpg'}
                        className="card__small__img-responsive"
                        alt=""
                      />
                    </div>
                    <div className="col-5">
                      <span className="txt__fs-18 text-dark">{title}</span>
                      <div>
                        <span className="link-freelancer">{company}</span>
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="txt__small mb-0">
                        <div className="txt__fw-600">Status: </div>
                        {is_active && !deleted_at ? 'Open' : 'Closed'}
                      </div>
                    </div>
                    <div className="col-2">
                      <p className="mb-0 ml-auto">
                        {formatDate(
                          dayjs(created_at, 'YY-MM-DD').fromNow(true),
                        )}
                      </p>
                    </div>
                    <div className="col-1">
                      {(!is_active || deleted_at) && (
                      <button
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          openHideApplicationModal(id);
                        }}
                        className="btn link-freelancer txt__fw-600 ml-auto d-md-block d-none"
                      >
                        Hide
                      </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ),
          )}
      </>
    );
};
  
export default OpportunitiesAppliedFor