import gql from 'graphql-tag';

export const GET_CREDIT_HISTORY = gql`
  query CreditHistory(
    $orderBy: [CreditHistoryOrderByOrderByClause!]
    $agency: Int
  ) {
    creditHistory(orderBy: $orderBy, agency: $agency) {
      type
      qty
      total
      balance
      invoice_id
      created_at
    }
  }
`;

export const GET_CURRENT_CREDITS = gql`
  query currentCredit($team_id: Int!){
    currentCredit(team_id: $team_id){
      qty
      total
      balance
      created_at
    }
  }
`;

export const GET_SUBSCRIPTION_HISTORY = gql`
  query SubscriptionHistory(
    $orderBy: [SubscriptionHistoryOrderByOrderByClause!]
    $agency: Int
  ) {
    subscriptionHistory(orderBy: $orderBy, agency: $agency) {
      price
      stripe_id
      created_at
      ends_at
    }
  }
`;
