import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getUser } from '../../utils/helpers';

const HeaderBlog = ({ isLoggedIn }) => {
  if (getUser().access_token) isLoggedIn = true;

  const location = useLocation();
  return (
    <>
      <div className="mobile-menu agency">
        <nav className="mobile-menu__inner">
          <ul className="mobile-menu__list">
            {!isLoggedIn && (
              <li className="mobile-menu__list__item-large">
                {location.pathname.includes('login') ? (
                  <Link to="/agency/register" className="">
                    Sign Up
                  </Link>
                ) : (
                  <Link to="/agency/login" className="">
                    Login
                  </Link>
                )}
              </li>
            )}
          </ul>
          <div className="mobile-menu__inner__social">
            {/* <a
              href='/agency/dashboard'
              target='_self'
              className='footer__links__img'
            >
              <img
                src='/img/liberty-hive-logo-icon-white-small@2x.png'
                className='img img__social'
                alt=''
              />
            </a> */}
            <a
              href="https://www.instagram.com/libertyhive/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer__links__img"
            >
              <img
                src="/img/social-icon-instagram@2x.png"
                className="img img__social"
                rel="noopener noreferrer"
                alt=""
              />
            </a>
            <a
              href="https://www.linkedin.com/company/libertyhive/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer__links__img"
            >
              <img
                src="/img/social-icon-linkedin@2x.png"
                className="img img__social"
                alt=""
              />
            </a>
          </div>
        </nav>
      </div>
      <div className="header-agency">
        <div className="header-agency__inner container">
          <div className="row">
            <div className="header-agency__inner__logo col-5">
              <a href="/agency/login">
                <img
                  src="/img/lh-header-logo.png"
                  className="img__logo__mobile"
                  alt=""
                />
              </a>
              <a href="/agency/login">
                <img
                  src="/img/lh-header-logo.png"
                  className="img__logo__desktop"
                  alt=""
                />
              </a>
            </div>
            <nav className="header-agency__inner__menu col-7 desktop">
              <ul className="header__list">
                {!isLoggedIn ?? (
                  <li className="header__list__item">
                    {location.pathname.includes('login') ? (
                      <Link to="/agency/register" className="header__link">
                        Sign Up
                      </Link>
                    ) : (
                      <Link to="/agency/login" className="header__link">
                        Login
                      </Link>
                    )}
                  </li>
                )}
              </ul>
            </nav>
            <div className="header-agency__inner__menu col-7 mobile-tablet">
              <div id="hamburger">
                <span />
                <span />
                <span />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderBlog;
