import gql from 'graphql-tag';

export const GET_TEAM_ASSOCIATIONS = gql `
  query ViewUserTeamAssociation($team_id: Int!) {
    viewUserTeamAssociation(team_id: $team_id) {
        id
        user_id
        team_id
        associate_id
        user {
          avatar
          first_name
          last_name
        }
    }
  }
`;

export const GET_USERS_ASSOCIATIONS = gql `
  query ViewUserAssociation($user_id: Int!) {
    viewUserAssociation(user_id: $user_id) {
        id
        user_id
        team_id
        associate_id
        user {
          avatar
          first_name
          last_name
        }
    }
  }
`;