import * as React from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import Cookies from 'universal-cookie';
import { ADMIN_IMPERSONATE_USER } from '../../graphql';
import { saveUserLocalstorage } from '../../utils/helpers';
import { userVar } from '../../graphql/config';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AdminImpersonateFreelancer = () => {
  const token = useQuery().get('token');
  const { id } = useParams();
  const history = useHistory();
  const [loginMutation, { loading, error }] = useMutation(
    ADMIN_IMPERSONATE_USER,
    {
      onCompleted: ({ adminImpersonateUser }) => {
        if (adminImpersonateUser?.type === 'FREELANCER') {
          const cookies = new Cookies();
          cookies.set('lh_user', adminImpersonateUser.id, {
            path: '/',
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
          });
          userVar(adminImpersonateUser);
          saveUserLocalstorage(adminImpersonateUser);
          history.push('/freelancer/dashboard');
        }
      },
      onError: () => {
        // history.push('/goback');
      },
      context: {
        headers: {
          'X-CSRF-TOKEN': token,
        },
        credentials: 'same-origin',
      },
    }
  );

  React.useEffect(() => {
    // eslint-disable-next-line consistent-return
    (async () => {
      loginMutation({ variables: { id, token } });
    })();

    const cookies = new Cookies();
    cookies.set('XSRF-TOKEN', token, {
      path: '/',
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, id]);

  /*
   * On load get the Id from the url - get the CSRF token query param
   * Set the CSRF in the header
   * hit new login endpoint
   */

  if (loading) return <Loading />;
  if (error) return <Error />;

  return <div>Done</div>;
};

export default AdminImpersonateFreelancer;

const Loading = () => <div>loading...</div>;

const Error = () => <div>error...</div>;
