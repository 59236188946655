import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import Header from '../../components/Freelancer/Header';
import Footer from '../../components/Footer';
import jquery from '../../styles/js/app';
import { GET_USER } from '../../graphql';

const CompanyProfile = ({
  match: {
    params: { id },
  },
}) => {
  useEffect(() => {
    jquery();
  }, []);

  const { data, loading, refetch } = useQuery(GET_USER, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
    },
    onError: () => refetch(),
  });

  return (
    <div className="lh-wrapper">
      <Header isLoggedIn />
      <div className="container">
        {loading && <div>Loading company profile...</div>}
        {data && (
          <div className="row mb-100">
            <div className="col-12 order-lg-last mt-lg-3 mb-2 text-lg-right">
              <Link
                to="/freelancer/dashboard"
                className="link-freelancer txt__fw-600"
              >
                Back To Dashboard
              </Link>
            </div>
            <div className="col-12">
              <div className="card__container">
                <div className="row">
                  <div className="col-lg-4">
                    <img
                      src={
                        data.user.avatar
                        || '/img/agency-picture-placeholder@2x.jpg'
                      }
                      alt={data.user.name}
                    />
                  </div>
                  <div className="col-lg-8">
                    <h1 className="mb-30">{data.user.company}</h1>
                    <p className="mb-30">{data.user.bio}</p>
                    <div className="mb-10 font-weight-bold">
                      What Sets Us Apart
                    </div>
                    <p className="mb-30">{data.user.description}</p>
                    <div className="mb-10 font-weight-bold">Address</div>
                    <p>
                      {data.user.address_1}
                      {' '}
                      <br />
                      {data.user.address_2}
                      {' '}
                      {data.user.address_2 && <br />}
                      {data.user.city}
                      {' '}
                      <br />
                      {data.user.state}
                      {' '}
                      <br />
                      {data.user.country}
                      {' '}
                      <br />
                      {data.user.zip}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default CompanyProfile;
