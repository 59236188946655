import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { UPDATE_PROFILE } from '../../../../graphql';

const VerificationConfirmationModal = ({
  onSubmitCallback,
  onCloseCallback,
}) => {
  const { register, handleSubmit } = useForm();
  const [updateProfile] = useMutation(UPDATE_PROFILE);

  const customStyles = {
    content: {
      top: '35%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      paddingLeft: 51,
      paddingRight: 51,
      borderRadius: 0,
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
  };

  function VerificationConfirmation() {
    updateProfile({
      variables: {
        input: {
          send_text_notification: true,
        },
      },
    })
      .then(() => onSubmitCallback())
      .catch(() => toast.error('Something went wrong.', { autoClose: 5000 }));
  }

  // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
  Modal.setAppElement('#root');

  return (
    <Modal isOpen={true} style={customStyles} contentLabel="Example Modal">
      <form
        className="form"
        onSubmit={handleSubmit(VerificationConfirmation)}
        style={{
          width: '100%',
        }}
      >
        <div
          style={{
            minWidth: '395px',
            minHeight: '250px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              flex: 7,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                fontWeight: '600',
                fontSize: '20px',
                lineHeight: '26px',
                textAlign: 'center',
              }}
            >
              SMS Notification
            </span>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flex: 6.5,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              paddingRight: '5%',
            }}
          >
            <span
              style={{
                fontWeight: '300',
                fontSize: '16px',
                lineHeight: '26px',
                textAlign: 'left',
              }}
            >
              Thank you for verifying your number. You're all set!
            </span>
          </div>
          <div
            style={{
              width: '90%',
              left: '-10%',
              display: 'flex',
              flex: 0.7,
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              alignItems: 'flex-start',
              alignSelf: 'flex-start',
            }}
          >
            <></>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flex: 2,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <button
              type="submit"
              className="form__btn form__edit-profile bg-freelancer d-block mb-50"
              style={{
                width: 300,
                height: 48,
              }}
            >
              <span
                style={{
                  fontWeight: 700,
                  fontSize: '16px',
                  lineHeight: '24px',
                  letterSpacing: '5%',
                }}
              >
                CLOSE
              </span>
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default VerificationConfirmationModal;
