import { Switch, Route, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CreateAccount from '../views/Agency/CreateAccount';
import CreateAccountStep2 from '../views/Agency/CreateAccountStep2';
import CompleteProfile from '../views/Agency/CompleteProfile';
import CreateTeamAccount from '../views/Team/CreateAccount';
import CreateTeamAccountStep2 from '../views/Team/CreateAccountStep2';
import CompleteTeamProfile from '../views/Team/CompleteProfile';
import Welcome from '../views/Team/Welcome';
import CompleteProfileFreelancer from '../views/Freelancer/CompleteProfile';
import CreateAccountFreelancer from '../views/Freelancer/CreateAccount';
import AdminImpersonateFreelancer from '../views/Freelancer/AdminImpersonateFreelancer';
import AdminImpersonateAgency from '../views/Agency/AdminImpersonateAgency';
import CreateAccountStep2Freelancer from '../views/Freelancer/CreateAccountStep2';
import StartSubscription from '../views/Agency/StartSubscription';
import ManagePaymentInfo from '../views/Agency/ManagePaymentInfo';
import ManageCredits from '../views/Agency/ManageCredits';
import NewOpportunity from '../views/Agency/NewOpportunity';
import EditOpportunity from '../views/Agency/EditOpportunity';
import ApplicantList from '../views/Agency/ApplicantList';
import Dashboard from '../views/Agency/Dashboard';
import DashboardFreelancer from '../views/Freelancer/Dashboard';
import NotificationSettings from '../views/Freelancer/NotificationSettings';
import Profile from '../views/Agency/Profile';
import FreelancerProfile from '../views/Agency/FreelancerProfile';
import ProfileFreelancer from '../views/Freelancer/Profile';
import ProfileEdit from '../views/Agency/ProfileEdit';
import BillingHistory from '../views/Agency/BillingHistory';
import BlogIndividual from '../views/Agency/BlogIndividual';
import BlogIndividualFreelancer from '../views/Freelancer/BlogIndividual';
import BlogIndividualMarketing from '../views/Marketing/BlogIndividual';
import Blog from '../views/Agency/Blog';
import ForgotPassword from '../views/Agency/ForgotPassword';
import ForgotPasswordFreelancer from '../views/Freelancer/ForgotPassword';
import Login from '../views/Agency/Login';
import SocialLogin from '../views/SocialLogin';
import MarketingLogin from '../views/MarketingLogin';
import MarketingRegister from '../views/MarketingRegister';
import LoginFreelancer from '../views/Freelancer/Login';
import SetNewPassword from '../views/Agency/SetNewPassword';
import SetNewPasswordFreelancer from '../views/Freelancer/SetNewPassword';
import BlogAll from '../views/Freelancer/BlogAll';
import CompanyProfile from '../views/Freelancer/CompanyProfile';
import ManageCV from '../views/Freelancer/ManageCV';
import OpportunityDetail from '../views/Freelancer/OpportunityDetail';
import PersonalInformationEdit from '../views/Freelancer/PersonalInformationEdit';
import PersonalInformation from '../views/Freelancer/PersonalInformation';
import ProfilePrivacy from '../views/Freelancer/ProfilePrivacy';
import AuthWrapper from '../components/AuthWrapper';
import LandingPage from '../views/Agency/LandingPage';
import Logout from '../views/Agency/Logout';
import LogoutFreelancer from '../views/Freelancer/Logout';
import NotFound from '../components/404';
import LatestOpportunities from '../views/Freelancer/LatestOpportunities';
import OpportunitiesForYou from '../views/Freelancer/OpportunitiesForYou';
import MyAccount from '../views/Agency/MyAccount';
import ChangePassword from '../views/Agency/ChangePassword';
import ChangePasswordFreelancer from '../views/Freelancer/ChangePassword';

import Invitations from '../views/Agency/Invitations';
import ManageTeams from '../views/Agency/ManageTeams';

import LoginCommon from '../views/Login';
import { localPrevLink } from '../utils/helpers';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const Routes = () => {
  const location = useLocation();

  useEffect(() => {
    localPrevLink.setLink(location.pathname);
  }, [location]);

  useEffect(
    () => () => {
      localPrevLink.removeLink(location.pathname);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );

  return (
    <Switch>
      <Route path="/" exact component={LandingPage} />
      <Route path="/login/google" exact component={SocialLogin} />
      <Route path="/login/marketing" exact component={MarketingLogin} />
      <Route path="/register/marketing" exact component={MarketingRegister} />
      <Route path="/login" exact component={LoginCommon} />
      <Route
        path="/freelancer/register"
        exact
        component={CreateAccountFreelancer}
      />
      <Route
        path="/freelancer/register/2"
        exact
        component={CreateAccountStep2Freelancer}
      />
      <Route path="/freelancer/login" exact component={LoginFreelancer} />
      <Route
        path="/freelancer/forgot-password"
        exact
        component={ForgotPasswordFreelancer}
      />
      <Route
        path="/freelancer/reset-password"
        exact
        component={SetNewPasswordFreelancer}
      />
      <Route
        path="/freelancer/impersonate/:id"
        exact
        component={AdminImpersonateFreelancer}
      />
      <Route path="/team/register/:id" exact component={CreateTeamAccount} />
      <Route
        path="/team/profile/register"
        exact
        component={CreateTeamAccountStep2}
      />
      <Route path="/team/welcome" exact component={Welcome} />
      <AuthWrapper
        path="/team/profile/complete"
        exact
        component={CompleteTeamProfile}
        user={['AGENCY', 'GUEST']}
      />
      <Route
        path="/agency/impersonate/:id"
        exact
        component={AdminImpersonateAgency}
      />
      <Route path="/agency/register" exact component={CreateAccount} />
      <Route path="/agency/register/2" exact component={CreateAccountStep2} />
      <Route path="/agency/articles/:slug" exact component={BlogIndividual} />
      <Route
        path="/freelancer/articles/:slug"
        exact
        component={BlogIndividualFreelancer}
      />
      {/* <Route
      path='/agency/resources/:slug'
      exact
      component={ResourcesIndividual}
    /> */}
      <Route path="/agency/forgot-password" exact component={ForgotPassword} />
      <Route path="/agency/login" exact component={Login} />
      <Route path="/agency/reset-password" exact component={SetNewPassword} />
      <AuthWrapper
        path="/freelancer/complete-profile"
        exact
        component={CompleteProfileFreelancer}
        user={['FREELANCER']}
      />
      <AuthWrapper
        path="/freelancer/dashboard"
        exact
        component={DashboardFreelancer}
        user={['FREELANCER']}
      />
      <AuthWrapper
        path="/freelancer/notification-settings"
        exact
        component={NotificationSettings}
        user={['FREELANCER']}
      />
      <AuthWrapper
        path="/freelancer/latest-opportunities"
        exact
        component={LatestOpportunities}
        user={['FREELANCER']}
      />
      <AuthWrapper
        path="/freelancer/opportunities-for-you"
        exact
        component={OpportunitiesForYou}
        user={['FREELANCER']}
      />
      <AuthWrapper
        path="/freelancer/company-profile/:id"
        exact
        component={CompanyProfile}
        user={['FREELANCER']}
      />
      <AuthWrapper
        path="/freelancer/opportunities/:id"
        exact
        component={OpportunityDetail}
        user={['FREELANCER']}
      />
      <Route
        path="/freelancer/logout"
        exact
        component={LogoutFreelancer}
        user={['FREELANCER']}
      />
      <AuthWrapper
        path="/freelancer/manage-cv"
        exact
        component={ManageCV}
        user={['FREELANCER']}
      />
      <AuthWrapper
        path="/freelancer/personal-information"
        exact
        component={PersonalInformation}
        user={['FREELANCER']}
      />
      <AuthWrapper
        path="/freelancer/edit-personal-information"
        exact
        component={PersonalInformationEdit}
        user={['FREELANCER']}
      />
      <AuthWrapper
        path="/freelancer/profile"
        exact
        component={ProfileFreelancer}
        user={['FREELANCER']}
      />
      <AuthWrapper
        path="/freelancer/profile-privacy"
        exact
        component={ProfilePrivacy}
        user={['FREELANCER']}
      />
      <AuthWrapper
        path="/freelancer/change-password"
        exact
        component={ChangePasswordFreelancer}
        user={['FREELANCER']}
      />
      <AuthWrapper
        path="/freelancer/resources"
        exact
        component={BlogAll}
        user={['FREELANCER']}
      />

      {/* <AuthWrapper
      path='/freelancer/resources'
      exact
      component={ResourcesFreelancer}
      user='FREELANCER'
    /> */}
      <AuthWrapper
        path="/agency/profile"
        exact
        component={Profile}
        user={['AGENCY']}
      />
      <AuthWrapper
        path="/agency/edit-profile"
        exact
        component={ProfileEdit}
        user={['AGENCY', 'GUEST']}
      />
      <AuthWrapper
        path="/agency/change-password"
        exact
        component={ChangePassword}
        user={['AGENCY', 'GUEST']}
      />
      <AuthWrapper
        path="/agency/dashboard"
        exact
        component={Dashboard}
        user={['AGENCY', 'GUEST']}
      />
      <AuthWrapper
        path="/agency/freelancer-profile/:id"
        exact
        component={FreelancerProfile}
        user={['AGENCY', 'GUEST']}
      />
      {/* <AuthWrapper
      path='/freelancer/resources/:slug'
      exact
      component={ResourcesIndividualFreelancer}
      user='FREELANCER'
    /> */}
      <Route
        path="/agency/logout"
        exact
        component={Logout}
        user={['AGENCY', 'GUEST']}
      />
      <AuthWrapper
        path="/agency/complete-profile"
        exact
        component={CompleteProfile}
        user={['AGENCY', 'GUEST']}
      />
      <AuthWrapper
        path="/agency/add-opportunity"
        exact
        component={NewOpportunity}
        user={['AGENCY', 'GUEST']}
      />
      <AuthWrapper
        path="/agency/edit-opportunity/:id"
        exact
        component={EditOpportunity}
        user={['AGENCY', 'GUEST']}
      />
      <AuthWrapper
        path="/agency/view-applicants/:id"
        exact
        component={ApplicantList}
        user={['AGENCY', 'GUEST']}
      />
      <AuthWrapper
        path="/agency/account"
        exact
        component={MyAccount}
        user={['AGENCY', 'GUEST']}
      />
      <AuthWrapper
        path="/agency/billing-history"
        exact
        component={BillingHistory}
        user={['AGENCY', 'GUEST']}
      />

      <AuthWrapper
        path="/agency/invitations"
        exact
        component={Invitations}
        user={['AGENCY', 'GUEST']}
      />
      <AuthWrapper
        path="/agency/manage-team"
        exact
        component={ManageTeams}
        user={['AGENCY', 'GUEST']}
      />

      <Route
        path="/marketing/how-it-works"
        exact
        component={BlogIndividualMarketing}
      />
      <Elements stripe={stripePromise}>
        <AuthWrapper
          path="/agency/subscription"
          exact
          component={StartSubscription}
          user={['AGENCY', 'GUEST']}
        />
        <AuthWrapper
          path="/agency/manage-payment-info"
          exact
          component={ManagePaymentInfo}
          user={['AGENCY', 'GUEST']}
        />
        <AuthWrapper
          path="/agency/manage-credits"
          exact
          component={ManageCredits}
          user={['AGENCY', 'GUEST']}
        />
        <AuthWrapper
          path="/agency/resources"
          exact
          component={Blog}
          user={['AGENCY', 'GUEST']}
        />
      </Elements>
      <Route path="/404" exact component={NotFound} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
