import gql from 'graphql-tag';

export const GET_GENRES = gql`
  query Genres($name: String) {
    genres(name: $name) {
      id
      name
    }
  }
`;

export const GET_SKILLS = gql`
  query Skills($name: String) {
    skills(name: $name) {
      id
      name
    }
  }
`;

export const GET_AGENCY_OPPORTUNITIES = gql`
  query Opportunities(
    $agency: Int
    $trashed: Trashed
    $first: Int
    $where: OpportunitiesWhereWhereConditions
    $hasGenres: OpportunitiesHasGenresWhereConditions
    $hasSkills: OpportunitiesHasSkillsWhereConditions
    $orderBy: [OpportunitiesOrderByOrderByClause!]
  ) {
    opportunities(
      agency: $agency
      first: $first
      trashed: $trashed
      where: $where
      hasGenres: $hasGenres
      hasSkills: $hasSkills
      orderBy: $orderBy
    ) {
      paginatorInfo {
        count
        total
      }
      data {
        id
        title
        job_number
        view
        agency {
          id
          avatar
          company
        }
        genres {
          id
          name
        }
        skills {
          id
          name
        }
        type
        sub_type
        applicants {
          id
          user(active: true) {
            id
            name
            email
          }
          created_at
        }
        created_at
        updated_at
        featured
      }
    }
  }
`;

export const GET_OPPORTUNITY = gql`
  query Opportunity($id: ID!) {
    opportunity(id: $id) {
      id
      title
      job_number
      description
      is_on_site
      is_remote
      type
      sub_type
      level
      minimumBudget
      maximumBudget
      compensation_unit
      compensation_currency
      is_compensation_displayed
      follow_up
      is_public
      is_active
      duration
      is_ir35
      application_text
      application_link
      updated_by
      created_at
      genres {
        id
        name
      }
      skills {
        id
        name
      }
      agency {
        id
        company
        avatar
      }
      applicants {
        user(active: true) {
          id
          name
          email
          phone
        }
        created_at
      }
      featured
    }
  }
`;

export const GET_ALL_OPPORTUNITIES = gql`
  query Opportunities(
    $trashed: Trashed
    $orderBy: [AllOpportunitiesOrderByOrderByClause!]
    $where: AllOpportunitiesWhereWhereConditions
    $hasGenres: AllOpportunitiesHasGenresWhereConditions
    $hasSkills: AllOpportunitiesHasSkillsWhereConditions
  ) {
    allOpportunities(
      trashed: $trashed
      orderBy: $orderBy
      where: $where
      hasGenres: $hasGenres
      hasSkills: $hasSkills
    ) {
      id
      title
      job_number
      description
      type
      sub_type
      level
      agency {
        id
        avatar
        company
      }
      created_at
      genres {
        id
        name
      }
      skills {
        id
        name
      }
      featured
    }
  }
`;

export const GET_OPPORTUNITIES_FOR_USER = gql`
  query GetOpportunitiesForUser {
    me {
      preferences {
        id
        title
        job_number
        view
        agency {
          id
          avatar
          company
        }
        created_at
      }
    }
  }
`;
