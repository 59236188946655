import React, { useContext } from 'react';
import { AppContext } from '../../Store';
import { Link } from 'react-router-dom';
import { getUser } from '../../utils/helpers';

const AccountSubmenuMobile = () => {
  const [state, setState] = useContext(AppContext);

  return (
    <div className="account-submenu__mobile bg-light-agency">
      <div className="container">
        <div className="account-submenu__mobile__fixed bg-light-agency">
          <div className="row">
            <div className="col-9 account-submenu__mobile__heading">
              Account Settings
            </div>
            <div className="col-3 account-submenu__mobile__arrow">
              <i className="fas fa-chevron-down" />
            </div>
          </div>
        </div>
        <div className="account-submenu__mobile__content bg-light-agency">
          <div className="row">
            <ul className="account-submenu__list">
              <li className="account-submenu__list__item">
                <Link to="/agency/account" className="link-agency">
                  My Account
                </Link>
              </li>
              {getUser()?.type !== 'GUEST' && (
                <li className="account-submenu__list__item">
                  <Link to="/agency/profile" className="link-agency">
                    Company Information
                  </Link>
                </li>
              )}
              {/* <li className='account-submenu__list__item'>
                  <Link to='/agency/subscription' className='link-agency'>
                    Manage Subscription
                  </Link>
                </li> */}
              {state?.activeCompany?.role !== 'member' && (
                <li className="account-submenu__list__item">
                  <Link to="/agency/edit-profile" className="link-agency">
                    Edit Profile
                  </Link>
                </li>
              )}
              {state?.activeCompany?.role !== 'member' && (
                <>
                  <li className="account-submenu__list__item">
                    <Link
                      to="/agency/manage-payment-info"
                      className="link-agency"
                    >
                      Manage Payment Info
                    </Link>
                  </li>
                  {getUser().status === 'ACTIVE' && (
                    <li className="account-submenu__list__item">
                      <Link to="/agency/manage-credits" className="link-agency">
                        Purchase Job Posts
                      </Link>
                    </li>
                  )}
                  <li className="account-submenu__list__item">
                    <Link to="/agency/manage-team" className="link-agency">
                      Manage Team
                    </Link>
                  </li>
                  <li className="account-submenu__list__item">
                    <Link to="/agency/invitations" className="link-agency">
                      Invitations
                    </Link>
                  </li>
                  <li className="account-submenu__list__item">
                    <Link to="/agency/billing-history" className="link-agency">
                      Billing History
                    </Link>
                  </li>
                </>
              )}
              <li className="account-submenu__list__item">
                <Link to="/agency/change-password" className="link-agency">
                  Change password
                </Link>
              </li>

              <li className="account-submenu__list__item">
                <Link to="/agency/logout" className="link-agency">
                  Sign out
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSubmenuMobile;
