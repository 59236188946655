import React, { useState, useEffect, useContext } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Checkbox from 'react-custom-checkbox';
import { useLazyQuery } from '@apollo/client';
import { getUser } from '../../../utils/helpers';
import { AppContext } from '../../../Store';
import { toast } from 'react-toastify';
import {
  DELETE_TEAM_USER_ASSOCIATION,
  CREATE_TEAM_USER_ASSOCIATION,
  UPDATE_TEAM_USER_SETTING,
  GET_TEAM_ASSOCIATIONS,
} from '../../../graphql';
import './styles.css';

const Team = ({ members, data, ...props }) => {
  const [teamAssociations, setTeamAssociations] = useState([]);
  const [state, setState] = useContext(AppContext);
  const [memberList, setMemberList] = useState([]);

  /** Update Team User Setting */
  const [updateTeamUser, { loading }] = useMutation(UPDATE_TEAM_USER_SETTING);
  const updateTeamUserRole = (payload) => {
    toast.dismiss();
    updateTeamUser({
      variables: {
        input: {
          user_id: data.user_id,
          team_id: data.team_id,
          can_post_jobs: payload,
        },
      },
    })
      .then(() => {
        toast.success('User permission updated succesfully!', {
          autoClose: 4000,
        });
      })
      .catch(() =>
        toast.error('Could not update user permission!', { toastId: 'appErr' })
      );
  };
  /** Create New Team User  Association*/
  const [createTeamUserAssociation] = useMutation(
    CREATE_TEAM_USER_ASSOCIATION,
    {
      onCompleted: () => {
        getTeamUserAssociation({
          variables: {
            team_id: Number(state?.activeCompany?.team_id),
          },
        });
        toast.success('User association created succesfully!', {
          autoClose: 4000,
        });
      },
      onError: () =>
        toast.error('Error creating User Association', { toastId: 'appErr' }),
    }
  );

  /** Delete Team User  Association*/
  const [deleteTeamUserAssociation] = useMutation(
    DELETE_TEAM_USER_ASSOCIATION,
    {
      onCompleted: () => {
        getTeamUserAssociation({
          variables: {
            team_id: Number(state?.activeCompany?.team_id),
          },
        });
        toast.success('User association removed succesfully!', {
          autoClose: 4000,
        });
      },
      onError: () =>
        toast.error('Error removing User Association', { toastId: 'appErr' }),
    }
  );

  /** Retrieve Team User  Association */
  const [getTeamUserAssociation, { loading: teamLoader, data: teamData }] =
    useLazyQuery(GET_TEAM_ASSOCIATIONS, {
      variables: {
        team_id: Number(state?.activeCompany?.team_id),
      },
      fetchPolicy: 'network-only',
    });
  useEffect(() => {
    getTeamUserAssociation({ variables: { team_id: Number(getUser()?.id) } });
  }, []);
  useEffect(() => {
    setTeamAssociations(teamData?.viewUserTeamAssociation);
  }, [teamData]);

  useEffect(() => {
    if (members !== undefined) {
      const memberFilter = members.filter((a) => a.user_id !== data?.user_id);
      setMemberList(memberFilter);
    }
  }, [members]);

  return (
    <div
      style={{
        minHeight: 60,
      }}
    >
      <div
        className="container"
        style={{
          paddingTop: 20,
        }}
      >
        <div className="row offset-1">
          <div
            className="col-8"
            style={{
              borderRightColor: 'lightgray',
              borderRightStyle: 'solid',
              borderRightWidth: '1px',
            }}
          >
            <div
              style={{
                fontSize: '21px',
                fontWeight: '600',
                marginTop: 30,
                marginBottom: 30,
              }}
            >
              User Associations
            </div>
            <div>
              <input
                style={{
                  width: '220px',
                  marginBottom: '29px',
                  borderRadius: '5px',
                }}
                className="form__input__border-light invite-users"
                placeholder="search...."
                type="text"
                name="text"
                onChange={(e) => {
                  const filtered = memberList.filter((a) =>
                    String(a.first_name + ' ' + a.last_name)
                      .toLowerCase()
                      .includes(String(e.target.value).toLowerCase())
                  );
                  if (e.target.value.length > 0) {
                    setMemberList(filtered);
                  } else {
                    const memberFilter = members.filter(
                      (a) => a.user_id !== data?.user_id
                    );
                    setMemberList(memberFilter);
                  }
                }}
              />
            </div>
            <div>
              <table
                class="table"
                style={{
                  height: 'min-content',
                }}
              >
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">First</th>
                    <th scope="col">Last</th>
                  </tr>
                </thead>
                <tbody>
                  {memberList.length > 0 &&
                    memberList.map((item, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">
                            <Checkbox
                              name="associate_user"
                              checked={
                                teamAssociations?.find(
                                  (a) =>
                                    a.user_id === data.user_id &&
                                    item.user_id === a.associate_id
                                ) || false
                              }
                              onChange={(value) => {
                                if (value) {
                                  createTeamUserAssociation({
                                    variables: {
                                      input: {
                                        user_id: data.user_id,
                                        team_id: item.team_id,
                                        associate_id: item.user_id,
                                      },
                                    },
                                  });
                                } else {
                                  deleteTeamUserAssociation({
                                    variables: {
                                      input: {
                                        user_id: data.user_id,
                                        team_id: item.team_id,
                                        associate_id: item.user_id,
                                      },
                                    },
                                  });
                                }
                              }}
                              borderColor="#297b9f"
                              style={{ cursor: 'pointer' }}
                              labelStyle={{ marginLeft: 5, userSelect: 'none' }}
                              label=""
                            />
                          </th>
                          <td>
                            <img
                              src={
                                item?.avatar ||
                                '/img/profile-picture-placeholder@2x.jpg'
                              }
                              alt="Profile"
                              style={{
                                borderRadius: '50%',
                                height: '35px',
                                width: '35px',
                                marginLeft: '16px',
                              }}
                            />
                          </td>
                          <td>{item?.first_name}</td>
                          <td>{item?.last_name}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              {memberList.length === 0 && (
                <p
                  style={{
                    paddingTop: 15,
                  }}
                >
                  There are currently no team members
                </p>
              )}
            </div>
          </div>

          <div className="col-4">
            <div
              style={{
                fontSize: '21px',
                fontWeight: '600',
                marginTop: 30,
                marginBottom: 30,
              }}
            >
              User Permissions
            </div>
            <Checkbox
              name="can_post_opportunity"
              checked={data?.can_post_jobs}
              onChange={(value) => {
                if (!loading) updateTeamUserRole(value);
              }}
              borderColor="#297b9f"
              style={{ cursor: 'pointer' }}
              labelStyle={{ marginLeft: 5, userSelect: 'none' }}
              label="Can post jobs"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
