import React from 'react';
import { Link } from 'react-router-dom';

const AccountSubmenuMobile = () => (
  <div className="account-submenu__mobile bg-light-freelancer">
    <div className="container">
      <div className="account-submenu__mobile__fixed bg-light-freelancer">
        <div className="row">
          <div className="col-9 account-submenu__mobile__heading">
            Account Settings
          </div>
          <div className="col-3 account-submenu__mobile__arrow txt-freelancer">
            <i className="fas fa-chevron-down" />
          </div>
        </div>
      </div>
      <div className="account-submenu__mobile__content bg-light-freelancer">
        <div className="row">
          <ul className="account-submenu__list">
            <li className="account-submenu__list__item">
              <Link
                to="/freelancer/personal-information"
                className="link-freelancer"
              >
                Personal Information
              </Link>
            </li>
            <li className="account-submenu__list__item">
              <Link
                to="/freelancer/edit-personal-information"
                className="link-freelancer"
              >
                Edit Personal Information
              </Link>
            </li>
            <li className="account-submenu__list__item">
              <Link to="/freelancer/profile" className="link-freelancer">
                View Profile
              </Link>
            </li>
            <li className="account-submenu__list__item">
              <Link to="/freelancer/manage-cv" className="link-freelancer">
                Edit Profile
              </Link>
            </li>
            <li className="account-submenu__list__item">
              <Link
                to="/freelancer/notification-settings"
                className="link-freelancer"
              >
                Notification Settings
              </Link>
            </li>
            <li className="account-submenu__list__item">
              <Link
                to="/freelancer/profile-privacy"
                className="link-freelancer"
              >
                Profile Privacy
              </Link>
            </li>
            <li className="account-submenu__list__item">
              <Link
                to="/freelancer/change-password"
                className="link-freelancer"
              >
                Change password
              </Link>
            </li>
            <li className="account-submenu__list__item">
              <Link to="/freelancer/logout" className="link-freelancer">
                Log Out
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
);

export default AccountSubmenuMobile;
