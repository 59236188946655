import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import Footer from '../../components/Footer';
import jquery from '../../styles/js/app';
import { GET_ARTICLES } from '../../graphql';
import { clone } from '../../utils/helpers';
import Header from '../../components/Agency/Header';

const Blog = () => {
  useEffect(() => {
    jquery();
  }, []);
  const {
    data: {
      articles: { data = [], paginatorInfo: { hasMorePages, count } = {} } = {},
    } = {},
    fetchMore,
    loading,
    refetch,
  } = useQuery(GET_ARTICLES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      availability: ['AGENCY', 'FREELANCER_AND_AGENCY'],
      orderBy: { field: { field: 'CREATED_AT', order: 'DESC' } },
      first: 10,
    },
    onError: () => refetch(),
  });

  const getMore = (e) => {
    e.preventDefault();
    fetchMore({
      variables: {
        first: count + 10,
      },
      updateQuery: (prev, newQuery) => (newQuery.fetchMoreResult ? newQuery.fetchMoreResult : prev),
    });
  };

  const BlogpostLarge = () => (
    <div className="col-12">
      {data.length > 0 && (
        <Link
          className="blog__banner__heading d-block px-0 txt-agency"
          to={`/agency/articles/${data[0].slug}`}
        >
          <div className="row blog__banner mx-0">
            <div className="col-lg-8 px-0">
              <img
                src={data[0].image || '/img/blog-banner-img.png'}
                className="blog__banner__img"
                alt=""
              />
            </div>
            <div className="col-lg-4 px-0">{data[0].title}</div>
          </div>
        </Link>
      )}
    </div>
  );

  const BlogPosts = () => {
    if (data.length > 0) {
      const restPosts = clone(data);
      restPosts.shift();
      return restPosts.map(({
        title, image, slug, created_at,
      }) => (
        <div className="col-lg-4 col-md-6" id={created_at}>
          <Link
            to={`/agency/articles/${slug}`}
            className="link-agency mb-40 d-block font-weight-bold"
          >
            <img
              src={image || '/img/cta-placeholder@2x.jpg'}
              className="blog__img"
              alt=""
            />
            {title}
          </Link>
        </div>
      ));
    }
    return null;
  };

  return (
    <div className="lh-wrapper">
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <h1 className="mb-20">Blog</h1>
          </div>
          <BlogpostLarge />
          <BlogPosts />
          {hasMorePages && (
            <div className="col-12">
              <a
                href="/#"
                className="form__btn d-block"
                onClick={(e) => getMore(e)}
              >
                {loading ? 'Loading...' : 'Show More'}
              </a>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blog;
