import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useLazyQuery } from '@apollo/react-hooks';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import Header from '../../components/Agency/Header';
import Footer from '../../components/Footer';
import {
  CHECK_REGISTERABLE_USER,
  PROCESS_INVITATION,
  CREATE_TEAM_USER,
} from '../../graphql';
import jquery from '../../styles/js/app';
import Error from '../../components/Error';
import { registerVar } from '../../graphql/config';

const CreateAccount = ({ history }) => {
  useEffect(() => {
    jquery();
  }, []);
  const location = useLocation();
  const { register, handleSubmit, errors } = useForm();
  const [invitationEmail, setInvitationEmail] = useState(undefined);
  const [invitationToken, setInvitationToken] = useState(undefined);
  /** Set Off Route Params */
  useEffect(() => {
    setInvitationEmail(String(location.pathname).split('&')[1]);
    setInvitationToken(String(location.pathname).split('/')[3].split('&')[0]);
  }, [location]);
  /** Check User Exist*/
  useEffect(() => {
    if (invitationEmail !== undefined) {
      check({ variables: { email: invitationEmail } });
    }
  }, [invitationEmail]);

  const [acceptInvite] = useMutation(PROCESS_INVITATION);
  const acceptInvitation = () => {
    toast.dismiss();
    acceptInvite({
      variables: {
        email: invitationEmail,
        token: invitationToken,
      },
    })
      .then((res) => {
        const { data } = res;
        if (data?.processInvitation?.team_id) {
          const cookies = new Cookies();
          cookies.set('lh_team', data?.processInvitation?.team_id, {
            path: '/',
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
          });
        }
        history.push({
          pathname: '/team/welcome',
          state: {
            mode: 'Invitation_Accepted',
          },
        });
        toast.success('Invitation accepted successfully', { autoClose: 4000 });
      })
      .catch((e) =>
        toast.error('Could not accept invite', { toastId: 'appErr' })
      );
  };

  const [check, { data, refetch }] = useLazyQuery(CHECK_REGISTERABLE_USER, {
    onCompleted: () => isUniqueEmail({ showFeedback: true }),
    onError: () => refetch(),
  });

  const isUniqueEmail = () => {
    toast.dismiss();
    if (
      data?.checkRegisterableUser !== null &&
      data?.checkRegisterableUser !== undefined &&
      data?.checkRegisterableUser?.type !== 'FREELANCER'
    ) {
      addExistingUser();
    }
  };

  /** Create New Team User */
  const [createTeamUser] = useMutation(CREATE_TEAM_USER);

  const addExistingUser = () => {
    toast.dismiss();
    createTeamUser({
      variables: {
        input: {
          email: invitationEmail,
          token: invitationToken,
        },
      },
    })
      .then((res) => {
        const { data } = res;
        if (data?.createTeamUser?.team_id) {
          const cookies = new Cookies();
          cookies.set('lh_team', data?.createTeamUser?.team_id, {
            path: '/',
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
          });
        }
        acceptInvitation();
      })
      .catch(() =>
        toast.error('Could not create team account', { toastId: 'appErr' })
      );
  };

  // const openInNewTab = (url) => {
  //   localStorage.setItem('userType', 'agency');
  //   window.location.replace(url);
  // };

  // if (userVar().access_token) {
  //   return (
  //     <Redirect
  //       to={{
  //         pathname: '/agency/dashboard',
  //         state: { referrer: '/agency/register' },
  //       }}
  //     />
  //   );
  // }

  const CheckEmail = (input) => {
    registerVar({
      token: invitationToken,
      email: invitationEmail,
      password: input?.password,
    });
    return history.push({
      pathname: '/team/profile/register',
      state: {
        token: invitationToken,
        email: invitationEmail,
      },
    });
  };

  return (
    <>
      <div className="lh-wrapper">
        <Header />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="onboarding">
                <h1 className="center">Create A Team Account</h1>
                <div className="pagination-bubble">
                  <div className="pagination-bubble__item pagination-bubble__active">
                    1
                  </div>
                  <div className="pagination-bubble__item bg-agency">2</div>
                  <div className="pagination-bubble__item bg-agency">3</div>
                </div>
                <div>Login information</div>
                <form className="form" onSubmit={handleSubmit(CheckEmail)}>
                  <div className="form__input-block">
                    <div className="form__label">Email</div>
                    <div className="form__input-wrapper">
                      <input
                        className="form__input"
                        type="text"
                        name="email"
                        value={invitationEmail}
                        defaultValue={invitationEmail}
                        ref={register({
                          required: true,
                          pattern:
                            /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        })}
                        autoComplete="false"
                        disabled
                      />
                      <div className="form__clear form__hidden">
                        <i className="fas fa-times" />
                      </div>
                    </div>
                  </div>
                  {errors.email && (
                    <Error text="Please enter a valid email address" />
                  )}
                  <div className="form__input-block">
                    <div className="form__label">Password</div>
                    <div className="form__input-wrapper">
                      <input
                        className="form__input"
                        type="password"
                        id="onboarding-password"
                        name="password"
                        defaultValue={registerVar().password || ''}
                        ref={register({ required: true, minLength: 8 })}
                        autoComplete="false"
                      />
                      <div className="form__clear form__hidden">
                        <i className="fas fa-times" />
                      </div>
                      <div
                        toggle="#onboarding-password"
                        className="fas fa-eye form__toggle-password form__hidden"
                      />
                    </div>
                  </div>
                  {errors.password && (
                    <Error text="Password must be at least 8 characters" />
                  )}
                  <button type="submit" className="form__btn btn d-block">
                    {'Create An Account'}
                  </button>
                  <p className="form__text">or</p>
                  <div
                    // onClick={() => {
                    //   openInNewTab(
                    //     `${process.env.REACT_APP_API_URL}/redirect/google`,
                    //   );
                    // }}
                    className="form__btn-outline"
                  >
                    Sign Up With Google
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CreateAccount;
