import Cookies from 'universal-cookie';
import { userVar } from '../../graphql/config';
import { logUserOut } from '../../utils/helpers';

const Logout = ({ history }) => {
  logUserOut();
  userVar({});
  const cookies = new Cookies();
  cookies.remove('lh_user', {
    path: '/',
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
  });
  history.push('/freelancer/login');
  return null;
};

export default Logout;
