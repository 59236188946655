import React, { useEffect, useState, useContext } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import moment from 'moment';
import countryList from 'country-list';
import Header from '../../components/Agency/Header';
import Footer from '../../components/Footer';
import Error from '../../components/Error';
import UpdatePaymentInfoModal from '../../components/Agency/UpdatePaymentInfoModal';
import jquery from '../../styles/js/app';
import AccountSubmenuDesktop from '../../components/Agency/AccountSubmenuDesktop';
import AccountSubmenuMobile from '../../components/Agency/AccountSubmenuMobile';
import {
  GET_AUTH_USER,
  GET_CREDIT_HISTORY,
  ADD_CREDIT,
  CHECK_PROMO,
  CALCULATE_PRICE,
  UPDATE_PROFILE,
} from '../../graphql';
import { userVar } from '../../graphql/config';
import {
  getUser,
  getLoginUrl,
  logUserOut,
  saveUserLocalstorage,
} from '../../utils/helpers';
import { AppContext } from '../../Store';

const ManageCredits = () => {
  const countries = countryList.getNames().sort();
  const [state, setState] = useContext(AppContext);
  const [totals, setTotals] = React.useState({
    unitPriceExcludingDiscount: 495,
    discountPercentage: 0,
    discountAmount: 0,
    discountPerUnitAmount: 0,
    unitPriceIncludingDiscount: 495,
    subtotalExcludingDiscount: 495,
    subtotalIncludingDiscount: 495,
    vatAmount: 59,
    vatPercentage: 0.2,
    total: 554,
  });
  const [promoError, setPromoError] = useState('');
  const [promoValue, setPromoValue] = useState(0);
  const [balance, setBalance] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [hasCard, setHasCard] = useState(false);
  const [submittedCard, setSubmittedCard] = useState(false);
  const [promoPercentage, setPromoPercentage] = useState(0);
  const [showPaymentForm, setShowPaymentForm] = useState(false);

  const { register, handleSubmit, getValues, setValue, errors, reset } =
    useForm({
      defaultValues: {
        qty: 1,
        promo: '',
      },
      send_to_xero: 0,
    });

  const clearForm = () => {
    reset();
  };

  const [updateProfile, { loading: loadingUpdateProfile }] =
    useMutation(UPDATE_PROFILE);

  const {
    data: userDetail,
    refetch: refetchUser,
    loading: loadingUser,
  } = useQuery(GET_AUTH_USER, {
    fetchPolicy: 'network-only',
    onCompleted: ({ me }) => {
      if (!me) {
        logUserOut();
        window.location.href = getLoginUrl('AGENCY');
      }
      if (me?.type === 'AGENCY') {
        if (me.card_brand && me.card_last_four) {
          setHasCard(true);
        }
      } else if (me?.type === 'GUEST' && state?.activeCompany !== undefined) {
        const { user } = state?.activeCompany;
        if (user !== undefined && user?.card_brand && user?.card_last_four) {
          setHasCard(true);
        }
      }
    },
    onError: () => refetchUser(),
  });

  const [addCredit] = useMutation(ADD_CREDIT);
  const [checkPromoMutation] = useMutation(CHECK_PROMO);
  const [calculatePrice, { loading: calculatePriceIsLoading }] = useMutation(
    CALCULATE_PRICE,
    {
      onCompleted: ({ calculatePrice: stuff }) => {
        if (promoPercentage) {
          setPromoValue(
            (promoPercentage / 100) * stuff.subtotalIncludingDiscount
          );
        }
        setTotals({ ...stuff });
      },
    }
  );

  const {
    loading,
    data,
    refetch: refetchCredit,
  } = useQuery(GET_CREDIT_HISTORY, {
    fetchPolicy: 'network-only',
    variables: {
      orderBy: {
        field: { field: 'CREATED_AT', order: 'DESC' },
      },
      agency: state?.activeCompany?.team_id,
    },
    onCompleted: (data) => {
      if (data.creditHistory.length > 0) {
        setBalance(data.creditHistory[0].balance);
      } else {
        setBalance(0);
      }
    },
    // onError: () => refetchCredit(),
  });

  const showError = (message) => {
    toast.error(message, { toastId: 'buyCredits' });
  };

  useEffect(() => {
    refetchUser();

    if (state?.activeCompany) {
      refetchCredit();
    }
  }, [state?.activeCompany]);

  const addCreditRequest = (data) => {
    setButtonLoading(true);
    return addCredit({
      variables: {
        input: {
          ...data,
        },
      },
    })
      .then(({ data }) => {
        setBalance(data.addCredit.balance);
        refetchCredit();
        setValue('qty', 1);
        checkPrice();
        toast.dismiss();
        toast.success('Credits added!', { autoClose: 4000 });
        setButtonLoading(false);
      })
      .catch((e) => {
        toast.dismiss();
        showError(e.graphQLErrors[0].extensions.reason);
        setButtonLoading(false);
      });
  };

  const submitInvoiceForm = async (data) => {
    toast.dismiss();

    return updateProfile({
      variables: {
        input: {
          ...data,
        },
      },
    })
      .then(({ data: { updateUser } }) => {
        const updatedUserObj = { ...getUser(), ...updateUser };
        userVar(updatedUserObj);
        saveUserLocalstorage(updatedUserObj);
        toast.success('Your data has been submitted!', {
          autoClose: 2000,
        });
        clearForm();
        togglePaymentForm();
      })
      .catch((e) =>
        toast.error('Something went wrong!', { toastId: 'resErr' })
      );
  };

  const downloadInvoice = (invoiceId) => {
    const url = `${process.env.REACT_APP_API_URL}/api/user/${
      getUser().id
    }/invoice/${invoiceId}`;
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const maxPostsCheck = (e) => {
    const qty = getValues('qty');
    if (e.target.value > 10) {
      e.target.value = 10;
    }
  };

  const checkPrice = async () => {
    const qty = getValues('qty');
    const promo = getValues('promo');
    if (qty !== '') {
      calculatePrice({
        variables: { qty, promo },
      });
    }
  };

  const applyPromo = async () => {
    if (!getValues('promo')) {
      setPromoError('');
      return;
    }

    const {
      data: { checkPromo },
    } = await checkPromoMutation({
      variables: {
        code: getValues('promo'),
      },
    });

    if (checkPromo === null) {
      setPromoError("Promo code doesn't exist!");
    } else {
      await checkPrice();

      let promoTotal = 0;
      if (checkPromo.type === 'percentage') {
        promoTotal =
          (checkPromo.value / 100) * totals.subtotalIncludingDiscount;
      }
      setPromoPercentage(checkPromo.value);
      setPromoValue(promoTotal);
      setPromoError('');
      toast.success('Promo applied!', {
        autoClose: 2000,
      });
    }
  };

  useEffect(() => {
    jquery();
    if (userDetail?.me?.is_payment_active) {
      checkPrice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail]);

  const changeButton = async () => {
    setSubmittedCard(true);
  };

  const displayPurchaseCredit = () => {
    return (
      <>
        <UpdatePaymentInfoModal onClose={changeButton} />

        <div className="row">
          <div className="col-12">
            <h1>Your Available Liberty Hive Job Posts</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-9">
            <div className="available-credits">
              {loading ? (
                <>
                  <div className="available-credits__heading" />
                  <div className="available-credits__subheading">
                    Loading Job Posts balance...
                  </div>
                </>
              ) : (
                <>
                  <div className="available-credits__heading">
                    {balance} Job Post
                    {balance > 1 ? 's' : ''}
                  </div>
                  <div className="available-credits__subheading">Available</div>
                </>
              )}
            </div>
            <div className="mt-40 mb-40">
              <h2>Pricing</h2>
              <p>
                We tailor all our packages to suit your resource needs so please
                get in touch for a tailored subscription package which is based
                on a zero-commission model.
                <br />
                If you prefer to pay as you go, the cost is £495 to post and a
                zero commission fee for successful hires, no matter whether it
                is permanent or freelance. You can buy the job posts online or
                you can apply for an account to be invoiced directly.
              </p>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="credits-calculator">
                  <div className="credits-calculator__heading">
                    How Many Posts Do You Need?
                  </div>
                  <input
                    type="number"
                    min={1}
                    max={10}
                    className="credits-calculator__input"
                    name="qty"
                    ref={register({
                      required: true,
                      min: 1,
                      max: 10,
                    })}
                    onInput={maxPostsCheck}
                    onChange={checkPrice}
                  />
                  <div className="credits-calculator__subheading">Per Post</div>
                  <div className="credits-calculator__body">
                    &pound; {totals.unitPriceIncludingDiscount.toFixed(2)}
                  </div>
                  {getValues('qty') > 1 && (
                    <>
                      <div className="credits-calculator__subheading">
                        Discount
                      </div>
                      <div className="credits-calculator__body">
                        -&pound;
                        {totals.discountPerUnitAmount.toFixed(2)}{' '}
                        <span className="credits-calculator__body__italic">
                          (-
                          {Math.round(totals.discountPercentage * 100)}
                          %)
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-6 credits-cart">
                <form onSubmit={handleSubmit(addCreditRequest)}>
                  <div className="credits-cart__promotion">
                    <div className="credits-cart__promotion__heading">
                      Have a promotion code?
                    </div>
                    <div className="credits-cart__promotion__input-area">
                      <input
                        className="credits-cart__promotion__input"
                        type="text"
                        name="promo"
                        ref={register({
                          required: false,
                        })}
                        onChange={(e) => {
                          if (e.target.value.length === 0) {
                            setPromoError('');
                          }
                        }}
                      />
                      <div className="credits-cart__promotion__apply">
                        <button type="button" onClick={applyPromo}>
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                  {promoError !== '' && (
                    <>
                      <Error className="mb-20" text={promoError} />
                      <br />
                    </>
                  )}

                  <div className="credits-cart__cost-breakdown">
                    <div className="credits-cart__cost-breakdown__heading">
                      Job Posts Added
                    </div>
                    <div className="credits-cart__cost-breakdown__content">
                      {getValues('qty') ?? 1}
                    </div>
                  </div>

                  <div
                    className={`credits-cart__cost-breakdown ${
                      parseInt(getValues('qty')) === 1 ? 'bb-0 mb-0 pb-0' : ''
                    }`}
                  >
                    <div className="credits-cart__cost-breakdown__heading">
                      Subtotal
                    </div>
                    <div className="credits-cart__cost-breakdown__content">
                      &pound; {totals.subtotalExcludingDiscount.toFixed(2)}
                    </div>
                  </div>

                  {getValues('qty') > 2 && (
                    <div className="credits-cart__cost-breakdown bb-0 mb-0 pb-0">
                      <div className="credits-cart__cost-breakdown__heading">
                        Discounts Applied
                      </div>
                      <div className="credits-cart__cost-breakdown__content">
                        -&nbsp;&pound; {totals.discountAmount.toFixed(2)}
                      </div>
                    </div>
                  )}

                  {getValues('promo') !== '' &&
                    getValues('promo') !== undefined &&
                    promoError === '' && (
                      <div className="credits-cart__promo-discount">
                        <div className="credits-cart__promo-discount__heading">
                          Promo Code:&nbsp;
                          <span className="promo-code-used">
                            {getValues('promo').toUpperCase()}
                          </span>
                        </div>
                        <div className="credits-cart__promo-discount__content">
                          -&nbsp;&pound; {promoValue.toFixed(2)}
                        </div>
                      </div>
                    )}

                  <div className="credits-cart__cost-breakdown bb-0 mb-0">
                    <div className="credits-cart__cost-breakdown__heading">
                      VAT @ {(totals.vatPercentage * 100).toString()}%
                    </div>
                    <div className="credits-cart__cost-breakdown__content">
                      &pound; {totals.vatAmount.toFixed(2)}
                    </div>
                  </div>

                  <div className="credits-cart__total">
                    <div className="credits-cart__total__heading">Total</div>
                    <div className="credits-cart__total__content">
                      &pound; {totals.total.toFixed(2)}
                    </div>
                  </div>

                  <div className="credits-cart__payment">
                    {hasCard ? (
                      <>
                        <button
                          type="submit"
                          disabled={buttonLoading || calculatePriceIsLoading}
                          className="btn pay-with-card"
                        >
                          {buttonLoading ? 'Loading... ' : 'Payment Online'}
                        </button>
                      </>
                    ) : (
                      <>
                        {submittedCard ? (
                          <button
                            type="submit"
                            disabled={buttonLoading || calculatePriceIsLoading}
                            className="btn pay-with-card"
                          >
                            {buttonLoading ? 'Loading... ' : 'PAY'}
                          </button>
                        ) : (
                          <div
                            className="btn pay-with-card"
                            data-toggle="modal"
                            data-target="#update_info_modal"
                          >
                            Add Payment Info
                          </div>
                        )}
                      </>
                    )}

                    {userDetail?.me?.is_payment_active ? (
                      <button
                        type="button"
                        className="btn pay-with-card mt-3"
                        onClick={payWithAccount}
                        disabled={buttonLoading || calculatePriceIsLoading}
                      >
                        {buttonLoading ? 'Loading... ' : 'Purchase on Account'}
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn pay-with-card mt-3"
                        onClick={togglePaymentForm}
                      >
                        Apply for Credit Account
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
            <h2 className="mb-20">Your Balance</h2>
            <div className="grid grid__credits">
              {loading ? (
                'Loading Job Posts history...'
              ) : (
                <>
                  <span className="grid-heading credits-in">Job Posts In</span>
                  <span className="grid-heading credits-out">
                    Job Posts Out
                  </span>
                  <span className="grid-heading amount desktop">Amount</span>
                  <span className="grid-heading date">Date</span>
                  <span className="grid-heading invoice tablet">Invoice</span>
                  {data &&
                    data.creditHistory.map(
                      ({ type, qty, total, invoice_id, created_at }) => (
                        <>
                          <span className="grid-content credits-in">
                            {type === 'IN'
                              ? `${qty} ${total === 0 ? 'Free' : ''} Post${
                                  qty > 1 ? 's' : ''
                                }`
                              : '-'}
                          </span>
                          <span className="grid-content credits-out">
                            {type === 'OUT'
                              ? `${qty} Post${qty > 1 ? 's' : ''}`
                              : '-'}
                          </span>
                          <span className="grid-content amount desktop">
                            {total > 0 && (
                              <span>&pound; {total.toFixed(2)}</span>
                            )}
                          </span>
                          <span className="grid-content date">
                            {moment(created_at).format('DD/MM/YYYY')}
                          </span>
                          <span className="grid-content invoice tablet">
                            {total > 0 && (
                              <button
                                type="button"
                                onClick={() => {
                                  downloadInvoice(invoice_id);
                                }}
                                className="btn link-agency font-weight-bold"
                              >
                                Download PDF
                              </button>
                            )}
                          </span>
                        </>
                      )
                    )}
                </>
              )}
            </div>
          </div>
          <AccountSubmenuDesktop />
        </div>
      </>
    );
  };

  const displayPaymentForm = () => {
    return (
      <>
        <div className="row">
          <div className="col-12 col-lg-9">
            <p>
              If you wish to purchase posts on account, please complete the form
              below. Once approved by Liberty Hive, you will be authorised to
              purchase posts and invoiced accordingly. All invoices are due 30
              days after the date of the invoice, by requesting an account you
              accept these terms in full and without exception.
            </p>
          </div>
        </div>

        <form onSubmit={handleSubmit(submitInvoiceForm)}>
          <div className="row mt-4">
            <div className="col-12 col-lg-6">
              <div className="profile-bio-row row">
                <div className="col-12 add-opportunity-row__title">
                  <span className="txt__fw-600">Company Name</span>
                </div>
                <div className="col-12 profile-bio-row__content">
                  <input
                    className="form__input__border-light"
                    type="text"
                    name="invoice_company"
                    ref={register({ required: true })}
                  />
                </div>
              </div>
              {errors.invoice_company && (
                <Error text="This field is required" noMargin />
              )}
              <div className="profile-bio-row row">
                <div className="col-12 add-opportunity-row__title">
                  <span className="txt__fw-600">Address Line 1</span>
                </div>
                <div className="col-12 profile-bio-row__content">
                  <textarea
                    rows="3"
                    className="form__textarea__border-light height__unset form__edit-profile-textarea"
                    name="invoice_address_1"
                    ref={register({ required: true })}
                  />
                </div>
              </div>
              {errors.invoice_address_1 && (
                <Error text="This field is required" noMargin />
              )}
              <div className="profile-bio-row row">
                <div className="col-12 add-opportunity-row__title">
                  <span className="txt__fw-600">Address Line 2</span>
                </div>
                <div className="col-12 profile-bio-row__content">
                  <textarea
                    rows="3"
                    className="form__textarea__border-light height__unset form__edit-profile-textarea"
                    name="invoice_address_2"
                    ref={register({ required: false })}
                  />
                </div>
              </div>
              <div className="profile-bio-row row">
                <div className="col-12 add-opportunity-row__title">
                  <span className="txt__fw-600">City</span>
                </div>
                <div className="col-12 profile-bio-row__content">
                  <input
                    className="form__input__border-light"
                    type="text"
                    name="invoice_city"
                    ref={register({ required: true })}
                  />
                </div>
              </div>
              {errors.invoice_city && (
                <Error text="This field is required" noMargin />
              )}
              <div className="profile-bio-row row">
                <div className="col-12 add-opportunity-row__title">
                  <span className="txt__fw-600">County</span>
                </div>
                <div className="col-12 profile-bio-row__content">
                  <input
                    className="form__input__border-light"
                    type="text"
                    name="invoice_state"
                    ref={register({ required: true })}
                  />
                </div>
              </div>
              {errors.invoice_state && (
                <Error text="This field is required" noMargin />
              )}
              <div className="profile-bio-row row">
                <div className="col-12 add-opportunity-row__title">
                  <span className="txt__fw-600">Country</span>
                </div>
                <div className="col-12 profile-bio-row__content">
                  <select
                    className="form__input form__country"
                    name="invoice_country"
                    list="countries"
                    id="country"
                    required
                    defaultValue="United Kingdom"
                    ref={register({ required: true })}
                  >
                    {countries.map((country) => (
                      <option
                        value={
                          country ===
                          'United Kingdom of Great Britain and Northern Ireland'
                            ? 'United Kingdom'
                            : country
                        }
                        key={
                          country ===
                          'United Kingdom of Great Britain and Northern Ireland'
                            ? 'United Kingdom'
                            : country
                        }
                      >
                        {country ===
                        'United Kingdom of Great Britain and Northern Ireland'
                          ? 'United Kingdom'
                          : country}
                      </option>
                    ))}
                  </select>
                  <div className="form__clear form__hidden">
                    <i className="fas fa-times" />
                  </div>
                </div>
              </div>
              {errors.invoice_country && (
                <Error text="This field is required" noMargin />
              )}
              <div className="profile-bio-row row">
                <div className="col-12 add-opportunity-row__title">
                  <span className="txt__fw-600">Post code</span>
                </div>
                <div className="col-12 profile-bio-row__content">
                  <input
                    className="form__input__border-light"
                    type="text"
                    name="invoice_zip"
                    ref={register({ required: true })}
                  />
                </div>
              </div>
              {errors.invoice_zip && (
                <Error text="This field is required" noMargin />
              )}
              <div className="profile-bio-row row">
                <div className="col-12 add-opportunity-row__title">
                  <span className="txt__fw-600">
                    Accounts payable contact telephone
                  </span>
                </div>
                <div className="col-12 profile-bio-row__content">
                  <input
                    className="form__input__border-light"
                    type="text"
                    name="invoice_phone"
                    ref={register({
                      required: true,
                      pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
                    })}
                  />
                </div>
              </div>
              {errors.invoice_phone && (
                <Error text="Please enter a valid phone number" noMargin />
              )}
              <div className="profile-bio-row row">
                <div className="col-12 add-opportunity-row__title">
                  <span className="txt__fw-600">
                    Accounts payable contact email
                  </span>
                </div>
                <div className="col-12 profile-bio-row__content">
                  <input
                    className="form__input__border-light"
                    type="email"
                    name="invoice_email"
                    ref={register({
                      required: true,
                      pattern:
                        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    })}
                  />
                </div>
              </div>
              {errors.invoice_email && (
                <Error text="Please enter a valid email" noMargin />
              )}
              <div className="profile-bio-row row">
                <button
                  type="submit"
                  className="col-8 form__btn d-block mb-50"
                  disabled={loading}
                >
                  {loadingUpdateProfile
                    ? 'Loading...'
                    : 'Send Approval Request'}
                </button>

                <a
                  href="/#"
                  className="offset-2 col-2 link-agency"
                  style={{ padding: '62px 0', cursor: 'pointer' }}
                  onClick={togglePaymentForm}
                >
                  Cancel
                </a>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  };

  const togglePaymentForm = () => {
    setShowPaymentForm(!showPaymentForm);
  };

  const payWithAccount = () => {
    const data = getValues();
    data.send_to_xero = 1;
    addCreditRequest(data);
  };

  return (
    <>
      <div className="lh-wrapper">
        <Header isLoggedIn />
        <AccountSubmenuMobile />
        <div className="container">
          {loadingUser ? (
            <div>Loading...</div>
          ) : (
            <>
              {showPaymentForm ? displayPaymentForm() : displayPurchaseCredit()}
            </>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ManageCredits;
