import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { SEND_VERIFICATION_CODE } from '../../../../graphql';
import Error from '../../../Error';
import { inputProps, inputStyle } from '../../../../utils/helpers';

const SendVerificationModal = ({ onSubmitCallback, onCloseCallback }) => {
  const { register, handleSubmit, errors } = useForm();
  const [phoneError, setPhoneError] = useState(false);
  const [phone, setPhoneNo] = useState(null);
  const [sendVerificationCode] = useMutation(SEND_VERIFICATION_CODE);

  const customStyles = {
    content: {
      top: '35%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      paddingLeft: 51,
      paddingRight: 51,
      borderRadius: 0,
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
  };

  const validatePhone = (phone) => {
    if (!/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(phone)) {
      setPhoneError(true);
      return true;
    }
    setPhoneError(false);
    return false;
  };

  function VerifyPhoneNumber() {
    validatePhone(phone);
    if (!phoneError && phone !== null && phone !== '') {
      sendVerificationCode({
        variables: {
          phone,
        },
      })
        .then(() => {
          toast.success("We've sent you a verification code!", {
            autoClose: 2000,
          });
          onSubmitCallback();
        })
        .catch(() => {
          setPhoneError(true);
          toast.error('Something went wrong.', { autoClose: 5000 });
        });
    }
  }

  const handleEnterKey = (event) => {
    if (event.key === 'Enter') {
      return handleSubmit(VerifyPhoneNumber)();
    }
  };

  // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
  Modal.setAppElement('#root');

  return (
    <Modal isOpen={true} style={customStyles} contentLabel="Example Modal">
      <form
        className="form"
        onSubmit={handleSubmit(VerifyPhoneNumber)}
        style={{
          width: '100%',
        }}
      >
        <div
          style={{
            minWidth: '395px',
            minHeight: '250px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              flex: 7,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src="/img/close.png"
              style={{
                width: 12,
                height: 12,
                position: 'absolute',
                top: 20,
                right: 20,
              }}
              alt="close"
              onClick={() => onCloseCallback()}
            />
            <span
              style={{
                fontWeight: '600',
                fontSize: '20px',
                lineHeight: '26px',
                textAlign: 'center',
              }}
            >
              SMS Notification
            </span>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flex: 0.5,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              paddingRight: '5%',
            }}
          >
            <span
              style={{
                fontWeight: '300',
                fontSize: '16px',
                lineHeight: '26px',
                textAlign: 'left',
              }}
            >
              Please enter the number where you'd like to receive notifications.
            </span>
          </div>
          <div
            style={{
              width: '90%',
              left: '-10%',
              display: 'flex',
              flex: 0.7,
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              alignItems: 'flex-start',
              alignSelf: 'flex-start',
            }}
          >
            <PhoneInput
              country="gb"
              autoFormat
              inputProps={inputProps}
              inputClass="form__input"
              containerClass="form__input-wrapper"
              inputStyle={inputStyle}
              buttonStyle={{
                border: 'none',
              }}
              // isValid={(value) => validatePhone(value)}
              onChange={(value) => {
                setPhoneNo(value);
              }}
              placeholder="44 116 456 5566"
              onKeyDown={handleEnterKey}
            />
            <div className="form__clear form__hidden">
              <i className="fas fa-times" />
            </div>
            {phoneError && (
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  marginTop: '30px',
                }}
              >
                <Error text="Please enter a valid phone number" />
              </div>
            )}
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flex: 2,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <button
              type="submit"
              className="form__btn form__edit-profile bg-freelancer d-block mb-50"
              style={{
                width: 300,
                height: 48,
              }}
            >
              <span
                style={{
                  fontWeight: 700,
                  fontSize: '16px',
                  lineHeight: '24px',
                  letterSpacing: '5%',
                }}
              >
                SEND VERIFICATION
              </span>
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default SendVerificationModal;
