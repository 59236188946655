/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { getUser } from '../../utils/helpers';
import { GET_LANDING_PAGES } from '../../graphql';
import { useMediaPredicate } from 'react-media-hook';

const Footer = () => {
  const { data = { landingPages: [] } } = useQuery(GET_LANDING_PAGES);
  const { landingPages = [] } = data;

  const [hasLandingPages, setHasLandingPages] = useState(false);
  const [filteredLandingPages, setFilteredLandingPages] = useState([]);

  /** Checks Current ScreenWidth */
  const smallerThan992 = useMediaPredicate('(max-width: 992px)');

  useEffect(() => {
    if (landingPages !== undefined && landingPages.length > 0) {
      const present = landingPages.find((o) => o?.display_in_footer === 1);
      if (present !== undefined) {
        setHasLandingPages(true);
        setFilteredLandingPages(
          landingPages.filter((o) => o?.display_in_footer === 1)
        );
      } else {
        setHasLandingPages(false);
      }
    }
  }, [landingPages]);

  const styles = {
    containerStyles: {
      justifyContent: smallerThan992 ? 'space-evenly' : 'flex-start',
    },
  };

  const Links = ({ children, links, prefixUrlsToWeb: landingPages }) => {
    const url = (link) => {
      switch (true) {
        case landingPages:
          return `${process.env.REACT_APP_API_URL}/landing/${link.slug}`;
        default:
          return link.slug;
      }
    };

    return (
      <>
        {landingPages === true ? (
          <nav className="footer__inner_content">
            <ul className="footer__inner__content__ul">
              {links.map((link) => (
                <li className="footer__inner__content__ul__li">
                  <a href={url(link)} target="_blank" rel="noopener noreferrer">
                    {link.name}
                  </a>
                </li>
              ))}
              {children}
            </ul>
          </nav>
        ) : (
          <nav className="footer__inner_content">
            <ul className="footer__inner__content__ul">
              {links.map((link) => (
                <li className="footer__inner__content__ul__li">
                  <a href={url(link)} target="_blank" rel="noopener noreferrer">
                    {link.name}
                  </a>
                </li>
              ))}
              {children}
            </ul>
          </nav>
        )}
      </>
    );
  };

  return (
    <div className="footer">
      <div className="container" id="footer-m" style={styles.containerStyles}>
        <Column>
          <CompanyDeclaration />
          <Links links={urls.legal} />
          <SocialIcons />
        </Column>

        <Column title="Consultants">
          <Links links={urls.consultants}>
            <li className="footer__inner__content__ul__li">
              {getUser().access_token ? (
                getUser().type === 'FREELANCER' && (
                  <Link to="/freelancer/dashboard" className="">
                    Dashboard
                  </Link>
                )
              ) : (
                <Link to="/freelancer/logout" className="">
                  Log In/Sign Up
                </Link>
              )}
            </li>
          </Links>
        </Column>

        <Column title="Companies">
          <Links links={urls.companies}>
            <li className="footer__inner__content__ul__li">
              {getUser().access_token ? (
                getUser().type === 'AGENCY' && (
                  <Link to="/agency/dashboard" className="">
                    Dashboard
                  </Link>
                )
              ) : (
                <Link to="/agency/logout" className="">
                  Log In/Sign Up
                </Link>
              )}
            </li>
          </Links>
        </Column>

        <Column title="Liberty Hive">
          <Links links={urls.libertyHive}>
            <li className="footer__inner__content__ul__li">
              {getUser().access_token ? (
                getUser().type === 'AGENCY' && (
                  <Link to="/agency/dashboard" className="">
                    Dashboard
                  </Link>
                )
              ) : (
                <Link to="/agency/logout" className="">
                  Log In/Sign Up
                </Link>
              )}
            </li>
          </Links>
        </Column>

        {hasLandingPages && (
          <Column title="">
            <Links links={filteredLandingPages} prefixUrlsToWeb />
          </Column>
        )}
      </div>
    </div>
  );
};

export default Footer;

const Column = ({ children, title }) => (
  <div className="footer__inner">
    {title && (
      <div className="footer__inner__title">
        {title}
        &nbsp;
      </div>
    )}
    <nav className="footer__inner__content">{children}</nav>
  </div>
);

const CompanyDeclaration = () => (
  <>
    <a href={process.env.REACT_APP_API_URL}>
      <img src="/img/lh-footer-logo.png" className="img__logo__footer footer__logo" alt="" />
    </a>
    <p className="footer__inner__smallprint">
      © {new Date().getFullYear()} Liberty Hive.
      <br />
      All rights reserved.
    </p>
  </>
);

const SocialIcons = () => (
  <>
    <a
      href="https://www.instagram.com/libertyhive/"
      target="_blank"
      rel="noopener noreferrer"
      className="footer__links__img"
    >
      <img
        src="/img/social-icon-instagram@2x.png"
        className="img img__social"
        alt=""
      />
    </a>
    <a
      href="https://www.linkedin.com/company/libertyhive/"
      target="_blank"
      rel="noopener noreferrer"
      className="footer__links__img"
    >
      <img
        src="/img/social-icon-linkedin@2x.png"
        className="img img__social"
        alt=""
      />
    </a>
  </>
);

const urls = {
  legal: [
    {
      slug: `${process.env.REACT_APP_API_URL}/pages/privacy-policy`,
      name: 'Privacy Policy',
    },
    {
      slug: `${process.env.REACT_APP_API_URL}/pages/platform-terms`,
      name: 'Platform Terms',
    },
    {
      slug: `${process.env.REACT_APP_API_URL}/pages/cookies`,
      name: 'Cookies',
    },
  ],
  consultants: [
    {
      slug: `${process.env.REACT_APP_API_URL}#how-it-works`,
      name: 'How It Works',
    },
    {
      slug: '/freelancer/dashboard',
      name: 'Find A Job',
    },
    {
      slug: `${process.env.REACT_APP_API_URL}/pages/terms-conditions`,
      name: 'Terms & Conditions',
    },
  ],
  companies: [
    {
      slug: `${process.env.REACT_APP_API_URL}#how-it-works`,
      name: 'How It Works',
    },
    {
      slug: '/agency/add-opportunity',
      name: 'Add An Opportunity',
    },
    {
      slug: `${process.env.REACT_APP_API_URL}/pages/company-terms-conditions`,
      name: 'Terms & Conditions',
    },
  ],
  libertyHive: [
    {
      slug: `${process.env.REACT_APP_API_URL}/pages/about-us`,
      name: 'About Us',
    },
    {
      slug: `${process.env.REACT_APP_API_URL}/pages/pricing`,
      name: 'Pricing',
    },
    {
      slug: `${process.env.REACT_APP_API_URL}/pages/faqs`,
      name: 'FAQs',
    },
    {
      slug: `${process.env.REACT_APP_API_URL}/blog`,
      name: 'Blog',
    },
    {
      slug: `${process.env.REACT_APP_API_URL}/pages/get-in-touch`,
      name: 'Contact Us',
    },
  ],
};
