import React, { useEffect } from 'react';
import moment from 'moment';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import Header from '../../components/Freelancer/Header';
import Footer from '../../components/Footer';
import jquery from '../../styles/js/app';
import { GET_OPPORTUNITIES_FOR_USER } from '../../graphql';

const OpportunitiesForYou = () => {
  useEffect(() => {
    jquery();
  }, []);

  const {
    loading,
    data: { me: { preferences: opportunitiesForYou = [] } = {} } = {},
  } = useQuery(GET_OPPORTUNITIES_FOR_USER, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <div className="lh-wrapper">
      <Header isLoggedIn />
      <div className="container">
        <h1>
          Opportunities For You
          {' '}
          {opportunitiesForYou && `(${opportunitiesForYou.length})`}
        </h1>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Company</th>
                <th scope="col" />
                <th scope="col">Date posted</th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
              {opportunitiesForYou
                && !loading
                && opportunitiesForYou.map(
                  ({
                    agency: { company, avatar }, title, created_at, id,
                  }) => (
                    <tr key={created_at}>
                      <th scope="row">
                        <Link
                          to={`/freelancer/opportunities/${id}`}
                        >
                          <img
                            src={
                              avatar || '/img/agency-picture-placeholder@2x.jpg'
                            }
                            alt={company}
                            className="card__small__img-responsive"
                          />
                        </Link>
                      </th>
                      <td className="align-middle">
                        <Link
                          to={`/freelancer/opportunities/${id}`}
                          className="text-dark txt__fs-18"
                        >
                          {title}
                        </Link>
                        <br />
                        {company}
                      </td>
                      <td className="align-middle">
                        {moment(created_at).format('DD/MM/YYYY')}
                      </td>
                      <td className="align-middle">
                        <Link
                          to={`/freelancer/opportunities/${id}`}
                          className="link-freelancer"
                        >
                          View Opportunity
                        </Link>
                      </td>
                    </tr>
                  ),
                )}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OpportunitiesForYou;
