import React from 'react';
import { getUser } from '../utils/helpers';

const HeaderCommonLogin = ({ isLoggedIn }) => {
  if (getUser().access_token) isLoggedIn = true;

  return (
    <>
      <div className="mobile-menu">
        <nav className="mobile-menu__inner">
          <ul className="mobile-menu__list">
            <li className="mobile-menu__list__item-small">
              <a
                href={`${process.env.REACT_APP_API_URL}#how-it-works`}
                className=""
              >
                How It Works
              </a>
            </li>
          </ul>
          <div className="mobile-menu__inner__social">
            <a href="/#" target="_self" className="footer__links__img">
              <img
                src="/img/liberty-hive-logo-icon-white-small@2x.png"
                className="img img__social"
                alt=""
              />
            </a>
            <a
              href="https://www.instagram.com/libertyhive/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer__links__img"
            >
              <img
                src="/img/social-icon-instagram@2x.png"
                className="img img__social"
                rel="noopener noreferrer"
                alt=""
              />
            </a>
            <a
              href="https://www.linkedin.com/company/libertyhive/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer__links__img"
            >
              <img
                src="/img/social-icon-linkedin@2x.png"
                className="img img__social"
                alt=""
              />
            </a>
          </div>
        </nav>
      </div>
      <div className="header-agency" style={{ background: '#f14a56' }}>
        <div className="header-agency__inner container">
          <div className="row">
            <div className="header-agency__inner__logo col-5">
              <a href="/#">
                <img
                  src="/img/liberty-hive-logo-white-mobile@2x.png"
                  className="img__logo__mobile"
                  alt=""
                />
              </a>
              <a href="/#">
                <img
                  src="/img/liberty-hive-logo-white-desktop@2x.png"
                  className="img__logo__desktop"
                  alt=""
                />
              </a>
            </div>
            <nav className="header-agency__inner__menu col-7 desktop" />
            <div className="header-agency__inner__menu col-7 mobile-tablet">
              <div id="hamburger">
                <span />
                <span />
                <span />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderCommonLogin;
