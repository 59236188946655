import React from 'react';
import 'bootstrap/js/src/modal';
import { useMutation, useReactiveVar } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import hideModal from '../../styles/js/hideModal';
import { currentTypeVar } from '../../graphql/config';
import {
  DELETE_EDUCATION,
  DELETE_EXPERIENCE,
  DELETE_RESUME,
} from '../../graphql';

const RemoveModal = ({ refetch }) => {
  const handleConfirm = () => {
    removeItem();
    refetch();
    hideModal();
  };

  const [removeEducation, { loading: removeEducationLoading }] =
    useMutation(DELETE_EDUCATION);

  const [removeExperience, { loading: removeExperienceLoading }] =
    useMutation(DELETE_EXPERIENCE);

  const [removeResume, { loading: removeResumeLoading }] =
    useMutation(DELETE_RESUME);

  const { id, type } = useReactiveVar(currentTypeVar);

  if (
    removeEducationLoading ||
    removeExperienceLoading ||
    removeResumeLoading
  ) {
    toast.info('Loading...', { toastId: 'remElLoad' });
  }

  const removeItem = () => {
    toast.dismiss();
    if (type === 'education') {
      return removeEducation({
        variables: {
          id,
        },
      })
        .then(() => {
          toast.dismiss();
          toast.success('Education has been removed', { autoClose: 4000 });
        })
        .catch(() => {
          toast.dismiss();
          toast.error('Could not remove education');
        });
    } else if (type === 'experience') {
      return removeExperience({
        variables: {
          id,
        },
      })
        .then(() => {
          toast.dismiss();
          toast.success('Experience has been removed', { autoClose: 4000 });
        })
        .catch(() => {
          toast.dismiss();
          toast.error('Could not remove experience');
        });
    } else if (type === 'CV') {
      return removeResume({
        variables: {
          id,
        },
      })
        .then(() => {
          toast.dismiss();
          toast.success('CV has been removed', { autoClose: 4000 });
        })
        .catch(() => {
          toast.dismiss();
          toast.error('Could not remove CV');
        });
    }
  };

  return (
    <div
      className="modal fade"
      id="remove_modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="applyModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="applyModalLabel">
              Remove {type}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            Are you sure you want to remove this {type}?
          </div>
          <div
            className="modal-footer"
            style={{
              justifyContent: 'center',
            }}
          >
            <button
              type="button"
              className="btn form__btn-small mt-0 bg-freelancer"
              onClick={handleConfirm}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemoveModal;
