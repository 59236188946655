import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { Link, Redirect, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify';
import Error from '../../components/Error';
import { LOGIN } from '../../graphql';
import { userVar } from '../../graphql/config';
import {
  getUser,
  saveUserLocalstorage,
  saveLoginTypeLocalstorage,
} from '../../utils/helpers';
import Header from '../../components/Freelancer/Header';
import Footer from '../../components/Footer';
import jquery from '../../styles/js/app';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Login = ({ history }) => {
  useEffect(() => {
    jquery();
  }, []);
  const { register, handleSubmit, errors } = useForm();
  const [loginMutation, { loading }] = useMutation(LOGIN);
  const [isEnabled, setIsEnabled] = useState(true);
  const redirectTo = useQuery().get('redirectTo');

  if (getUser().access_token && getUser().type === 'FREELANCER') {
    return (
      <Redirect
        to={{
          pathname: redirectTo ?? '/freelancer/dashboard',
          state: { referrer: '/freelancer/login' },
        }}
      />
    );
  }

  const loginUser = (data) => {
    setIsEnabled(false);
    loginMutation({
      variables: data,
    })
      .then(({ data: { login } }) => {
        if (!login) {
          setIsEnabled(true);
          return toast.error('Wrong email / password combination!', {
            toastId: 'frLogErr',
          });
        }
        userVar(login);
        saveUserLocalstorage(login);
        const cookies = new Cookies();
        cookies.set('lh_user', login.id, {
          path: '/',
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
        });
        saveLoginTypeLocalstorage('FREELANCER');
        history.push(redirectTo ?? '/freelancer/dashboard');
      })
      .catch((e) => {
        setIsEnabled(true);
        toast.error('Something went wrong', { toastId: 'graphqlError' });
      });
  };

  return (
    <div className="lh-wrapper">
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="onboarding">
              <h1 className="center mb-50">Login</h1>
              <form
                className="form form__freelancer"
                onSubmit={handleSubmit(loginUser)}
              >
                <div className="form__input-block">
                  <div className="form__heading mt-0">Email</div>
                  <div className="form__input-wrapper">
                    <input
                      className="form__input"
                      type="text"
                      autoComplete="false"
                      name="email"
                      ref={register({
                        required: true,
                        pattern:
                          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      })}
                    />
                    <div className="form__clear form__hidden">
                      <i className="fas fa-times" />
                    </div>
                  </div>
                </div>
                {errors.email && (
                  <Error text="Please enter a valid email address" />
                )}
                <div className="form__input-block">
                  <div className="form__heading">Password</div>
                  <div className="form__input-wrapper">
                    <input
                      className="form__input"
                      type="password"
                      id="onboarding-password"
                      name="password"
                      ref={register({ required: true })}
                      autoComplete="false"
                    />
                    <div className="form__clear form__hidden">
                      <i className="fas fa-times" />
                    </div>
                    <div
                      toggle="#onboarding-password"
                      className="fas fa-eye form__toggle-password form__hidden"
                    />
                  </div>
                </div>
                {errors.password && <Error text="Please enter a password" />}
                <button
                  type="submit"
                  disabled={!isEnabled}
                  className="btn form__btn bg-freelancer d-block mb-50"
                >
                  {loading ? 'Loading...' : 'Login'}
                </button>
                <div className="d-flex justify-content-between mb-60">
                  <Link
                    to="/freelancer/register"
                    className="link-freelancer txt__fw-600"
                  >
                    Need an account?
                  </Link>
                  <Link
                    to="/freelancer/forgot-password"
                    className="link-freelancer txt__fw-600"
                  >
                    Forgotten Password
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
