import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import Footer from '../../components/Footer';
import jquery from '../../styles/js/app';
import BlogpostSidebar from '../../components/Agency/BlogpostSidebar';
import { GET_ARTICLE } from '../../graphql';
import Header from '../../components/Agency/Header';

const BlogIndividual = (props) => {
  useEffect(() => {
    jquery();
  }, []);

  const {
    match: {
      params: { slug },
    },
  } = props;

  const {
    loading, error, data, refetch,
  } = useQuery(GET_ARTICLE, {
    variables: { slug },
    onError: () => refetch(),
  });

  if (error) return toast.error(error);

  return (
    <div className="lh-wrapper">
      <Header isLoggedIn />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Link
              to="/agency/resources"
              className="link-agency txt__fw-600 mb-20 d-block"
            >
              <i className="fa fa-chevron-left" />
              {' '}
              Back
            </Link>
          </div>
          {loading && <div>Loading...</div>}
          {data && (
            <div className="col-lg-7">
              <h1>{data.article.title}</h1>
              <img src={data.article.image} alt="" className="w-100 mb-30" />
              <p
                className="txt__fw-600 mb-70"
                dangerouslySetInnerHTML={{ __html: data.article?.content }}
              />
            </div>
          )}
          {data?.article && <BlogpostSidebar articleId={data.article.id} />}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogIndividual;
