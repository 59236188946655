import React from 'react'
import Header from '../../components/Agency/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';

const Welcome = props => {
    return (
        <>
            <div className="lh-wrapper">
                <Header isLoggedIn />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12"
                        style={{
                            minHeight: '200px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <div>
                                <h1>You've successfully accepted your team invitation.</h1>
                            </div>
                            <div>
                                <button type="submit" className="form__btn btn d-block">
                                    <Link to="/agency/login">
                                        <span style={{
                                            color: 'white',
                                            fontWeight: '600'
                                        }}>LOGIN</span>
                                    </Link>
                                </button>
                            </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Welcome