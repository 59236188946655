import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import currencyToSymbolMap from 'currency-symbol-map/map';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import getSymbolFromCurrency from 'currency-symbol-map';
import { toast } from 'react-toastify';
import ConfirmApplyOpportunity from '../../../components/Freelancer/ConfirmApplyOpportunityModal';
import ProfileIncomplete from '../../../components/Freelancer/ProfileIncompleteModal';
import Header from '../../../components/Freelancer/Header';
import Footer from '../../../components/Footer';
import jquery from '../../../styles/js/app';
import ResourcesCard from '../../../components/Freelancer/ResourcesCard';
import CTA from '../../../components/CTA';
import DealCard from '../../../components/Freelancer/DealCard';
import TextareaAutosize from 'react-textarea-autosize';
import {
  GET_ALL_JOB_TYPES,
  GET_AUTH_USER,
  CREATE_APPLICANT,
  GET_ARTICLES,
  GET_DEALS,
  VIEW_OPPORTUNITY,
} from '../../../graphql';
import {
  capitalizeFirstLetter,
  getOpportunityGenreOrSkillNames,
  getOpportunityLocation,
  userHasApplied,
} from '../../../utils/helpers';
import { userVar } from '../../../graphql/config';
import {
  openApplyOpportunityModal,
  openProfileIncompleteModal,
} from '../../../styles/js/hideModal';
import './styles.css';

const OpportunityDetail = ({
  match: {
    params: { id },
  },
}) => {
  useEffect(() => {
    dayjs.extend(relativeTime);
    jquery();
  }, []);

  const [hasApplied, setHasApplied] = useState(false);
  const [isProfileComplete, setIsProfileComplete] = useState(false);
  const [messages, setMessages] = useState([]);
  const [hasApplicationLink, setHasApplicationLink] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(['GBP', '£']);
  const [selectedJobType, setSelectedJobType] = useState(null);
  const [selectedSubJobType, setSelectedSubJobType] = useState(null);
  const [viewOpportunity, { called, data, loading }] = useMutation(
    VIEW_OPPORTUNITY,
    {
      onCompleted: (res) => {
        setHasApplied(userHasApplied(res?.viewOpportunity?.applicants));
        setHasApplicationLink(
          typeof res?.viewOpportunity?.application_link !== 'undefined' &&
            res?.viewOpportunity?.application_link
        );
        setSelectedCurrency(
          currencies.find(
            (o) => o[0] === res?.viewOpportunity?.compensation_currency
          )
        );
        setSelectedJobType(
          allJobtypes.find((o) => o.key === res?.viewOpportunity?.type)
        );
        setSelectedSubJobType(
          allSubJobtypes.find((o) => o.key === res?.viewOpportunity?.sub_type)
        );
      },
      onError: (e) => {
        toast.error('Something went wrong!', { toastId: 'errOppDet' });
      },
    }
  );

  const allJobtypes = [
    {
      key: 'PERMANENT',
      name: 'Permanent',
      description: 'Full Time or Part Time',
    },
    {
      key: 'FREELANCE',
      name: 'Freelance',
      description: 'Less than six months',
    },
    {
      key: 'BOTH',
      name: 'Permanent or Freelance',
    },
  ];

  const allSubJobtypes = [
    {
      key: 'FULLTIME',
      name: 'Full-time',
    },
    {
      key: 'PARTTIME',
      name: 'Part-time',
    },
    {
      key: 'BOTH_TIME',
      name: 'Full-time or Part-time',
    },
  ];

  const [createApplicant, { loading: loadingApplication }] =
    useMutation(CREATE_APPLICANT);

  const sendApplication = (cv, doc, reason = '') => {
    let input = {
      user: {
        connect: userVar().id,
      },
      opportunity: {
        connect: data.viewOpportunity.id,
      },
    };

    if (cv.file) {
      input.resume = cv.file;
    }

    if (doc.file) {
      input.document = doc.file;
    }

    if (reason) {
      input.reason = reason;
    }

    toast.dismiss();
    createApplicant({
      variables: {
        input: input,
      },
    })
      .then(() => {
        if (data?.viewOpportunity?.application_link) {
          window.open(data.viewOpportunity.application_link, '_blank');
        }

        setHasApplied(true);
        toast.success('Application sent succesfully!', { autoClose: 4000 });
      })
      .catch((e) =>
        toast.error('Could not send application', { toastId: 'appErr' })
      );
  };

  const {
    data: { articles: { data: articles = [] } = {} } = {},
    loading: articleLoading,
    refetch: refetchArticle,
  } = useQuery(GET_ARTICLES, {
    variables: {
      availability: ['FREELANCER', 'FREELANCER_AND_AGENCY'],
      first: 3,
      orderBy: {
        field: { field: 'CREATED_AT', order: 'DESC' },
      },
    },
    onError: () => refetchArticle(),
  });

  const {
    data: { articles: { data: ctas = [] } = {} } = {},
    refetch: refetchCTA,
  } = useQuery(GET_ARTICLES, {
    variables: {
      availability: ['FREELANCER', 'FREELANCER_AND_AGENCY'],
      type: ['MENTORING', 'TRAINING'],
      first: 2,
      orderBy: {
        field: { field: 'CREATED_AT', order: 'DESC' },
      },
    },
    onError: () => refetchCTA(),
  });

  const {
    data: { deals: { data: deals = [] } = {} } = {},
    loading: loadingDeals,
    refetch: refetchDeal,
  } = useQuery(GET_DEALS, {
    variables: {
      first: 6,
      orderBy: {
        field: { field: 'CREATED_AT', order: 'DESC' },
      },
    },
    onError: () => refetchDeal(),
  });

  if (!called && data === undefined && id) {
    viewOpportunity({
      variables: {
        id,
      },
    });
  }

  const availableCurrencies = ['GBP', 'EUR', 'USD'];
  const currencies = Object.entries(
    Object.keys(currencyToSymbolMap)
      .filter((key) => availableCurrencies.includes(key))
      .reduce((obj, key) => {
        obj[key] = currencyToSymbolMap[key];
        return obj;
      }, {})
  );

  const { data: userData, refetch } = useQuery(GET_AUTH_USER, {
    fetchPolicy: 'network-only',
    onCompleted: () => checkProfileCompleted(),
    onError: () => refetch(),
  });

  const checkProfileCompleted = () => {
    if (userData.me) {
      setIsProfileComplete(userData?.me?.isProfileComplete?.complete);
      setMessages(userData?.me?.isProfileComplete?.missing);
    }
  };

  const checkApplicationLink = () => {
    if (data?.viewOpportunity) {
      return data?.viewOpportunity?.application_link !== '';
    }

    return false;
  };

  return (
    <div className="lh-wrapper">
      <Header isLoggedIn />
      <ConfirmApplyOpportunity
        confirm={sendApplication}
        loading={loadingApplication}
        applicationLink={hasApplicationLink}
      />
      <ProfileIncomplete missing={messages} />
      <div className="container">
        {loading && <div>Loading job opportunity...</div>}
        {data && (
          <div className="row mb-100">
            <div className="col-12 col-lg-8">
              <h1>
                Job Opportunity:
                {data.viewOpportunity.title}
              </h1>
            </div>
            <div className="col-12 col-lg-4 text-lg-right">
              <Link
                to="/freelancer/dashboard"
                className="link-freelancer txt__fw-600"
              >
                {'< Back To Dashboard'}
              </Link>
            </div>
            <div className="col-12">
              <div className="card__container">
                <div className="row">
                  <div className="col-lg-4">
                    <Link
                      to={`/freelancer/company-profile/${data.viewOpportunity.agency.id}`}
                    >
                      <img
                        src={
                          data.viewOpportunity.agency.avatar ||
                          '/img/agency-picture-placeholder@2x.jpg'
                        }
                        className="mb-10"
                        alt=""
                      />
                    </Link>
                    <table className="table table-borderless table-sm mb-lg-0 mb-40">
                      <tbody>
                        <tr>
                          <td>
                            <p className="mb-0 txt__fs-18 txt__fw-600">
                              Employer:{' '}
                            </p>
                          </td>
                          <td>
                            <p className="mb-0 txt__fs-18">
                              <Link
                                to={`/freelancer/company-profile/${data.viewOpportunity.agency.id}`}
                                className="link-freelancer"
                              >
                                {data.viewOpportunity.agency.company}
                              </Link>
                            </p>
                          </td>
                        </tr>
                        {data.viewOpportunity.genres.length > 0 && (
                          <tr>
                            <td>
                              <p className="mb-0 txt__fs-18 txt__fw-600">
                                Category Experience:{' '}
                              </p>
                            </td>
                            <td>
                              <p className="mb-0 txt__fs-18">
                                {getOpportunityGenreOrSkillNames(
                                  data.viewOpportunity.genres
                                )}
                              </p>
                            </td>
                          </tr>
                        )}

                        <tr>
                          <td>
                            <p className="mb-0 txt__fs-18 txt__fw-600">
                              Location:{' '}
                            </p>
                          </td>
                          <td>
                            <p className="mb-0 txt__fs-18">
                              {getOpportunityLocation(
                                data.viewOpportunity.is_on_site,
                                data.viewOpportunity.is_remote
                              )}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="mb-0 txt__fs-18 txt__fw-600">
                              Job Type:
                            </p>
                          </td>
                          <td>
                            <p className="mb-0 txt__fs-18">
                              {selectedJobType?.name}{' '}
                              {selectedSubJobType
                                ? `- ${selectedSubJobType?.name}`
                                : ''}
                            </p>
                            {data.viewOpportunity.is_ir35 == 1 && (
                              <p
                                style={{
                                  fontStyle: 'italic',
                                }}
                              >
                                This contract is under IR35 rules.
                              </p>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="mb-0 txt__fs-18 txt__fw-600">
                              Experience Level:
                            </p>
                          </td>
                          <td>
                            <p className="mb-0 txt__fs-18">
                              {capitalizeFirstLetter(
                                data.viewOpportunity.level
                              )}
                            </p>
                          </td>
                        </tr>
                        {data.viewOpportunity.skills.length > 0 && (
                          <tr>
                            <td>
                              <p className="mb-0 txt__fs-18 txt__fw-600">
                                Skills Required:
                              </p>
                            </td>
                            <td>
                              <p className="mb-0 txt__fs-18">
                                {getOpportunityGenreOrSkillNames(
                                  data.viewOpportunity.skills
                                )}
                              </p>
                            </td>
                          </tr>
                        )}
                        {data.viewOpportunity.compensation > 0 && (
                          <tr>
                            <td>
                              <p className="mb-0 txt__fs-18 txt__fw-600">
                                Compensation:
                              </p>
                            </td>
                            <td>
                              <p className="mb-0 txt__fs-18">
                                {`${
                                  getSymbolFromCurrency(
                                    data.viewOpportunity.compensation_currency
                                  ) || ''
                                } ${data.viewOpportunity.compensation.toLocaleString(
                                  undefined,
                                  { maximumFractionDigits: 2 }
                                )} per ${data.viewOpportunity.compensation_unit.toLowerCase()}`}
                              </p>
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td>
                            <p className="mb-0 txt__fs-18 txt__fw-600">
                              Date Published:
                            </p>
                          </td>
                          <td>
                            <p className="mb-0 txt__fs-18">
                              {dayjs(
                                data.viewOpportunity.created_at,
                                'YY-MM-DD'
                              ).fromNow()}
                            </p>
                          </td>
                        </tr>

                        {data?.viewOpportunity?.is_compensation_displayed &&
                          data?.viewOpportunity?.minimumBudget &&
                          data?.viewOpportunity?.maximumBudget && (
                            <tr>
                              <td>
                                <p className="mb-0 txt__fs-18 txt__fw-600">
                                  Compensation:
                                </p>
                              </td>
                              <td>
                                <p className="mb-0 txt__fs-18">
                                  {(selectedCurrency?.length > 0
                                    ? selectedCurrency[1]
                                    : null) +
                                    data?.viewOpportunity?.minimumBudget?.toLocaleString()}
                                  {' - '}
                                  {(selectedCurrency?.length > 0
                                    ? selectedCurrency[1]
                                    : null) +
                                    data?.viewOpportunity?.maximumBudget?.toLocaleString()}
                                </p>
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                    <button
                      type="submit"
                      className="btn form__btn bg-freelancer d-block mb-50"
                      disabled={hasApplied}
                      onClick={() => {
                        if (isProfileComplete) {
                          openApplyOpportunityModal();
                        } else {
                          openProfileIncompleteModal();
                        }
                      }}
                    >
                      {loadingApplication && 'Loading...'}
                      {!loadingApplication && (
                        <>
                          {hasApplied ? (
                            <>
                              You have applied <br /> for this job
                            </>
                          ) : (
                            'CONNECT NOW'
                          )}
                        </>
                      )}
                    </button>
                  </div>
                  <div className="col-lg-8">
                    <h2 className="mb-10">About the role</h2>
                    <p>
                      <TextareaAutosize
                        id="opp_details"
                        rows="5"
                        className="form__textarea__border-light height__unset"
                        name="opportunity_description"
                        defaultValue={data?.viewOpportunity?.description}
                        contentEditable={false}
                        readOnly={true}
                        style={{ overflow: 'hidden' }}
                      />
                    </p>
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <ResourcesCard content={articles} loading={articleLoading} />
        <CTA content={ctas} />

        <h1>Deals For You</h1>
        <div className="row">
          {loadingDeals && 'Loading deals...'}
          {deals && deals.map((deal) => <DealCard key={deal.id} deal={deal} />)}
          <div className="col-12 mt-3 text-right d-md-none">
            <a href="/#" className="link-freelancer txt__fw-600">
              View More
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OpportunityDetail;
