import React from 'react'
import { Link } from 'react-router-dom';

const AccountInactive = () => (
    <div className="row">
      <div className="col-12">
        <h2 className="mb-20">Thank you for registering!</h2>
        <p>We are currently approving your account.</p>
        <p>
          In the mean time, why not
          {' '}
          <Link
            to="/freelancer/manage-cv"
            className="link-freelancer txt__fw-600"
          >
            update your profile
          </Link>
          ?
        </p>
      </div>
    </div>
);

export default AccountInactive