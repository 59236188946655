import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import Header from '../../components/Agency/Header';
import Footer from '../../components/Footer';
import jquery from '../../styles/js/app';
import Error from '../../components/Error';
import AccountSubmenuDesktop from '../../components/Agency/AccountSubmenuDesktop';
import AccountSubmenuMobile from '../../components/Agency/AccountSubmenuMobile';

import { LOGIN, UPDATE_PROFILE } from '../../graphql';
import { userVar } from '../../graphql/config';

const ChangePassword = ({ history }) => {
  useEffect(() => {
    jquery();
  }, []);

  const {
    register, handleSubmit, errors, getValues,
  } = useForm();
  const [loginMutation, { loading }] = useMutation(LOGIN);
  const [updateProfile, state] = useMutation(UPDATE_PROFILE);

  const editProfile = async (data) => {
    toast.dismiss();
    loginMutation({
      variables: { email: userVar().email, password: data.current_password },
    })
      .then((res) => {
        const { data: loginData } = res;
        if (!loginData.login) {
          return toast.error('Incorrect password!');
        }
        return updateProfile({
          variables: {
            input: { password: data.password },
          },
        })
          .then(() => {
            toast.success('Password changed successfully!', {
              autoClose: 4000,
            });
            return history.push('/agency/account');
          })
          .catch((e) => {
            toast.error('Something went wrong!', { toastId: 'resErr' });
          });
      })
      .catch((e) => {
        toast.error('Something went wrong!', { toastId: 'resErr2' });
      });
  };

  return (
    <div className="lh-wrapper">
      <Header isLoggedIn />
      <AccountSubmenuMobile />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Change password</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-8 col-lg-9">
            <div className="row">
              <div className="col-9">
                <div className="profile-bio-row row">
                  <div className="col-12 add-opportunity-row__title">
                    <span className="txt__fw-600">Current Password</span>
                  </div>
                  <div className="col-12 profile-bio-row__content">
                    <div className="form__input-block">
                      <div className="form__input-wrapper">
                        <input
                          className="form__input"
                          type="password"
                          name="current_password"
                          id="current_password"
                          ref={register({ minLength: 8, required: true })}
                        />
                        <div className="form__clear form__hidden">
                          <i className="fas fa-times" />
                        </div>
                        <div
                          toggle="#current_password"
                          className="fas fa-eye form__toggle-password form__hidden"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {errors.current_password && (
                  <Error text="Password must be at least 8 characters" />
                )}
                <div className="profile-bio-row row">
                  <div className="col-12 add-opportunity-row__title">
                    <span className="txt__fw-600">New Password</span>
                  </div>
                  <div className="col-12 profile-bio-row__content">
                    <div className="form__input-block">
                      <div className="form__input-wrapper">
                        <input
                          className="form__input"
                          type="password"
                          name="password"
                          id="password"
                          ref={register({ minLength: 8, required: true })}
                        />
                        <div className="form__clear form__hidden">
                          <i className="fas fa-times" />
                        </div>
                        <div
                          toggle="#password"
                          className="fas fa-eye form__toggle-password form__hidden"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {errors.password && (
                  <Error text="Password must be at least 8 characters" />
                )}
                <div className="profile-bio-row row">
                  <div className="col-12 add-opportunity-row__title">
                    <span className="txt__fw-600">Confirm Password</span>
                  </div>
                  <div className="col-12 profile-bio-row__content">
                    <div className="form__input-block">
                      <div className="form__input-wrapper">
                        <input
                          className="form__input"
                          type="password"
                          name="password_confirmation"
                          id="password_confirmation"
                          ref={register({
                            validate: (value) => value === getValues('password'),
                          })}
                        />
                        <div className="form__clear form__hidden">
                          <i className="fas fa-times" />
                        </div>
                        <div
                          toggle="#password_confirmation"
                          className="fas fa-eye form__toggle-password form__hidden"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {errors.password_confirmation && (
                  <Error text="Passwords do not match" />
                )}
                <a
                  href="/#"
                  className="form__btn d-block mb-50"
                  onClick={handleSubmit(editProfile)}
                >
                  {loading || state.loading ? 'Loading...' : 'Save'}
                </a>
              </div>
            </div>
          </div>
          <AccountSubmenuDesktop />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ChangePassword;
