import React, { useState, useEffect } from 'react';
import 'bootstrap/js/src/modal';
import DatePicker from 'react-date-picker';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useMutation, useReactiveVar } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { UPDATE_EDUCATION } from '../../graphql';
import Error from '../Error';
import { currentEducationVar } from '../../graphql/config';
import hideModal from '../../styles/js/hideModal';

const EditEducationModal = ({ refetch }) => {
  const {
    id,
    qualification,
    description,
    from: existingFrom,
    to: existingTo,
  } = useReactiveVar(currentEducationVar);

  const [txtDescription, setTxtDescription] = useState();
  const [present, setPresent] = useState(false);

  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  useEffect(() => {
    if (existingFrom) {
      setFrom(new Date(existingFrom));
    }

    if (existingTo) {
      setTo(new Date(existingTo));
    }
  }, [existingFrom, existingTo]);

  description && !txtDescription && setTxtDescription(description);

  const [updateEducation, { loading }] = useMutation(UPDATE_EDUCATION);
  const { register, handleSubmit, errors, reset } = useForm();

  const clearForm = () => {
    reset();
    setFrom(null);
    setTo(null);
    setPresent(false);
    setTxtDescription(null);
  };

  const editEducation = (data) => {
    toast.dismiss();

    const input = {
      ...data,
    };

    if (from) {
      input.from = moment(from).format('YYYY-MM-DD');
    } else if (from === null) {
      input.from = null;
    }

    if (!present) {
      input.to = moment(to).format('YYYY-MM-DD');
    }

    if (to === null) {
      input.to = null;
    }

    return updateEducation({
      variables: {
        id,
        input,
      },
    })
      .then(() => {
        currentEducationVar({});
        clearForm();
        refetch();
        hideModal();
        toast.success('Education updated successfully!', { autoClose: 4000 });
      })
      .catch((e) => {
        toast.error('Could not edit education', { toastId: 'addExErr' });
      });
  };

  return (
    <div>
      <div
        className="modal fade"
        id="edit_qualification_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="edit_qualification_modal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <h3 className="txt__fw-600 text-center mb-60">
                Edit Qualification
              </h3>
              <form className="row">
                <div className="col-12">
                  <div className="form__input-block mb-50">
                    <span className="txt__fw-600">Qualification</span>
                    <div className="form__input-wrapper">
                      <input
                        className="form__input"
                        type="text"
                        autoComplete="false"
                        name="qualification"
                        placeholder="Qualification e.g BSc Physics"
                        ref={register({ required: true })}
                        defaultValue={qualification}
                      />
                      <div className="form__clear form__hidden">
                        <i className="fas fa-times" />
                      </div>
                    </div>
                    {errors.qualification && (
                      <Error text="This field is required" noMargin />
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <span className="txt__fw-600">Description</span>
                  <textarea
                    rows="8"
                    className="form__textarea height__unset"
                    placeholder="Add your description here..."
                    name="description"
                    ref={register({ required: true })}
                    defaultValue={description}
                  />
                  {errors.description && (
                    <Error text="This field is required" noMargin />
                  )}
                </div>
                <div className="col-12 text-center">
                  <a
                    href="/#"
                    className="form__btn d-block bg-freelancer"
                    onClick={handleSubmit(editEducation)}
                  >
                    {loading ? 'Loading...' : 'Save'}
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditEducationModal;
