import $ from 'jquery';

export const openAddOpportunityModal = () => {
  $('#confirm_new_opportunity').modal();
};

export const openReasonModal = () => {
  $('#close_opportunity_modal').modal();
};

export const openApplyOpportunityModal = () => {
  $('#confirm_apply_opportunity').modal();
};

export const openHideApplicationModal = (id) => {
  $(`#confirm_hide_application_${id}`).modal();
};

export const openProfileIncompleteModal = () => {
  $('#profile_incomplete').modal();
};

const hideModal = () => {
  $('#close_opportunity_modal').modal('hide');
  $('#buy_credits_modal').modal('hide');
  $('#update_info_modal').modal('hide');
  $('#delete_account_modal').modal('hide');
  $('#confirm_new_opportunity').modal('hide');
  $('#add_qualification_modal').modal('hide');
  $('#edit_qualification_modal').modal('hide');
  $('#add_experience_modal').modal('hide');
  $('#edit_experience_modal').modal('hide');
  $('#confirm_apply_opportunity').modal('hide');
  $('#remove_modal').modal('hide');
  $('#profile_incomplete').modal('hide');

  for (let i = 0; i < 50; i++) {
    $(`#confirm_hide_application_${i}`).modal('hide');
  }
};

export default hideModal;
