import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../../components/Freelancer/Header';
import Footer from '../../components/Footer';
import jquery from '../../styles/js/app';
import { formatValidationErrors, getUser } from '../../utils/helpers';
import Error from '../../components/Error';
import { resetPassword } from '../../api/auth';

const SetNewPassword = ({ history }) => {
  useEffect(() => {
    jquery();
  }, []);

  const {
    register, handleSubmit, getValues, errors,
  } = useForm();
  const [buttonText, setButtonText] = useState('Reset password');

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const email = query.get('email').replace(/\s+/g, '+').toLowerCase();
  const token = query.get('token');

  if (getUser().access_token) {
    return (
      <Redirect
        to={{
          pathname: '/freelancer/dashboard',
          state: { referrer: '/freelancer/login' },
        }}
      />
    );
  }

  const resetPasswordRequest = async (data) => {
    toast.dismiss();
    setButtonText('Loading...');
    const response = await resetPassword({
      ...data,
      token,
      email,
    });
    setButtonText('Reset password');
    if (response.data && response.data.errors) {
      response.status === 422
        ? toast.error(formatValidationErrors(response.data.errors))
        : toast.error(response.data.error);
    } else {
      toast.success(response.message);
      return history.push('/freelancer/login');
    }
  };

  return (
    <div className="lh-wrapper">
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="onboarding">
              <h1 className="center mb-50">Set your new password</h1>
              <form className="form form__freelancer">
                <div className="form__input-block">
                  <div className="form__heading mt-0">New Password</div>
                  <div className="form__input-wrapper">
                    <input
                      className="form__input"
                      type="password"
                      id="onboarding-password-new"
                      autoComplete="false"
                      name="password"
                      ref={register({ required: true, minLength: 8 })}
                    />
                    <div className="form__clear form__hidden">
                      <i className="fas fa-times" />
                    </div>
                    <div
                      toggle="#onboarding-password-new"
                      className="fas fa-eye form__toggle-password form__hidden"
                    />
                  </div>
                </div>
                <div className="form__input-block">
                  <div className="form__heading mt-0">Confirm New Password</div>
                  <div className="form__input-wrapper">
                    <input
                      className="form__input"
                      type="password"
                      id="onboarding-password-new-confirm"
                      autoComplete="false"
                      name="password_confirmation"
                      ref={register({
                        required: true,
                        validate: (value) => value === getValues('password'),
                      })}
                    />
                    <div className="form__clear form__hidden">
                      <i className="fas fa-times" />
                    </div>
                    <div
                      toggle="#onboarding-password-new-confirm"
                      className="fas fa-eye form__toggle-password form__hidden"
                    />
                  </div>
                </div>
                {errors.password_confirmation && (
                  <Error text="Passwords do not match" />
                )}
                <a
                  href="agency-create-account-step-2.html"
                  className="form__btn bg-freelancer d-block mb-50"
                  onClick={handleSubmit(resetPasswordRequest)}
                >
                  {buttonText}
                </a>
                <div className="d-flex justify-content-center mb-60">
                  <Link
                    to="/freelancer/login"
                    className="link-agency txt__fw-600"
                  >
                    Back To Login
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SetNewPassword;
