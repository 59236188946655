import React, { useEffect, useState, useContext } from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import countryList from 'country-list';
import AccountSubmenuDesktop from '../../components/Agency/AccountSubmenuDesktop';
import AccountSubmenuMobile from '../../components/Agency/AccountSubmenuMobile';
import Header from '../../components/Agency/Header';
import Footer from '../../components/Footer';
import jquery from '../../styles/js/app';
import {
  UPDATE_PROFILE,
  UPDATE_TEAM_PROFILE,
  UPDATE_PAYMENT_METHOD,
  UPDATE_TEAM_PAYMENT_METHOD,
} from '../../graphql';
import { getUser } from '../../utils/helpers';
import { AppContext } from '../../Store';

const ManagePaymentInfo = ({ history }) => {
  const [state, setState] = useContext(AppContext);
  useEffect(() => {
    jquery();
  }, []);
  const [isAgency, setIsAgency] = useState(getUser()?.type === 'AGENCY');
  const [globalState, setGlobalState] = useContext(AppContext);
  const countries = countryList
    .getData()
    .sort((a, b) => a.name.localeCompare(b.name));
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cardComplete, setCardComplete] = useState(false);
  const [billingDetails, setBillingDetails] = useState({
    address: {
      line1:
        state?.activeCompany?.user?.billing_address_1 ??
        state?.activeCompany?.user?.address_1,
      line2:
        state?.activeCompany?.user?.billing_address_2 ??
        state?.activeCompany?.user?.address_2,
      city:
        state?.activeCompany?.user?.billing_city ??
        state?.activeCompany?.user?.city,
      state:
        state?.activeCompany?.user?.billing_state ??
        state?.activeCompany?.user?.state,
      postal_code:
        state?.activeCompany?.user?.billing_zip ??
        state?.activeCompany?.user?.zip,
      country: 'GB',
    },
  });
  const [updateProfile] = useMutation(UPDATE_PROFILE);
  const [updatePaymentMethod] = useMutation(UPDATE_PAYMENT_METHOD);
  const [updateTeamProfile] = useMutation(UPDATE_TEAM_PROFILE);
  const [updateTeamPaymentMethod] = useMutation(UPDATE_TEAM_PAYMENT_METHOD);

  /** Set Team Billing Details */
  useEffect(() => {
    if (globalState?.activeCompany !== undefined) {
      const { user } = globalState?.activeCompany;
      setBillingDetails({
        address: {
          line1: user?.billing_address_1,
          line2: user?.billing_address_2,
          city: user?.billing_city,
          state: user?.billing_state,
          postal_code: user?.billing_zip,
          country: 'GB',
        },
      });
    }
  }, [globalState?.activeCompany]);

  const showError = () => {
    toast.error(error, { toastId: 'startSubscription' });
  };
  const createOptions = () => ({
    style: {
      base: {
        fontSize: '18px',
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4',
        },
        height: '42px',
        letterSpacing: '0.18px',
        lineHeight: '42px',
      },
      invalid: {
        color: '#c23d4b',
      },
    },
    classes: {
      base: 'StripeCardBase',
    },
  });

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      showError(error);
      return;
    }

    if (cardComplete) {
      setLoading(true);
    }

    const cardNumber = elements.getElement(CardNumberElement);
    const cardExpire = elements.getElement(CardExpiryElement);
    const cardCvc = elements.getElement(CardCvcElement);

    // Use your card Element with other Stripe.js APIs
    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumber,
      billing_details: billingDetails,
    });

    if (payload.error) {
      setError(payload.error);
      showError(error);
    } else {
      if (getUser()?.type === 'AGENCY') {
        updateProfile({
          variables: {
            input: {
              billing_address_1: billingDetails.address.line1,
              billing_address_2: billingDetails.address.line2,
              billing_city: billingDetails.address.city,
              billing_state: billingDetails.address.state,
              billing_country: billingDetails.address.country,
              billing_zip: billingDetails.address.postal_code,
            },
          },
        });
        updatePaymentMethod({
          variables: {
            input: { payment_method_id: payload.paymentMethod.id },
          },
        })
          .then(() => {
            setLoading(false);
            toast.dismiss();
            toast.success('Your payment info has been updated!', {
              autoClose: 4000,
            });
            setBillingDetails({
              address: {},
            });
            cardNumber.clear();
            cardExpire.clear();
            cardCvc.clear();

            return history.push('/agency/dashboard');
          })
          .catch((e) => {
            // setError(e.error);
            setError('Failed to update team payment method');
            showError();
          });
      } else if (getUser()?.type === 'GUEST') {
        updateTeamProfile({
          variables: {
            input: {
              billing_address_1: billingDetails.address.line1,
              billing_address_2: billingDetails.address.line2,
              billing_city: billingDetails.address.city,
              billing_state: billingDetails.address.state,
              billing_country: billingDetails.address.country,
              billing_zip: billingDetails.address.postal_code,
            },
            teamId: Number(globalState?.activeCompany?.team_id),
          },
        });
        updateTeamPaymentMethod({
          variables: {
            input: {
              payment_method_id: payload.paymentMethod.id,
            },
            teamId: Number(globalState?.activeCompany?.team_id),
          },
        })
          .then(() => {
            setLoading(false);
            toast.dismiss();
            toast.success('Your payment info has been updated!', {
              autoClose: 4000,
            });
            setBillingDetails({
              address: {},
            });
            cardNumber.clear();
            cardExpire.clear();
            cardCvc.clear();

            return history.push('/agency/dashboard');
          })
          .catch((e) => {
            // setError(e.error);
            setError('Failed to update team payment method');
            showError();
          });
      }
    }
  };

  return (
    <div className="lh-wrapper">
      <Header isLoggedIn />
      <AccountSubmenuMobile />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Manage Payment Info</h1>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-9">
            <form className="form" onSubmit={handleSubmit} autocomplete="off">
              <h3>Billing Address</h3>

              <div className="form__input-block">
                <div className="form__label">Address Line 1</div>
                <div className="form__input-wrapper">
                  <input
                    className="form__input"
                    type="text"
                    autoComplete="false"
                    name="address-line-1"
                    required
                    defaultValue={
                      isAgency
                        ? state?.activeCompany?.user?.billing_address_1 ??
                          state?.activeCompany?.user?.address_1
                        : billingDetails?.address?.line1
                    }
                    onChange={(e) => {
                      setBillingDetails({
                        ...billingDetails,
                        address: {
                          ...billingDetails.address,
                          line1: e.target.value,
                        },
                      });
                    }}
                  />
                  <div className="form__clear form__hidden">
                    <i className="fas fa-times" />
                  </div>
                </div>
              </div>

              <div className="form__input-block">
                <div className="form__label">Address Line 2</div>
                <div className="form__input-wrapper">
                  <input
                    className="form__input"
                    type="text"
                    autoComplete="false"
                    name="address-line-2"
                    defaultValue={
                      isAgency
                        ? state?.activeCompany?.user?.billing_address_2 ??
                          state?.activeCompany?.user?.address_2
                        : billingDetails?.address?.line2
                    }
                    onChange={(e) => {
                      setBillingDetails({
                        ...billingDetails,
                        address: {
                          ...billingDetails.address,
                          line2: e.target.value,
                        },
                      });
                    }}
                  />
                  <div className="form__clear form__hidden">
                    <i className="fas fa-times" />
                  </div>
                </div>
              </div>

              <div className="form__input-block">
                <div className="form__label">City</div>
                <div className="form__input-wrapper">
                  <input
                    className="form__input"
                    type="text"
                    autoComplete="false"
                    name="city"
                    required
                    defaultValue={
                      isAgency
                        ? state?.activeCompany?.user?.billing_city ??
                          state?.activeCompany?.user?.city
                        : billingDetails?.address?.city
                    }
                    onChange={(e) => {
                      setBillingDetails({
                        ...billingDetails,
                        address: {
                          ...billingDetails.address,
                          city: e.target.value,
                        },
                      });
                    }}
                  />
                  <div className="form__clear form__hidden">
                    <i className="fas fa-times" />
                  </div>
                </div>
              </div>

              <div className="form__input-block">
                <div className="form__label">County</div>
                <div className="form__input-wrapper">
                  <input
                    className="form__input"
                    type="text"
                    autoComplete="false"
                    name="state"
                    required
                    defaultValue={
                      isAgency
                        ? state?.activeCompany?.user?.billing_state ??
                          state?.activeCompany?.user?.state
                        : billingDetails?.address?.state
                    }
                    onChange={(e) => {
                      setBillingDetails({
                        ...billingDetails,
                        address: {
                          ...billingDetails.address,
                          state: e.target.value,
                        },
                      });
                    }}
                  />
                  <div className="form__clear form__hidden">
                    <i className="fas fa-times" />
                  </div>
                </div>
              </div>

              <div className="form__two-cols">
                <div className="form__input-block form__two-cols__inner">
                  <div className="form__label">Post code</div>
                  <div className="form__input-wrapper">
                    <input
                      className="form__input"
                      type="text"
                      autoComplete="false"
                      name="post-code"
                      required
                      defaultValue={
                        isAgency
                          ? state?.activeCompany?.user?.billing_zip ??
                            state?.activeCompany?.user?.zip
                          : billingDetails?.address?.postal_code
                      }
                      onChange={(e) => {
                        setBillingDetails({
                          ...billingDetails,
                          address: {
                            ...billingDetails.address,
                            postal_code: e.target.value,
                          },
                        });
                      }}
                    />
                    <div className="form__clear form__hidden">
                      <i className="fas fa-times" />
                    </div>
                  </div>
                </div>

                <div className="form__input-block form__two-cols__inner mb-60">
                  <div className="form__label">Country</div>
                  <div className="form__input-wrapper">
                    <select
                      className="form__input form__country"
                      name="country"
                      list="countries"
                      id="country"
                      required
                      defaultValue={
                        isAgency
                          ? state?.activeCompany?.user?.billing_country ?? 'GB'
                          : billingDetails?.address?.country
                      }
                      onChange={(e) => {
                        setBillingDetails({
                          ...billingDetails,
                          address: {
                            ...billingDetails.address,
                            country: e.target.value,
                          },
                        });
                      }}
                    >
                      {countries.map(({ code, name }) => (
                        <option value={code} key={code}>
                          {name}
                        </option>
                      ))}
                    </select>
                    <div className="form__clear form__hidden">
                      <i className="fas fa-times" />
                    </div>
                  </div>
                </div>
              </div>

              <h3>Payment Information</h3>

              <div className="form__input-block">
                <div className="form__label">&nbsp;</div>
                <div className="form__input-wrapper">
                  <CardNumberElement
                    className="form__input"
                    {...createOptions()}
                    onChange={(e) => {
                      setError(e.error);
                      setCardComplete(e.complete);
                    }}
                  />
                </div>
              </div>

              <div className="onboarding__two-cols-constant">
                <div className="form__input-block form__two-cols-constant__inner">
                  <div className="form__label">&nbsp;</div>
                  <div className="form__input-wrapper">
                    <CardExpiryElement
                      className="form__input"
                      {...createOptions()}
                      onChange={(e) => {
                        setError(e.error);
                        setCardComplete(e.complete);
                      }}
                    />
                  </div>
                </div>

                <div className="form__input-block form__two-cols-constant__inner">
                  <div className="form__label">&nbsp;</div>
                  <div className="form__input-wrapper">
                    <CardCvcElement
                      className="form__input"
                      {...createOptions()}
                      onChange={(e) => {
                        setError(e.error);
                        setCardComplete(e.complete);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 text-center">
                <button
                  className="btn form__btn d-block"
                  type="submit"
                  disabled={!stripe}
                >
                  {loading ? 'Loading...' : 'SAVE'}
                </button>
              </div>
            </form>
          </div>

          <AccountSubmenuDesktop />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ManagePaymentInfo;
