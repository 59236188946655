import React from 'react';
import { Redirect } from 'react-router-dom';
import { userVar } from '../../graphql/config';

const LandingPage = () => {
  if (userVar()?.access_token) {
    const dashboard = userVar().type === 'AGENCY'
      ? '/agency/dashboard'
      : '/freelancer/dashboard';
    return (
      <Redirect
        to={{
          pathname: dashboard,
          state: { referrer: '/' },
        }}
      />
    );
  }
  return (
    <Redirect
      to={{
        pathname: '/agency/login',
        state: { referrer: '/' },
      }}
    />
  );
};

export default LandingPage;
