import gql from 'graphql-tag';

export const GET_SETTING = gql`
  query Setting($key: String!) {
    setting(key: $key) {
      key
      value
    }
  }
`;

export const GET_SETTINGS = gql`
  query Settings {
    settings {
      key
      value
    }
  }
`;
