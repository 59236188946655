import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Link, Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import countryList from 'country-list';
import Error from '../../components/Error';
import { REGISTER } from '../../graphql';
import jquery from '../../styles/js/app';
import Header from '../../components/Agency/Header';
import Footer from '../../components/Footer';
import { registerVar, userVar } from '../../graphql/config';
import {
  inputProps,
  inputStyle,
  saveUserLocalstorage,
  saveLoginTypeLocalstorage,
} from '../../utils/helpers';
import 'react-phone-input-2/lib/plain.css';

const CreateAccountStep2 = ({ history }) => {
  useEffect(() => {
    jquery();
  }, []);

  const countries = countryList.getNames().sort();
  const { register, handleSubmit, errors } = useForm();
  const [registerMutation, { loading }] = useMutation(REGISTER);
  const [phoneError, setPhoneError] = useState(false);
  const [phone, setPhoneNo] = useState(null);

  if (!Object.keys(registerVar()).length) {
    return <Redirect to="/agency/register" />;
  }

  function RegisterUser(data) {
    toast.dismiss();
    validatePhone(phone);
    if (!phoneError && phone !== '') {
      registerMutation({
        variables: {
          ...data,
          ...registerVar(),
          type: 'AGENCY',
          phone: `+${phone}`,
        },
      })
        .then((res) => {
          const { data } = res;
          if (!data.register) {
            toast.error('Something is wrong!');
          } else {
            registerVar({});
            const cookies = new Cookies();
            cookies.set('lh_user', data.register.id, {
              path: '/',
              domain: process.env.REACT_APP_COOKIE_DOMAIN,
            });
            userVar(data.register);
            saveUserLocalstorage(data.register);
            saveLoginTypeLocalstorage('AGENCY');
            return history.push('/agency/complete-profile');
          }
        })
        .catch((e) => {
          let msg = 'Something went wrong!';

          if (
            'validation' in e.graphQLErrors[0].extensions &&
            'input.email' in e.graphQLErrors[0].extensions.validation
          ) {
            msg = 'The email has already been taken!';
          }

          toast.error(msg, { toastId: 'frRegErr' });
        });
    }
  }

  const validatePhone = (phone) => {
    if (!/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(phone)) {
      return setPhoneError(true);
    }
    return setPhoneError(false);
  };

  const handleEnterKey = (event) => {
    if (event.key === 'Enter') {
      return handleSubmit(RegisterUser)();
    }
  };

  return (
    <>
      <div className="lh-wrapper">
        <Header />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="onboarding">
                <h1 className="center">Create A Company Account</h1>
                <div className="pagination-bubble">
                  <div style={{ flex: 1, textAlign: 'left' }}>
                    <Link to="/agency/register" className="btn back">
                      <i className="fas fa-arrow-left" />
                      Back
                    </Link>
                  </div>
                  <div className="pagination-bubble__item bg-agency">1</div>
                  <div className="pagination-bubble__item pagination-bubble__active">
                    2
                  </div>
                  <div className="pagination-bubble__item bg-agency">3</div>
                  <div style={{ flex: 1 }} />
                </div>
                <div>Personal information</div>
                <form className="form" onSubmit={handleSubmit(RegisterUser)}>
                  <input
                    type="hidden"
                    name="googleId"
                    defaultValue={registerVar().google_id || null}
                    ref={register({ required: false })}
                  />
                  <div className="onboarding__two-cols">
                    <div className="form__input-block onboarding__two-cols__inner">
                      <div className="form__label">First Name</div>
                      <div className="form__input-wrapper">
                        <input
                          className="form__input"
                          type="text"
                          autoComplete="false"
                          name="firstName"
                          defaultValue={registerVar().first_name || ''}
                          ref={register({ required: true })}
                        />
                        <div className="form__clear form__hidden">
                          <i className="fas fa-times" />
                        </div>
                      </div>
                    </div>
                    {errors.firstName && (
                      <Error text="Please enter your first name" />
                    )}
                    <div className="form__input-block onboarding__two-cols__inner">
                      <div className="form__label">Last Name</div>
                      <div className="form__input-wrapper">
                        <input
                          className="form__input"
                          type="text"
                          autoComplete="false"
                          name="lastName"
                          defaultValue={registerVar().last_name || ''}
                          ref={register({ required: true })}
                        />
                        <div className="form__clear form__hidden">
                          <i className="fas fa-times" />
                        </div>
                      </div>
                    </div>
                  </div>
                  {errors.lastName && (
                    <Error text="Please enter your lastname" />
                  )}

                  <div style={{ clear: 'both' }} />
                  <div className="form__input-block">
                    <div className="form__label">Company Name</div>
                    <div className="form__input-wrapper">
                      <input
                        className="form__input"
                        type="text"
                        autoComplete="false"
                        name="company"
                        ref={register({ required: true })}
                      />
                      <div className="form__clear form__hidden">
                        <i className="fas fa-times" />
                      </div>
                    </div>
                  </div>
                  {errors.company && (
                    <Error text="Please enter your company name" />
                  )}
                  <div className="form__input-block">
                    <div className="form__label">Position</div>
                    <div className="form__input-wrapper">
                      <input
                        className="form__input"
                        type="text"
                        autoComplete="false"
                        name="position"
                        ref={register({ required: true })}
                      />
                      <div className="form__clear form__hidden">
                        <i className="fas fa-times" />
                      </div>
                    </div>
                  </div>
                  {errors.position && <Error text="Please enter a position" />}
                  <div className="form__input-block">
                    <div className="form__label">Address Line 1</div>
                    <div className="form__input-wrapper">
                      <input
                        className="form__input"
                        type="text"
                        autoComplete="false"
                        name="address1"
                        ref={register({ required: true })}
                      />
                      <div className="form__clear form__hidden">
                        <i className="fas fa-times" />
                      </div>
                    </div>
                  </div>
                  {errors.address1 && <Error text="This field is required" />}
                  <div className="form__input-block">
                    <div className="form__label">Address Line 2</div>
                    <div className="form__input-wrapper">
                      <input
                        className="form__input"
                        type="text"
                        autoComplete="false"
                        name="address2"
                        ref={register({ required: false })}
                      />
                      <div className="form__clear form__hidden">
                        <i className="fas fa-times" />
                      </div>
                    </div>
                  </div>
                  {errors.address2 && <Error text="This field is required" />}
                  <div className="form__input-block">
                    <div className="form__label">City</div>
                    <div className="form__input-wrapper">
                      <input
                        className="form__input"
                        type="text"
                        autoComplete="false"
                        name="city"
                        ref={register({ required: true })}
                      />
                      <div className="form__clear form__hidden">
                        <i className="fas fa-times" />
                      </div>
                    </div>
                  </div>
                  {errors.city && <Error text="This field is required" />}
                  <div className="form__input-block">
                    <div className="form__label">County</div>
                    <div className="form__input-wrapper">
                      <input
                        className="form__input"
                        type="text"
                        autoComplete="false"
                        name="state"
                        ref={register({ required: true })}
                      />
                      <div className="form__clear form__hidden">
                        <i className="fas fa-times" />
                      </div>
                    </div>
                  </div>
                  {errors.state && <Error text="This field is required" />}
                  <div className="onboarding__two-cols">
                    <div className="form__input-block onboarding__two-cols__inner">
                      <div className="form__label">Post code</div>
                      <div className="form__input-wrapper">
                        <input
                          className="form__input"
                          type="text"
                          autoComplete="false"
                          name="zip"
                          ref={register({ required: true })}
                        />
                        <div className="form__clear form__hidden">
                          <i className="fas fa-times" />
                        </div>
                      </div>
                    </div>
                    {errors.zip && <Error text="This field is required" />}
                    <div className="form__input-block onboarding__two-cols__inner">
                      <div className="form__label">Country</div>
                      <div className="form__input-wrapper">
                        <select
                          className="form__input form__country"
                          name="country"
                          list="countries"
                          id="country"
                          defaultValue="United Kingdom"
                          ref={register({ required: true })}
                        >
                          <option value="" />
                          {countries.map((country) => (
                            <option
                              value={
                                country ===
                                'United Kingdom of Great Britain and Northern Ireland'
                                  ? 'United Kingdom'
                                  : country
                              }
                              key={
                                country ===
                                'United Kingdom of Great Britain and Northern Ireland'
                                  ? 'United Kingdom'
                                  : country
                              }
                            >
                              {country ===
                              'United Kingdom of Great Britain and Northern Ireland'
                                ? 'United Kingdom'
                                : country}
                            </option>
                          ))}
                        </select>
                        <div className="form__clear form__hidden">
                          <i className="fas fa-times" />
                        </div>
                      </div>
                    </div>
                    {errors.country && <Error text="Please select a country" />}
                  </div>
                  <div style={{ clear: 'both' }} />
                  <div className="form__input-block">
                    <div className="form__label form__active">Phone Number</div>
                    <PhoneInput
                      country="gb"
                      autoFormat
                      inputProps={inputProps}
                      inputClass="form__input"
                      containerClass="form__input-wrapper"
                      inputStyle={inputStyle}
                      buttonStyle={{
                        border: 'none',
                      }}
                      // isValid={(value) => validatePhone(value)}
                      onChange={(phone) => {
                        setPhoneNo(phone);
                      }}
                      placeholder="44 116 456 5566"
                      onKeyDown={handleEnterKey}
                    />
                    <div className="form__clear form__hidden">
                      <i className="fas fa-times" />
                    </div>
                  </div>
                  {phoneError && (
                    <Error text="Please enter a valid phone number" />
                  )}
                  <div class="form__input-block" style={{ textAlign: 'left' }}>
                    <input
                      type="checkbox"
                      id="tnc"
                      name="tnc"
                      ref={register({ required: true })}
                    />{' '}
                    <label htmlFor="tnc">
                      I agree to the{' '}
                      <a
                        href={`${process.env.REACT_APP_API_URL}/pages/company-terms-conditions`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="agency"
                      >
                        Terms and Conditions
                      </a>
                    </label>
                  </div>
                  {errors.tnc && (
                    <Error text="You must accept the terms & conditions" />
                  )}
                  <button
                    type="submit"
                    disabled={!!loading}
                    className="form__btn btn d-block mb-50"
                  >
                    {loading ? 'Loading...' : 'Next'}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CreateAccountStep2;
