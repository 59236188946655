import React, { useState, useEffect, useContext } from 'react';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import AccountSubmenuMobile from '../../../components/Agency/AccountSubmenuMobile';
import AccountSubmenuDesktop from '../../../components/Agency/AccountSubmenuDesktop';
import Header from '../../../components/Agency/Header';
import Footer from '../../../components/Footer';
import Error from '../../../components/Error';
import { getUser } from '../../../utils/helpers';
import { AppContext } from '../../../Store';
import { useWindowSize } from '@react-hook/window-size';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import moment from 'moment';
import {
  CREATE_INVITATION,
  UPDATE_INVITATION,
  DELETE_INVITATION,
  VIEW_INVITATIONS,
} from '../../../graphql';
import './styles.css';

const CustomWrapper = styled.div`
  .account-submenu__desktop.bg-light-agency {
    min-width: 194.25px;
  }
  width: 9%;
  position: absolute;
  right: 0px;
`;

const CustomFooterWrapper = styled.div`
  .footer {
    min-width: 790px;
  }
`;

const Invitations = (props) => {
  const [width, height] = useWindowSize();
  const [state, setState] = useContext(AppContext);
  const [emailInput, setEmailInput] = useState('');
  const { register, handleSubmit, errors } = useForm();
  const [invitationList, setInvitationList] = useState([]);

  useEffect(() => {
    if (state?.activeCompany !== undefined || getUser()?.type === 'AGENCY') {
      getInvitationList();
    }
  }, [state?.activeCompany]);

  /** Retrieve Open Invitations */
  const [getInvitationList, { loading }] = useLazyQuery(VIEW_INVITATIONS, {
    variables: {
      team_id: Number(state?.activeCompany?.team_id),
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ viewInvitations }) => setInvitationList(viewInvitations),
  });

  /** Create New Invitation */
  const [createInvitation] = useMutation(CREATE_INVITATION);

  const sendInvitation = (data) => {
    toast.dismiss();
    createInvitation({
      variables: {
        input: {
          ...data,
          team_id: Number(state?.activeCompany?.team_id),
          invite_status: 'pending',
        },
      },
    })
      .then(() => {
        getInvitationList();
        setEmailInput('');
        toast.success('Invite sent succesfully!', { autoClose: 4000 });
      })
      .catch((e) => {
        toast.error(e.graphQLErrors[0].extensions.reason, { toastId: 'invitations' });
      });
  };

  /** Resend Invitation */
  const [resendInvitation] = useMutation(UPDATE_INVITATION);
  const resendInvite = (id) => {
    toast.dismiss();
    resendInvitation({ variables: { id: id } })
      .then(() => {
        getInvitationList();
        toast.success('Invite resent succesfully!', { autoClose: 4000 });
      })
      .catch((e) =>
        toast.error('Could not resend invite', { toastId: 'appErr' })
      );
  };

  /** Delete Invitation */
  const [deleteInvitation] = useMutation(DELETE_INVITATION);
  const deleteInvite = (id) => {
    toast.dismiss();
    deleteInvitation({ variables: { id: id } })
      .then(() => {
        getInvitationList();
        toast.success('Invite deleted succesfully!', { autoClose: 4000 });
      })
      .catch((e) =>
        toast.error('Could not delete invite', { toastId: 'appErr' })
      );
  };

  return (
    <>
      <div
        className="lh-wrapper"
        style={{
          minWidth: '790px',
        }}
      >
        <Header isLoggedIn />
        <AccountSubmenuMobile />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Invitations</h1>
          </div>
        </div>

        <div
          className="row"
          style={{
            minHeight: '50vh',
            height: '100%',
            display: 'flex',
            flexDirection: width > 1490 ? 'row' : 'column',
          }}
        >
          <div
            className="col-12 col-md-4 col-lg-3"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              borderRightColor: 'grey',
              borderRightWidth: '1px',
            }}
          >
            <div
              style={{
                marginTop: 30,
              }}
            >
              <input
                style={{ minWidth: '240px' }}
                className="form__input__border-light invite-users"
                type="email"
                name="email"
                autoComplete="false"
                value={emailInput}
                onChange={(e) => {
                  setEmailInput(e.target.value);
                }}
                required
                ref={register({
                  required: true,
                  pattern:
                    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                })}
              />
            </div>

            <div
              style={{
                marginTop: '20px',
              }}
            >
              <a
                href="javascript:void(0)"
                className="form__btn"
                onClick={handleSubmit(sendInvitation)}
              >
                {/* {loading ? 'Loading...' : 'Send Invite'} */}
                Send Invite
              </a>
            </div>

            <div
              style={{
                marginTop: '20px',
              }}
            >
              {errors.email && (
                <Error text="Please enter a valid email address" noMargin />
              )}
            </div>
          </div>

          <div className="col-12 col-md-8 col-lg-6 invite-list">
            <div
              className="row"
              style={{
                marginTop: '30px',
              }}
            >
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Email</th>
                    <th scope="col">Date Sent</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {invitationList.length > 0 &&
                    invitationList.map((item, index) => (
                      <tr key={index}>
                        <th scope="row">{Number(index + 1)}</th>
                        <td>{item?.email}</td>
                        <td>{moment(item?.updated_at).format('DD/MM/YYYY')}</td>
                        <td>{item?.invite_status}</td>
                        <td
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                          }}
                        >
                          <button
                            type="button"
                            class="btn btn-default"
                            style={{
                              width: 'max-content',
                              background: '#237B9F',
                              marginRight: 5,
                            }}
                            onClick={() => resendInvite(item?.id)}
                          >
                            <span style={{ fontWeight: '600', color: 'white' }}>
                              Send Again
                            </span>
                          </button>
                          <button
                            type="button"
                            class="btn btn-danger"
                            style={{ width: 'max-content' }}
                            onClick={() => deleteInvite(item?.id)}
                          >
                            <span style={{ fontWeight: '600' }}>Cancel</span>
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {!loading && invitationList.length === 0 && (
                <p
                  style={{
                    paddingTop: 15,
                  }}
                >
                  There are no pending invites
                </p>
              )}
            </div>
          </div>
          <CustomWrapper>
            <AccountSubmenuDesktop />
          </CustomWrapper>
        </div>
      </div>
      <CustomFooterWrapper>
        <Footer />
      </CustomFooterWrapper>
    </>
  );
};

export default Invitations;
