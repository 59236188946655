import gql from 'graphql-tag';

export const CREATE_EDUCATION = gql`
  mutation CreateEducation($input: EducationCreate!) {
    createEducation(input: $input) {
      id
      school_name
      qualification
      description
      from
      to
    }
  }
`;

export const UPDATE_EDUCATION = gql`
  mutation UpdateEducation($id: ID!, $input: EducationUpdate) {
    updateEducation(id: $id, input: $input) {
      id
    }
  }
`;

export const UPDATE_EXPERIENCE = gql`
  mutation UpdateExperience($id: ID!, $input: ExperienceUpdate) {
    updateExperience(id: $id, input: $input) {
      id
    }
  }
`;

export const CREATE_EXPERIENCE = gql`
  mutation CreateExperience($input: ExperienceCreate!) {
    createExperience(input: $input) {
      id
      title
      company
      description
      from
      to
      years
    }
  }
`;

export const ATTACH_SKILL = gql`
  mutation AttachSkill($input: UserSkillsBelongsToMany!) {
    attachSkill(skills: $input) {
      id
      skills {
        id
        name
        pivot {
          is_top
        }
      }
    }
  }
`;

export const ATTACH_GENRE = gql`
  mutation AttachGenre($input: UserGenresBelongsToMany!) {
    attachGenre(genres: $input) {
      id
      genres {
        id
        name
      }
    }
  }
`;

export const ATTACH_JOB_TYPE = gql`
  mutation AttachJobType($input: UserJobTypes) {
    attachJobType(job_types: $input) {
      name
    }
  }
`;

export const DELETE_EDUCATION = gql`
  mutation DeleteEducation($id: ID!) {
    deleteEducation(id: $id) {
      id
    }
  }
`;

export const DELETE_EXPERIENCE = gql`
  mutation DeleteExperience($id: ID!) {
    deleteExperience(id: $id) {
      id
    }
  }
`;

export const CREATE_RESUME = gql`
  mutation CreateResume($input: ResumeCreate!) {
    createResume(input: $input) {
      id
      file
      active
    }
  }
`;

export const DELETE_RESUME = gql`
  mutation DeleteResume($id: ID!) {
    deleteResume(id: $id) {
      id
    }
  }
`;
