import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import 'bootstrap/js/src/modal';
import hideModal from '../../styles/js/hideModal';

const ProfileIncomplete = ({ missing }) => {
  return (
    <div
      className="modal fade"
      id="profile_incomplete"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="profileIncompleteModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div
            className="modal-header text-center"
            style={{ borderBottom: 'none', paddingLeft: '2rem' }}
          >
            <h5 className="modal-title w-100" id="profileIncompleteModalLabel">
              Profile Incomplete
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body text-center">
            <div className="mb-40">
              Please complete your profile to apply to this job. Adding skills
              helps improve the accuracy of job matches.
              <br />
              <br />
              <span className="txt-red">Required information missing:</span>
              <br />
              {missing.map((m) => (
                <>
                  <span>{m.charAt(0).toUpperCase() + m.slice(1)}</span>
                  <br />
                </>
              ))}
            </div>
          </div>
          <div
            className="modal-footer"
            style={{
              justifyContent: 'center',
              borderTop: 'none',
            }}
          >
            <a
              href="/freelancer/manage-cv"
              className="btn form__btn-small mt-0 bg-freelancer"
            >
              Edit Profile
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileIncomplete;
