import React from 'react';

const Error = ({ text, noMargin }) => (
  <span
    className="form__error"
    style={{ marginTop: noMargin && 0, fontWeight: 1 }}
  >
    {text}
  </span>
);

export default Error;
