import React, { useEffect, useState, useContext } from 'react';
import currencyToSymbolMap from 'currency-symbol-map/map';
import { useForm } from 'react-hook-form';
import Collapse from '@kunukn/react-collapse';
import Switch from 'react-switch';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useLazyQuery } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { Multiselect } from 'multiselect-react-dropdown';
import RangeSlider from './../../../components/Agency/RangeSlider';
import Error from '../../../components/Error';
import Header from '../../../components/Agency/Header';
import Footer from '../../../components/Footer';
import ReasonModal from '../../../components/Agency/ReasonModal';
import jquery from '../../../styles/js/app';
import { UPDATE_OPPORTUNITY } from '../../../graphql/mutations';
import {
  GET_GENRES,
  GET_OPPORTUNITY,
  GET_SKILLS,
  GET_ALL_JOB_LEVELS,
  GET_TEAM_USERS,
} from '../../../graphql';
import { arrToDropdownSelection, getIds } from '../../../utils/helpers';
import { currentOppVar } from '../../../graphql/config';
import { AppContext } from '../../../Store';

const availableCurrencies = ['GBP', 'EUR', 'USD'];
const currencies = Object.entries(
  Object.keys(currencyToSymbolMap)
    .filter((key) => availableCurrencies.includes(key))
    .reduce((obj, key) => {
      obj[key] = currencyToSymbolMap[key];
      return obj;
    }, {})
);

const EditOpportunity = ({
  history,
  match: {
    params: { id },
  },
}) => {
  useEffect(() => {
    jquery();
  }, []);
  const [state] = useContext(AppContext);
  const [opportunity, setOpportunity] = useState({});
  const [jobLevelHelpText, setJobLevelHelpText] = useState('');
  const [jobLevelIsOpen, setJobLevelIsOpen] = useState(true);
  const [jobBudget, setJobBudget] = useState([]);
  const [jobRate, setJobRate] = useState('Day Rate');
  const [readOnlyMode, setReadOnlyMode] = useState(
    !state?.activeCompany?.can_post_jobs
  );
  const [assignedUser, setAssignedUser] = useState(undefined);

  useEffect(() => {
    if (state?.activeCompany) {
      getTeamUsers();
    }
  }, [state?.activeCompany]);

  /** Retrieve Team Members */
  const [getTeamUsers, { data: teamList, loading: teamListLoading }] =
    useLazyQuery(GET_TEAM_USERS, {
      variables: {
        team_id:
          state?.activeCompany?.role === 'admin'
            ? Number(state?.activeCompany?.team_id)
            : Number(state?.activeCompany?.user_id),
      },
      fetchPolicy: 'network-only',
    });

  const { data: { skills = [] } = {}, refetch: refetchSkill } = useQuery(
    GET_SKILLS,
    {
      variables: { name: '' },
      onError: () => refetchSkill(),
    }
  );

  const { data: { genres = [] } = {}, refetch: refetchGenre } = useQuery(
    GET_GENRES,
    {
      variables: { name: '' },
      onError: () => refetchGenre(),
    }
  );

  const allJobtypes = [
    {
      key: 'PERMANENT',
      name: 'Permanent',
      description: 'Full Time or Part Time',
    },
    {
      key: 'FREELANCE',
      name: 'Freelance',
      description: 'Less than six months',
    },
    {
      key: 'BOTH',
      name: 'Both',
    },
  ];

  const allSubJobtypes = [
    {
      key: 'FULLTIME',
      name: 'Full-time',
    },
    {
      key: 'PARTTIME',
      name: 'Part-time',
    },
    {
      key: 'BOTH_TIME',
      name: 'Both',
    },
  ];

  const { data: { allJoblevels = [] } = {}, refetch: refetchJobLevel } =
    useQuery(GET_ALL_JOB_LEVELS, {
      onError: () => refetchJobLevel(),
    });

  const optionsArrSkills = arrToDropdownSelection(skills);
  const optionsArrGenres = arrToDropdownSelection(genres);

  const [selected, setSelected] = useState({});

  const [active, setActive] = useState(true);

  const { loading, data, refetch } = useQuery(GET_OPPORTUNITY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
    },
    onCompleted: ({ opportunity }) => {
      toast.dismiss();
      setActive(opportunity.is_active);
      setSelected({
        genres: arrToDropdownSelection(opportunity.genres),
        skills: arrToDropdownSelection(opportunity.skills),
      });
      setJobLevelHelpText(
        allJoblevels.filter((l) => l.key === opportunity.level)[0]?.description
      );
      /** Update Opportunity */
      switch (opportunity?.compensation_unit) {
        case 'DAY':
          setJobRate('Day Rate');
          break;
        case 'PROJECT':
          setJobRate('Project Rate');
          break;
        case 'YEAR':
          setJobRate('Year Rate');
          break;
        default:
          break;
      }
    },
    onError: () => refetch(),
  });

  if (data && !opportunity.skills) {
    const opportunityObj = data.opportunity;
    delete opportunityObj.__typename;
    delete opportunityObj.id;
    delete opportunityObj.created_at;
    delete opportunityObj.agency;
    delete opportunityObj.applicants;
    setOpportunity({
      ...opportunity,
      ...opportunityObj,
      skills: { sync: getIds(opportunityObj.skills) },
      genres: { sync: getIds(opportunityObj.genres) },
    });
  }

  loading &&
    currentOppVar().id &&
    toast.info('Please wait...', { toastId: 'currOppLoading' });

  const {
    is_on_site,
    is_remote,
    title,
    job_number,
    is_public,
    type,
    level,
    compensation_unit,
    compensation_currency,
    compensation,
    description,
    follow_up,
    application_text,
    application_link,
    maximumBudget,
    minimumBudget,
    updated_by,
    is_compensation_displayed,
  } = opportunity;

  const { register, watch, handleSubmit, errors, getValues } = useForm();
  const watchFields = watch(['description']);
  const followUpWatchFields = watch(['follow_up']);

  const [updateOpportunity, { loading: buttonLoading }] =
    useMutation(UPDATE_OPPORTUNITY);

  const handleChecked = (checked) => {
    setActive(checked);
    setOpportunity({
      ...opportunity,
      is_active: checked,
    });
    return updateOpportunity({
      variables: {
        input: {
          is_active: checked,
        },
        id,
      },
    })
      .then(() => {
        currentOppVar({});
        toast.success('Opportunity status updated!', { autoClose: 2000 });
      })
      .catch((e) => toast.error('Something went wrong.', { autoClose: 5000 }));
  };

  const handleBudgetNotDisplayed = (e) => {
    setOpportunity({
      ...opportunity,
      is_compensation_displayed: e.target.checked,
    });
  };

  const handleIR35 = (val) => {
    setOpportunity({
      ...opportunity,
      is_ir35: val,
    });
  };

  const updateLocation = (location) => {
    if (location === 'both') {
      return setOpportunity({
        ...opportunity,
        is_on_site: true,
        is_remote: true,
      });
    }
    if (location === 'is_on_site') {
      return setOpportunity({
        ...opportunity,
        is_on_site: true,
        is_remote: false,
      });
    }
    return setOpportunity({
      ...opportunity,
      is_on_site: false,
      is_remote: true,
    });
  };

  const updateField = (key, value) =>
    setOpportunity({ ...opportunity, [key]: value });

  const submitOpportunity = (data) => {
    if (!('type' in opportunity) || opportunity.type === '') {
      toast.error('Job Type must be filled!', { toastId: 'appErr' });
      return;
    }

    if (!('sub_type' in opportunity) || opportunity.sub_type === '') {
      toast.error('Working Hour must be filled!', { toastId: 'appErr' });
      return;
    }

    if (!('level' in opportunity) || opportunity.level === '') {
      toast.error('Experience level must be filled!', {
        toastId: 'appErr',
      });
      return;
    }

    const updatedData = {
      ...opportunity,
      ...data,
      skills: { sync: selected.skills.map((s) => s.key) },
      genres: { sync: selected.genres.map((g) => g.key) },
      updated_by: assignedUser,
    };

    if (assignedUser && assignedUser !== -1) {
      updatedData.updated_by = assignedUser;
    }

    if (jobBudget.length !== 0) {
      updatedData.minimumBudget = jobBudget[0];
      updatedData.maximumBudget = jobBudget[1];
    }

    delete updatedData.featured;

    updateOpportunity({
      variables: {
        input: updatedData,
        id,
      },
    })
      .then(() => {
        currentOppVar({});
        toast.success('Opportunity edited successfully', { autoClose: 5000 });
        history.push('/agency/dashboard');
      })
      .catch((e) => toast.error('Something went wrong.', { autoClose: 5000 }));
  };

  const validateCurrency = (value) => {
    if (getValues('compensation') !== '') {
      if (value === '') return false;
    }
    return true;
  };

  const style = {
    chips: {
      background: '#237B9F',
    },
    searchBox: {
      border: 'none',
      borderBottom: '1px solid #ccc',
      borderRadius: '0px',
    },
    multiselectContainer: {
      color: '#237B9F',
    },
  };

  return (
    <div className="lh-wrapper">
      <Header isLoggedIn />
      <ReasonModal refetch={refetch} />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Edit opportunity</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-4">
            <p>
              Fill out the details of the role which will be shown to our
              network of candidates
            </p>
          </div>
          {!loading && title && (
            <>
              <div className="col-12 col-lg-5">
                <div className="add-opportunity-row row">
                  <div className="col-12 add-opportunity-row__title font-weight-bold">
                    Job Title
                  </div>
                  <div className="col-12 profile-bio-row__content">
                    <div className="form__input-block">
                      <div className="form__input-wrapper">
                        <input
                          className="form__input"
                          type="text"
                          name="title"
                          ref={register({ required: true })}
                          defaultValue={title}
                          disabled={readOnlyMode}
                        />
                      </div>
                    </div>
                    {errors.title && <Error text="Please enter a title" />}
                  </div>
                </div>
                <div className="add-opportunity-row row">
                  <div className="col-12 add-opportunity-row__title font-weight-bold">
                    Date posted
                  </div>
                  <div className="col-12 profile-bio-row__content">
                    <div className="form__input-block">
                      <div className="form__input-wrapper">
                        <input
                          className="form__input"
                          type="text"
                          name="job_number"
                          ref={register({ required: false })}
                          defaultValue={job_number}
                          disabled={readOnlyMode}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="add-opportunity-row row">
                  <div className="col-12 add-opportunity-row__title font-weight-bold">
                    Category Experience
                  </div>
                  <div className="col-12 profile-bio-row__content">
                    <div className="form__input-block">
                      <div className="form__input-wrapper">
                        {optionsArrGenres.length > 0 && selected.genres && (
                          <Multiselect
                            options={optionsArrGenres}
                            selectedValues={
                              selected.genres.length === 1
                                ? [...selected.genres]
                                : selected.genres
                            }
                            onSelect={(selectedList, selectedItem) => {
                              const temp = selected;
                              temp.genres = selectedList;
                              setSelected(temp);
                            }}
                            onRemove={(selectedList, removedItem) => {
                              const temp = selected;
                              temp.genres = selectedList;
                              setSelected(temp);
                            }}
                            displayValue="label"
                            disable={readOnlyMode}
                            closeOnSelect={false}
                            avoidHighlightFirstOption
                            placeholder="Select Category Experience"
                            style={style}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="add-opportunity-row row">
                  <div className="col-12 add-opportunity-row__title font-weight-bold">
                    Job Location
                  </div>
                  <div className="col-12 col-lg-12 add-opportunity-row__content">
                    <div className="opportunity-location">
                      <span
                        className={`opportunity-location__card ${
                          is_on_site && !is_remote && 'active'
                        }`}
                        onClick={() => {
                          state?.activeCompany?.can_post_jobs &&
                            updateLocation('is_on_site');
                        }}
                      >
                        On site
                      </span>
                      <span
                        className={`opportunity-location__card ${
                          !is_on_site && is_remote && 'active'
                        }`}
                        onClick={() => {
                          state?.activeCompany?.can_post_jobs &&
                            updateLocation('is_remote');
                        }}
                      >
                        Remote
                      </span>
                      <span
                        className={`opportunity-location__card ${
                          is_on_site && is_remote && 'active'
                        }`}
                        onClick={() => {
                          state?.activeCompany?.can_post_jobs &&
                            updateLocation('both');
                        }}
                      >
                        Hybrid
                      </span>
                    </div>
                  </div>
                </div>
                <div className="add-opportunity-row row">
                  <div className="col-12 add-opportunity-row__title font-weight-bold">
                    Job Description
                  </div>
                  <div className="col-12 col-lg-12 add-opportunity-row__content">
                    <textarea
                      rows="5"
                      className="form__textarea__border-light height__unset"
                      name="description"
                      defaultValue={description}
                      ref={register({ required: true })}
                      disabled={readOnlyMode}
                    />
                    <div className="form__textarea__character-limit">
                      {watchFields && (
                        <>
                          <span id="chars">
                            {'description' in watchFields
                              ? watchFields.description
                                ? watchFields.description.length
                                : 0
                              : 0}
                          </span>{' '}
                          character
                          {'description' in watchFields
                            ? watchFields.description
                              ? watchFields.description.length > 1
                                ? 's'
                                : ''
                              : ''
                            : ''}
                        </>
                      )}
                    </div>
                    {errors.description && (
                      <Error noMargin text="Please enter a description" />
                    )}
                  </div>
                </div>
                <div className="add-opportunity-row row">
                  <div className="col-12 add-opportunity-row__title font-weight-bold">
                    Job Type
                  </div>
                  <div className="col-12 col-lg-12 add-opportunity-row__content">
                    <div className="job-type">
                      {allJobtypes.length > 0 &&
                        allJobtypes.map((t) => (
                          <span
                            className={`job-type__card ${
                              type === t.key && 'active'
                            }`}
                            onClick={() => {
                              state?.activeCompany?.can_post_jobs &&
                                (type !== t.key
                                  ? updateField('type', t.key)
                                  : updateField('type', null));
                            }}
                          >
                            {t.name}
                          </span>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="add-opportunity-row row">
                  <div className="col-12 add-opportunity-row__title font-weight-bold">
                    Working Hours
                  </div>
                  <div className="col-12 col-lg-12 add-opportunity-row__content">
                    <div className="col-12 work-hour mt-3">
                      {allSubJobtypes.map((sjt) => (
                        <div>
                          <input
                            name="sub_type"
                            id={sjt.key}
                            type="radio"
                            value={sjt.key}
                            checked={opportunity.sub_type == sjt.key}
                            onClick={() => {
                              updateField('sub_type', sjt.key);
                            }}
                          />
                          <label htmlFor={sjt.key}>{sjt.name}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                  {opportunity.type === 'FREELANCE' && (
                    <>
                      <div className="col-12 mt-4">
                        <label htmlFor="duration">What is the duration?</label>
                      </div>
                      <div className="col-12 add-opportunity-row__content">
                        <div className="form__input-block">
                          <div className="form__input-wrapper">
                            <select
                              name="duration"
                              className="form__input form__country"
                              ref={register({ required: false })}
                            >
                              {[1, 2, 3, 4, 5, 6].map((val) => (
                                <option
                                  value={val}
                                  key={val}
                                  selected={opportunity.duration === val}
                                >
                                  {val} month{val === 1 ? '' : 's'}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mt-2">
                        <label htmlFor="is_ir35">
                          Is this role inside IR35?
                        </label>
                      </div>
                      <div className="col-12">
                        <div className="form__input-wrapper">
                          <input
                            name="is_ir35"
                            id="toggle1"
                            type="radio"
                            value="1"
                            checked={opportunity.is_ir35 == 1}
                            onClick={() => handleIR35(1)}
                          />
                          <label htmlFor="toggle1">Yes</label>
                          <br />

                          <input
                            name="is_ir35"
                            id="toggle2"
                            type="radio"
                            value="0"
                            checked={opportunity.is_ir35 == 0}
                            onClick={() => handleIR35(0)}
                          />
                          <label htmlFor="toggle2">No</label>
                          <br />

                          <input
                            name="is_ir35"
                            id="toggle3"
                            type="radio"
                            value="2"
                            checked={opportunity.is_ir35 == 2}
                            onClick={() => handleIR35(2)}
                          />
                          <label htmlFor="toggle3">Not sure</label>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="add-opportunity-row row">
                  <div className="col-12 add-opportunity-row__title font-weight-bold">
                    Experience Level
                  </div>
                  <div className="col-12 col-lg-12 add-opportunity-row__content">
                    <div className="opportunity-type">
                      {allJoblevels.length > 0 &&
                        allJoblevels.map((l) => (
                          <span
                            className={`opportunity-type__card ${
                              level === l.key && 'active'
                            }`}
                            onClick={() => {
                              state?.activeCompany?.can_post_jobs &&
                                setJobLevelIsOpen(true);
                              state?.activeCompany?.can_post_jobs &&
                                setJobLevelHelpText(l.description);
                              state?.activeCompany?.can_post_jobs &&
                                updateField('level', l.key);
                            }}
                          >
                            {l.name}
                          </span>
                        ))}
                    </div>
                    <Collapse
                      isOpen={jobLevelIsOpen}
                      aria-hidden={jobLevelIsOpen ? 'false' : 'true'}
                      className="collapse-css-transition app__collapse"
                    >
                      <div
                        className="mt-2"
                        dangerouslySetInnerHTML={{ __html: jobLevelHelpText }}
                      />
                    </Collapse>
                  </div>
                </div>
                <div className="add-opportunity-row row">
                  <div className="col-12 add-opportunity-row__title font-weight-bold">
                    Skills Required
                  </div>
                  <div className="col-12 profile-bio-row__content">
                    <div className="form__input-block">
                      <div className="form__input-wrapper">
                        {optionsArrSkills.length > 0 && selected.skills && (
                          <Multiselect
                            options={optionsArrSkills}
                            selectedValues={
                              selected.skills.length === 1
                                ? [...selected.skills]
                                : selected.skills
                            }
                            onSelect={(selectedList, selectedItem) => {
                              const temp = selected;
                              temp.skills = selectedList;
                              setSelected(temp);
                            }}
                            onRemove={(selectedList, removedItem) => {
                              const temp = selected;
                              temp.skills = selectedList;
                              setSelected(temp);
                            }}
                            displayValue="label"
                            disable={readOnlyMode}
                            closeOnSelect={false}
                            avoidHighlightFirstOption
                            placeholder="Select Skills"
                            style={style}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="add-opportunity-row row">
                  <div
                    className="col-12 add-opportunity-row__title font-weight-bold"
                    style={{
                      marginBottom: 32,
                    }}
                  >
                    Compensation/Budget
                  </div>
                  <div className="col-12" style={{ marginBottom: 20 }}>
                    <input
                      id="do-not-display"
                      type="checkbox"
                      defaultChecked={is_compensation_displayed}
                      style={{ marginRight: '10px' }}
                      onChange={handleBudgetNotDisplayed}
                      disabled={readOnlyMode}
                    />
                    <label htmlFor="do-not-display">
                      Show compensation on the job offer
                    </label>
                  </div>
                  <div className="col-md-3 add-opportunity-row__content">
                    <div className="form__input-block">
                      <div className="form__input-wrapper">
                        <select
                          name="compensation_currency"
                          className="form__input form__country"
                          defaultValue={compensation_currency}
                          ref={register({
                            validate: (value) => validateCurrency(value),
                          })}
                          disabled={readOnlyMode}
                        >
                          {currencies.map(([code, symbol]) => (
                            <option value={code} key={code}>
                              {symbol}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {errors.compensation_currency && (
                      <Error text="Please select a currency" />
                    )}
                  </div>
                  <div
                    className="col-md-9 add-opportunity-row__content"
                    style={{
                      justifyContent: 'center',
                      alignSelf: 'flex-start',
                    }}
                  >
                    <div className="form__input-block">
                      <div className="form__input-wrapper">
                        {/* <input
                        className="form__input"
                        type="text"
                        name="compensation"
                        defaultValue={compensation}
                        ref={register({
                          required: false,
                          pattern: /^[+-]?((\d+(\.\d+)?)|(\.\d+))$/,
                        })}
                      /> */}

                        <RangeSlider
                          onUpdateCallback={(args) => setJobBudget(args)}
                          jobBudget={[minimumBudget, maximumBudget]}
                          jobRate={jobRate}
                          editMode={true}
                          disabled={readOnlyMode}
                        />
                      </div>
                    </div>
                    {errors.compensation && (
                      <Error text="Please enter a valid number" />
                    )}
                  </div>
                  <div className="col-lg-12 add-opportunity-row__content">
                    <div className="compensation">
                      <span
                        className={`compensation__card ${
                          jobRate === 'Day Rate' && 'active'
                        }`}
                        onClick={() => {
                          state?.activeCompany?.can_post_jobs &&
                            updateField('compensation_unit', 'DAY');
                          state?.activeCompany?.can_post_jobs &&
                            setJobRate('Day Rate');
                        }}
                      >
                        Day Rate
                      </span>

                      <span
                        className={`compensation__card ${
                          jobRate === 'Year Rate' && 'active'
                        }`}
                        onClick={() => {
                          state?.activeCompany?.can_post_jobs &&
                            updateField('compensation_unit', 'YEAR');
                          state?.activeCompany?.can_post_jobs &&
                            setJobRate('Year Rate');
                        }}
                      >
                        Annual Salary
                      </span>
                    </div>
                  </div>
                </div>

                <div className="add-opportunity-row row">
                  <div className="col-12 add-opportunity-row__title font-weight-bold">
                    Follow Up Message
                  </div>
                  <div className="col-12">
                    Add a message to your applicants to let them know you have
                    received their application and/or add more information about
                    the recruitment process.
                  </div>
                  <div className="col-12 col-lg-12 add-opportunity-row__content">
                    <textarea
                      rows="5"
                      className="form__textarea__border-light height__unset"
                      name="follow_up"
                      defaultValue={follow_up}
                      ref={register({ required: true })}
                      disabled={readOnlyMode}
                    />
                    <div className="form__textarea__character-limit">
                      {followUpWatchFields && (
                        <>
                          <span id="chars">
                            {'follow_up' in followUpWatchFields
                              ? followUpWatchFields.follow_up
                                ? followUpWatchFields.follow_up.length
                                : 0
                              : 0}
                          </span>{' '}
                          character
                          {'follow_up' in followUpWatchFields
                            ? followUpWatchFields.follow_up
                              ? followUpWatchFields.follow_up.length > 1
                                ? 's'
                                : ''
                              : ''
                            : ''}
                        </>
                      )}
                    </div>{' '}
                    {errors.follow_up && (
                      <Error noMargin text="Please enter a follow up message" />
                    )}
                  </div>
                </div>

                <div className="add-opportunity-row row">
                  <div className="col-12 add-opportunity-row__title font-weight-bold">
                    If you would like to link this application to your own ATS,
                    please supply a link below
                  </div>
                  <div className="col-12 profile-bio-row__content">
                    <div className="form__input-block">
                      <div className="form__input-wrapper">
                        <input
                          className="form__input"
                          type="text"
                          name="application_link"
                          ref={register({ required: false })}
                          defaultValue={application_link}
                          disabled={readOnlyMode}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {state?.activeCompany?.role === 'admin' && (
                  <div
                    style={{
                      marginTop: '15px',
                      marginBottom: '50px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <span
                      style={{
                        fontWeight: '700',
                        fontSize: '16px',
                        color: 'black',
                        marginTop: '14px',
                      }}
                    >
                      Assign Opportunity to:
                    </span>
                    <span>
                      <select
                        name="posting_user"
                        className="form__input form__country"
                        onChange={(e) => {
                          setAssignedUser(e?.target?.value);
                        }}
                      >
                        <option value={-1}>-- Select User --</option>
                        {teamList &&
                          teamList?.viewUserTeam
                            .filter(
                              (a) => a.team_id !== a.user_id && a.team !== null
                            )
                            .map(
                              ({
                                team: { first_name, last_name },
                                id,
                                user_id,
                              }) => (
                                <option
                                  value={user_id}
                                  key={id}
                                  selected={user_id === updated_by}
                                >
                                  {first_name + ' ' + last_name}
                                </option>
                              )
                            )}
                      </select>
                    </span>
                  </div>
                )}

                <div className="text-center mt-4">
                  {state?.activeCompany?.can_post_jobs ? (
                    <button
                      className="btn publish-center mb-50"
                      onClick={handleSubmit(submitOpportunity)}
                    >
                      {buttonLoading ? 'Loading...' : 'SAVE'}
                    </button>
                  ) : (
                    <button
                      className="btn publish-center mb-50"
                      onClick={() => history.push('/agency/dashboard')}
                    >
                      BACK
                    </button>
                  )}
                </div>
              </div>
              {state?.activeCompany?.can_post_jobs && (
                <div className="col-12 col-lg-3">
                  <div className="add-opportunity-row">
                    <div className="add-opportunity-row__title font-weight-bold">
                      Status
                    </div>
                  </div>
                  <div className="published">
                    <span className="mr-5">
                      {active ? 'Published' : 'Not Published'}
                    </span>
                    <Switch
                      uncheckedIcon={false}
                      checkedIcon={false}
                      onColor="#237b9f"
                      onChange={handleChecked}
                      checked={active}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EditOpportunity;
