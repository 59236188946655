import React from 'react';
import { Link } from 'react-router-dom';

const ResourcesCard = ({ content, loading }) => (
  <>
    <h1>Liberty Hive Community</h1>
    <div className="row">
      {loading && 'Loading resources...'}
      {content
        && content.map(({
          title, slug, image, id,
        }) => (
          <div className="col-lg-4 col-md-6" key={id}>
            <Link
              to={`/freelancer/articles/${slug}`}
              className="link-freelancer mb-20 d-block font-weight-bold"
            >
              <img
                src={image || '/img/cta-placeholder@2x.jpg'}
                className="blog__img"
                alt={title}
              />
              {title}
            </Link>
          </div>
        ))}
      <div className="col-12 text-right mb-5">
        <Link
          to="/freelancer/resources"
          className="link-freelancer txt__fw-600"
        >
          View More
        </Link>
      </div>
    </div>
  </>
);

export default ResourcesCard;
