import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import Checkbox from 'react-custom-checkbox';
import Header from '../../components/Freelancer/Header';
import Footer from '../../components/Footer';
import jquery from '../../styles/js/app';
import AccountSubmenuDesktop from '../../components/Freelancer/AccountSubmenuDesktop';
import AccountSubmenuMobile from '../../components/Freelancer/AccountSubmenuMobile';
import { UPDATE_PROFILE } from '../../graphql';
import { userVar } from '../../graphql/config';
import { getUser, saveUserLocalstorage } from '../../utils/helpers';

const ProfilePrivacy = () => {
  useEffect(() => {
    jquery();
  }, []);

  const [privacy, setPrivacy] = useState(getUser().privacy);
  const [updateProfile] = useMutation(UPDATE_PROFILE);

  const handlePrivacy = ({ state, field }) => {
    setPrivacy(field);
    updateProfile({
      variables: {
        input: {
          privacy: field,
        },
      },
    })
      .then(({ data: { updateUser } }) => {
        const updatedUserObj = { ...getUser(), ...updateUser };
        userVar(updatedUserObj);
        saveUserLocalstorage(updatedUserObj);
        toast.success('Your notification settings updated!', {
          autoClose: 2000,
        });
      })
      .catch(() => {
        toast.error('Something went wrong.', { autoClose: 5000 });
      });
  };

  return (
    <div className="lh-wrapper">
      <Header isLoggedIn />
      <AccountSubmenuMobile />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Manage Your Profile Privacy</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-9">
            <form className="form-row">
              <div className="col-7">
                <div className="form__heading mt-0" style={{ fontSize: 16 }}>
                  Manage your profile status
                </div>
              </div>
              <div className="col-5">
                <div className="form__input-block">
                  <div className="form__input-wrapper">
                    <div
                      id="privacy"
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Checkbox
                        name="privacy-active"
                        checked={privacy != 'INACTIVE'}
                        onChange={(value) =>
                          handlePrivacy({
                            state: value,
                            field: 'ACTIVE',
                          })
                        }
                        borderColor="rgb(140, 121, 181)"
                        style={{ cursor: 'pointer' }}
                        labelStyle={{ marginLeft: 5, userSelect: 'none' }}
                        label="Active"
                      />
                      <div style={{ width: '5px' }} />
                      <Checkbox
                        name="privacy-inactive"
                        checked={privacy == 'INACTIVE'}
                        onChange={(value) =>
                          handlePrivacy({
                            state: value,
                            field: 'INACTIVE',
                          })
                        }
                        borderColor="rgb(140, 121, 181)"
                        style={{ cursor: 'pointer' }}
                        labelStyle={{ marginLeft: 5, userSelect: 'none' }}
                        label="Inactive"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <AccountSubmenuDesktop />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProfilePrivacy;
