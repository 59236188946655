import gql from 'graphql-tag';

export const VIEW_INVITATIONS = gql`
query ViewInvitation($team_id: Mixed){
    viewInvitations(where: { 
      AND: [
        {column: TEAM_ID, operator: EQ, value: $team_id }
        {column: INVITE_STATUS, operator: EQ, value: "pending" }
      ]
    }) {
        id
        email
        invite_status
        updated_at
    }
  }
`;