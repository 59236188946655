import gql from 'graphql-tag';

export const LOGIN = gql`
  mutation ($email: String!, $password: String!) {
    login(input: { email: $email, password: $password }) {
      name
      first_name
      last_name
      email
      type
      access_token
      id
      bio
      description
      company
      position
      address_1
      address_2
      city
      state
      country
      zip
      phone
      email
      avatar
      billing_address_1
      billing_address_2
      billing_city
      billing_state
      billing_country
      billing_zip
      privacy
      send_email
      send_text_notification
      notification_frequency
      phone_secondary
      status
      isProfileComplete {
        complete
        missing
      }
      looking_for
      location
      payroll_status
      hear_us
      hear_us_additional
      is_payment_active
    }
  }
`;

export const REGISTER = gql`
  mutation (
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $type: UserType!
    $company: String = ""
    $position: String = ""
    $address1: String!
    $address2: String
    $city: String!
    $state: String!
    $country: String!
    $zip: String!
    $phone: String!
  ) {
    register(
      input: {
        first_name: $firstName
        last_name: $lastName
        email: $email
        password: $password
        type: $type
        company: $company
        position: $position
        address_1: $address1
        address_2: $address2
        city: $city
        state: $state
        country: $country
        zip: $zip
        phone: $phone
      }
    ) {
      id
      name
      first_name
      last_name
      email
      type
      access_token
      company
      address_1
      address_2
      city
      state
      country
      zip
      phone
      email
      billing_address_1
      billing_address_2
      billing_city
      billing_state
      billing_country
      billing_zip
      privacy
      send_email
      send_text_notification
      notification_frequency
      phone_secondary
      status
      isProfileComplete {
        complete
        missing
      }
      looking_for
      payroll_status
      hear_us
      hear_us_additional
    }
  }
`;

export const ADMIN_IMPERSONATE_USER = gql`
  mutation ($id: String!, $token: String!) {
    adminImpersonateUser(input: { id: $id, token: $token }) {
      name
      first_name
      last_name
      email
      type
      access_token
      id
      bio
      description
      company
      position
      address_1
      address_2
      city
      state
      country
      zip
      phone
      email
      avatar
      billing_address_1
      billing_address_2
      billing_city
      billing_state
      billing_country
      billing_zip
      privacy
      send_email
      send_text_notification
      notification_frequency
      phone_secondary
      status
      isProfileComplete {
        complete
        missing
      }
      looking_for
      location
      payroll_status
      hear_us
      hear_us_additional
      is_payment_active
    }
  }
`;
