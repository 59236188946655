/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
import * as React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useQuery } from '@apollo/react-hooks';
import Header from '../../../components/Freelancer/Header';
import Footer from '../../../components/Footer';
import jquery from '../../../styles/js/app';
import ResourcesCard from '../../../components/Freelancer/ResourcesCard';
import CTA from '../../../components/CTA';
import { GET_AUTH_USER, GET_ARTICLES } from '../../../graphql';
import { getLoginUrl, logUserOut } from '../../../utils/helpers';
import OpportunitiesAppliedFor from './OpportunitiesAppliedFor';
import LatestOpportunities from './LatestJobOpportunities';
import OpportunitiesForYou from './OpportunitiesForYou';
import AccountInactive from './AccountInactive';
import DealsForYou from './DealsForYou';

const DashboardPage = ({ history }) => {
  React.useEffect(() => {
    dayjs.extend(relativeTime);
    jquery();
  }, []);

  return (
    <div className="lh-wrapper">
      <Header isLoggedIn />
      <div className="container">
        <Dashboard {...{ history }} />
      </div>
      <Footer />
    </div>
  );
};

export default DashboardPage;

const reducer = (state, action) => ({ ...state, ...action.payload });
const initialState = {
  skills: [],
  genres: [],
  opportunitiesForYou: [],
  applications: [],
  status: '',
  jobTypes: [],
};

const Dashboard = ({ history }) => {
  const [user, dispatch] = React.useReducer(reducer, initialState);
  const [initialLoadComplete, setInitialLoadComplete] = React.useState(false);
  const [userStatus, setUserStatus] = React.useState(undefined);

  const { loading, refetch: refetchUser } = useQuery(GET_AUTH_USER, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ me }) => {
      if (!me) {
        logUserOut();
        window.location.href = getLoginUrl('FREELANCER');
      }
      setInitialLoadComplete(true);
      setUserStatus(me?.status);
      dispatch({
        payload: {
          genres: me.genres ?? user.genres,
          skills: me.skills ?? user.skills,
          opportunitiesForYou: me.preferences ?? user.opportunitiesForYou,
          applications: me.applications ?? user.applications,
          status: me.status ?? user.status,
          jobTypes: me.jobTypes ?? user.jobTypes,
        },
      });
    },
    onError: () => refetchUser(),
  });

  const {
    data: { articles: { data: articles = [] } = {} } = {},
    loading: articleLoading,
    refetch: refetchArticle,
  } = useQuery(GET_ARTICLES, {
    variables: {
      availability: ['FREELANCER', 'FREELANCER_AND_AGENCY'],
      first: 3,
      orderBy: {
        field: { field: 'CREATED_AT', order: 'DESC' },
      },
    },
    onError: () => refetchArticle(),
  });

  const {
    data: { articles: { data: ctas = [] } = {} } = {},
    refetch: refetchCTA,
  } = useQuery(GET_ARTICLES, {
    variables: {
      availability: ['FREELANCER', 'FREELANCER_AND_AGENCY'],
      type: ['MENTORING', 'TRAINING'],
      first: 2,
      orderBy: {
        field: { field: 'CREATED_AT', order: 'DESC' },
      },
    },
    onError: () => refetchCTA(),
  });

  if (loading && initialLoadComplete === false) {
    return <span>Loading...</span>;
  }

  if (userStatus === 'INACTIVE') {
    return <AccountInactive />;
  }

  return (
    <>
      {userStatus === 'ACTIVE' && (
        <>
          <div className="row mb-100">
            <OpportunitiesForYou
              {...{ user, loading }}
              opportunitiesForYou={user.opportunitiesForYou}
            />
            <LatestOpportunities {...{ user }} />
            <OpportunitiesAppliedFor
              {...{ loading, history, user }}
              applications={user.applications}
              updateApplications={(data) => dispatch({ ...user, ...data })}
            />
          </div>

          <ResourcesCard content={articles} loading={articleLoading} />
          <CTA content={ctas} />

          <DealsForYou />
        </>
      )}
    </>
  );
};
