import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks';
import DealCard from '../../../../components/Freelancer/DealCard';
import {
    GET_DEALS
} from '../../../../graphql';

const DealsForYou = () => {
    const {
      data: { deals: { data: deals = [] } = {} } = {},
      loading: loadingDeals,
      refetch: refetchDeal,
    } = useQuery(GET_DEALS, {
      variables: {
        first: 6,
        orderBy: {
          field: { field: 'CREATED_AT', order: 'DESC' },
        },
      },
      onError: () => refetchDeal(),
    });
  
    return (
      <>
        <h1>Deals For You</h1>
        <div className="row">
          {loadingDeals && 'Loading deals...'}
          {Array.isArray(deals) && deals?.map((deal) => <DealCard key={deal.id} deal={deal} />)}
          <div className="col-12 mt-3 text-right d-md-none">
            <a href="/#" className="link-freelancer txt__fw-600">
              View More
            </a>
          </div>
        </div>
      </>
    );
};

export default DealsForYou