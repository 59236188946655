import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Error from '../../components/Error';
import jquery from '../../styles/js/app';
import Header from '../../components/Agency/Header';
import Footer from '../../components/Footer';
import {
  getUser,
  isValidImage,
  saveUserLocalstorage,
} from '../../utils/helpers';
import { userVar } from '../../graphql/config';
import { GET_AUTH_USER, UPDATE_PROFILE } from '../../graphql';

const CompleteProfile = ({ history }) => {
  useEffect(() => {
    jquery();
  }, []);

  const avatarRef = useRef(null);
  const { register, watch, handleSubmit, errors } = useForm();
  const watchFields = watch(['bio', 'description']);
  const [updateProfile, { loading }] = useMutation(UPDATE_PROFILE);

  const [img, setImg] = useState({
    url: '/img/agency-picture-placeholder@2x.jpg',
    error: null,
  });

  const { data, refetch } = useQuery(GET_AUTH_USER, {
    onCompleted: () => checkProfileCompleted(),
    onError: () => refetch(),
  });

  const checkProfileCompleted = () => {
    if (data.me) {
      if (data.me.bio && data.me.avatar) {
        return history.push('/agency/dashboard');
      }
    }
  };

  const onChangeFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    toast.dismiss('invalidImage');

    const file = event.target.files[0];
    isValidImage(file, (valid) => {
      if (!valid) {
        setImg({ url: '/img/agency-picture-placeholder@2x.jpg', error: true });
        return toast.error('Please select a valid image file.', {
          toastId: 'invalidImage',
        });
      }
      setImg({
        url: valid.url,
        error: false,
        file: valid.file,
      });
    });
  };

  const editProfile = (data) => {
    toast.dismiss();
    if (img.error) {
      return toast.error('Please select a valid image file.');
    }
    return updateProfile({
      variables: {
        input: {
          ...data,
          avatar: img.file,
        },
      },
    })
      .then(({ data: { updateUser } }) => {
        const updatedUserObj = { ...getUser(), ...updateUser };
        userVar(updatedUserObj);
        saveUserLocalstorage(updatedUserObj);
        toast.success('Profile completed!', { autoClose: 4000 });
        return history.push('/agency/dashboard');
        // return history.push('/agency/subscription', {
        //   from: '/agency/complete-profile',
        // });
      })
      .catch((e) =>
        toast.error('Something went wrong!', { toastId: 'resErr' })
      );
  };

  return (
    <>
      <div className="lh-wrapper">
        <Header />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="onboarding">
                <h1 className="center">You're signed up!</h1>
                <div className="pagination-bubble">
                  <div className="pagination-bubble__item bg-agency">1</div>
                  <div className="pagination-bubble__item bg-agency">2</div>
                  <div className="pagination-bubble__item pagination-bubble__active">
                    3
                  </div>
                </div>
                <h3>Now tell us a little about your company...</h3>
                <div className="form__heading">Add Your Company Logo</div>
                <div className="edit-company-logo">
                  <div
                    className="edit-company-logo__overlay invisible"
                    onClick={() => avatarRef.current.click()}
                  >
                    <p className="edit-profile-picture__text">
                      <i className="far fa-edit" /> Add Logo
                    </p>
                  </div>
                  <img src={img.url} className="img img__company-logo" alt="" />
                </div>
                <input
                  type="file"
                  id="file"
                  ref={avatarRef}
                  style={{ display: 'none' }}
                  onChange={(e) => onChangeFile(e)}
                />
                <div className="form__heading">Add Profile Bio</div>
                <textarea
                  maxLength="1000"
                  className="form__textarea"
                  name="bio"
                  id="bio"
                  ref={register({ required: true, maxLength: 1000 })}
                  style={{ maxWidth: 'none' }}
                />
                <div
                  className="form__textarea__character-limit"
                  style={{ maxWidth: 'none' }}
                >
                  {watchFields && (
                    <>
                      <span id="chars">
                        {'bio' in watchFields
                          ? watchFields.bio
                            ? watchFields.bio.length
                            : 0
                          : 0}
                      </span>{' '}
                      / 1000
                    </>
                  )}
                </div>
                {errors.bio && <Error text="Please fill in your profile bio" />}
                <div className="form__heading">What sets you apart</div>
                <textarea
                  maxLength="250"
                  className="form__textarea"
                  name="description"
                  id="description"
                  ref={register({ required: true, maxLength: 250 })}
                  style={{ maxWidth: 'none' }}
                />
                <div
                  className="form__textarea__character-limit"
                  style={{ maxWidth: 'none' }}
                >
                  {watchFields && (
                    <>
                      <span id="chars">
                        {'description' in watchFields
                          ? watchFields.description
                            ? watchFields.description.length
                            : 0
                          : 0}
                      </span>{' '}
                      / 250
                    </>
                  )}
                </div>
                {errors.description && <Error text="This field is required" />}
                <div className="form__heading">
                  Are you happy for us to use your logo across our marketing
                  channels?
                </div>
                <select
                  name="can_use_logo"
                  className="form__input form__country"
                  ref={register({ required: true })}
                >
                  <option value="1" key="yes">
                    Yes
                  </option>
                  <option value="0" key="no">
                    No
                  </option>
                </select>
                {errors.can_use_logo && (
                  <Error text="You must fill this field" />
                )}
                <div className="form__btn" onClick={handleSubmit(editProfile)}>
                  {loading ? 'Loading...' : 'Submit'}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CompleteProfile;
