import { useQuery } from '@apollo/react-hooks';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GET_ARTICLES } from '../../graphql';

const BlogpostSidebar = ({ articleId }) => {
  const [articles, setArticles] = useState();

  const { data, loading, error } = useQuery(GET_ARTICLES, {
    variables: {
      availability: ['AGENCY', 'FREELANCER_AND_AGENCY'],
      type: 'ARTICLE',
      first: 4,
      orderBy: {
        field: { field: 'CREATED_AT', order: 'DESC' },
      },
    },
    onCompleted: () => checkCurrentArticle(),
  });
  if (error) return toast.error('Could not get blog posts', { toastId: 'blgPstEr' });

  const checkCurrentArticle = () => {
    let currentArticleExists = false;
    data.articles.data.forEach((element, index) => {
      if (element.id === articleId) {
        data.articles.data.splice(index, 1);
        currentArticleExists = true;
      }
    });
    !currentArticleExists && data.articles.data.splice(3, 1);
    setArticles(data.articles.data);
  };

  return (
    <>
      <div className="col-lg-4 offset-lg-1">
        <h2 className="txt__fw-600 mb-20">Other Blog Posts</h2>
        <div className="row">
          {loading && <div>Loading...</div>}
          {articles
            && articles.map(({ title, slug, image }) => (
              <div className="col-12 col-md-6 col-lg-12" key={slug}>
                <img
                  src={image || '/img/cta-placeholder@2x.jpg'}
                  className="blog__img"
                  alt=""
                />
                <Link
                  to={`/agency/articles/${slug}`}
                  className="link-agency txt__fw-600 mb-40 d-block"
                >
                  {title}
                </Link>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default BlogpostSidebar;
