import React from 'react';
import 'bootstrap/js/src/modal';
import hideModal from '../../styles/js/hideModal';

const ConfirmHideApplication = ({ id, confirm, loading }) => {
  const handleConfirm = () => {
    confirm();
    hideModal();
  };

  return (
    <div
      className="modal fade"
      id={`confirm_hide_application_${id}`}
      tabIndex="-1"
      role="dialog"
      aria-labelledby={`hideApplicationLabel_${id}`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id={`hideApplicationLabel_${id}`}>
              Please confirm before you hide your application
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            Are you sure you want to hide this opportunity?
          </div>
          <div
            className="modal-footer"
            style={{
              justifyContent: 'center',
            }}
          >
            <button
              type="button"
              className="btn form__btn-small mt-0"
              onClick={handleConfirm}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmHideApplication;
