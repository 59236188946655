import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Checkbox from 'react-custom-checkbox';
import Header from '../../components/Freelancer/Header';
import AccountSubmenuMobile from '../../components/Freelancer/AccountSubmenuMobile';
import AccountSubmenuDesktop from '../../components/Freelancer/AccountSubmenuDesktop';
import SendVerificationModal from '../../components/Freelancer/NotificationSettings/SendVerificationModal';
import PhoneVerificationModal from '../../components/Freelancer/NotificationSettings/PhoneVerificationModal';
import PhoneVerificationErrorModal from '../../components/Freelancer/NotificationSettings/PhoneVerificationErrorModal';
import VerificationConfirmationModal from '../../components/Freelancer/NotificationSettings/VerificationConfirmationModal';
import Footer from '../../components/Footer';
import jquery from '../../styles/js/app';
import {
  getUser,
  logUserOut,
  getLoginUrl,
  saveUserLocalstorage,
} from '../../utils/helpers';
import { userVar } from '../../graphql/config';
import { GET_AUTH_USER, UPDATE_PROFILE } from '../../graphql';

const NotificationSettings = () => {
  useEffect(() => {
    jquery();
  }, []);
  const stages = ['Send_Verification', 'Phone_Verification', 'Error'];
  const [emailOpportunityAlertsActive, setEmailOpportunityAlertsActive] =
    useState(getUser().send_email);
  const [textNewAlertsActive, setTextNewAlertsActive] = useState(
    getUser().send_text_notification
  );
  const [verificationStage, setVerificationStage] = useState(stages[0]);
  const [textFrequency, setTextFrequency] = useState(
    getUser().notification_frequency
  );
  const [showModal, setShowModal] = useState(false);

  const { refetch } = useQuery(GET_AUTH_USER, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ me }) => {
      if (!me) {
        logUserOut();
        window.location.href = getLoginUrl('FREELANCER');
      }
      setEmailOpportunityAlertsActive(me.send_email);
      setTextNewAlertsActive(me.send_text_notification);
    },
    onError: () => refetch(),
  });

  const [updateProfile] = useMutation(UPDATE_PROFILE);
  const handleChecked = (checked, field) => {
    switch (field) {
      case 'Email_Opportunity_Alerts':
        updateProfile({
          variables: {
            input: {
              send_email: checked,
            },
          },
        })
          .then(({ data: { updateUser } }) => {
            const updatedUserObj = { ...getUser(), ...updateUser };
            userVar(updatedUserObj);
            saveUserLocalstorage(updatedUserObj);
            setEmailOpportunityAlertsActive(checked);
            toast.success('Your notification settings updated!', {
              autoClose: 2000,
            });
          })
          .catch(() => {
            toast.error('Something went wrong.', { autoClose: 5000 });
          });
        break;

      case 'Text_New_Alerts':
        if (checked) {
          setShowModal(true);
        } else {
          updateProfile({
            variables: {
              input: {
                send_text_notification: checked,
              },
            },
          })
            .then(({ data: { updateUser } }) => {
              const updatedUserObj = { ...getUser(), ...updateUser };
              userVar(updatedUserObj);
              saveUserLocalstorage(updatedUserObj);
              setTextNewAlertsActive(checked);
              toast.success('Your notification settings updated!', {
                autoClose: 2000,
              });
            })
            .catch(() => {
              toast.error('Something went wrong.', { autoClose: 5000 });
            });
        }
        break;

      default:
        break;
    }
  };

  const handleFrequency = ({ state, field }) => {
    setTextFrequency(field);
    switch (field) {
      case 'daily':
        updateProfile({
          variables: {
            input: {
              notification_frequency: field,
            },
          },
        })
          .then(({ data: { updateUser } }) => {
            const updatedUserObj = { ...getUser(), ...updateUser };
            userVar(updatedUserObj);
            saveUserLocalstorage(updatedUserObj);
            toast.success('Your notification settings updated!', {
              autoClose: 2000,
            });
          })
          .catch(() => {
            toast.error('Something went wrong.', { autoClose: 5000 });
          });
        break;
      case 'immediately':
        updateProfile({
          variables: {
            input: {
              notification_frequency: field,
            },
          },
        })
          .then(({ data: { updateUser } }) => {
            const updatedUserObj = { ...getUser(), ...updateUser };
            userVar(updatedUserObj);
            saveUserLocalstorage(updatedUserObj);
            toast.success('Your notification settings updated!', {
              autoClose: 2000,
            });
          })
          .catch(() => {
            toast.error('Something went wrong.', { autoClose: 5000 });
          });
        break;
      default:
        break;
    }
  };

  const verificationMode = (stage) => {
    switch (stage) {
      case stages[0]:
        return (
          <SendVerificationModal
            onCloseCallback={() => setShowModal(false)}
            onSubmitCallback={() => setVerificationStage(stages[1])}
          />
        );
      case stages[1]:
        return (
          <PhoneVerificationModal
            onCloseCallback={() => setShowModal(false)}
            onSubmitCallback={() => setVerificationStage(stages[2])}
            onBackCallback={() => setVerificationStage(stages[0])}
          />
        );
      case stages[2]:
        return (
          <VerificationConfirmationModal
            onCloseCallback={() => setShowModal(false)}
            onSubmitCallback={() => {
              setTextNewAlertsActive(true);
              setShowModal(false);
            }}
          />
        );
      case stages[3]:
        return (
          <PhoneVerificationErrorModal
            onCloseCallback={() => setShowModal(false)}
            onSubmitCallback={() => setVerificationStage(stages[0])}
          />
        );
      default:
        break;
    }
  };

  return (
    <>
      <div className="lh-wrapper">
        <Header isLoggedIn />
        {showModal && verificationMode(verificationStage)}
        <AccountSubmenuMobile />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Notification Settings</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-9">
            <form className="form-row">
              <div className="col-11">
                <div className="form__heading mt-0" style={{ fontSize: 16 }}>
                  Email me new opportunity alerts
                </div>
              </div>
              <div className="col-1">
                <div className="form__input-block">
                  <div className="form__input-wrapper">
                    <Switch
                      uncheckedIcon={false}
                      checkedIcon={false}
                      onColor="#8C79B5"
                      onChange={(checked) => {
                        handleChecked(checked, 'Email_Opportunity_Alerts');
                      }}
                      checked={emailOpportunityAlertsActive}
                    />
                  </div>
                </div>
              </div>

              <div className="col-11">
                <div className="form__heading mt-0" style={{ fontSize: 16 }}>
                  Text me new alerts
                </div>
              </div>
              <div className="col-1">
                <div className="form__input-block">
                  <div className="form__input-wrapper">
                    <Switch
                      uncheckedIcon={false}
                      checkedIcon={false}
                      onColor="#8C79B5"
                      onChange={(checked) => {
                        handleChecked(checked, 'Text_New_Alerts');
                      }}
                      checked={textNewAlertsActive}
                    />
                  </div>
                </div>
              </div>

              <div className="col-7">
                <div className="form__heading mt-0" style={{ fontSize: 16 }}>
                  Frequency
                </div>
              </div>
              <div className="col-5">
                <div className="form__input-block">
                  <div className="form__input-wrapper">
                    <div
                      id="frequency"
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Checkbox
                        name="daily-input"
                        checked={textFrequency === 'daily'}
                        onChange={(value) =>
                          handleFrequency({
                            state: value,
                            field: 'daily',
                          })
                        }
                        borderColor="rgb(140, 121, 181)"
                        style={{ cursor: 'pointer' }}
                        labelStyle={{ marginLeft: 5, userSelect: 'none' }}
                        label="Daily"
                      />
                      <div style={{ width: '5px' }} />
                      <Checkbox
                        name="immediately-input"
                        checked={textFrequency === 'immediately'}
                        onChange={(value) =>
                          handleFrequency({
                            state: value,
                            field: 'immediately',
                          })
                        }
                        borderColor="rgb(140, 121, 181)"
                        style={{ cursor: 'pointer' }}
                        labelStyle={{ marginLeft: 5, userSelect: 'none' }}
                        label="Immediately"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <AccountSubmenuDesktop />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NotificationSettings;
