import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { googleLogin } from '../api/user';
import { registerVar } from '../graphql/config';
import HeaderBlog from '../components/Agency/HeaderBlog';
import Header from '../components/Freelancer/Header';
import Footer from '../components/Footer';
import { logUserOut } from '../utils/helpers';

const SocialLogin = ({ history }) => {
  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    callGoogleLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const message = 'Loading...';
  const location = useLocation();
  const generateUID = (length) => window
    .btoa(
      Array.from(window.crypto.getRandomValues(new Uint8Array(length * 2)))
        .map((b) => String.fromCharCode(b))
        .join(''),
    )
    .replace(/[+/]/g, '')
    .substring(0, length);

  function ShowHeader(type) {
    switch (type) {
      case 'freelancer':
        return <Header />;

      case 'agency':
      default:
        return <HeaderBlog />;
    }
  }

  const callGoogleLogin = () => {
    logUserOut();
    googleLogin(location.search).then(({ data }) => {
      if (data) {
        registerVar({
          email: data.email,
          password: generateUID(8),
          google_id: data.id,
          first_name: data.user.given_name,
          last_name: data.user.family_name,
        });
        return history.push(`/${localStorage.getItem('userType')}/register/2`);
      }
    });
  };

  return (
    <div className="lh-wrapper">
      {ShowHeader(localStorage.getItem('userType'))}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="onboarding">
              <span>{message}</span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SocialLogin;
