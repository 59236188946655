import gql from 'graphql-tag';

export const GET_JOB_LEVEL = gql`
  query JobLevel($key: String!) {
    joblevel(key: $key) {
      key
      name
      description
    }
  }
`;

export const GET_ALL_JOB_LEVELS = gql`
  query AllJobLevels {
    allJoblevels {
      key
      name
      description
    }
  }
`;
